import DefaultProjectIcon from '../../icons/default_project_icon.svg'
import '../../css/main.css'
import React, { useState, useRef, useEffect, useContext } from 'react'
import HeaderWeekBox from '../../jsx/HeaderWeekBox.jsx'
import MainWeekBox from '../../jsx/MainWeekBox.jsx'
import { GlobalStateContext } from '../GlobalStateContext.jsx'
import { findMissingReportDates } from '../Helpers/ReportExtensions.js'
import useApiReports from '../../api/reports.js'

const BriefReviewPage = ({ dates, startDate, endDate, missedReportsDates, projectsList }) => {
  const api = useApiReports()
  const containerRef = useRef(null)
  const contentRef = useRef(null)
  const { getProfileInfo } = useContext(GlobalStateContext)
  const creatorId = getProfileInfo().id

  const [ReportList, setReportList] = useState([])

  useEffect(() => {
    if (startDate || endDate) {
      getReportsList()
    }
    // console.log('Start date: ', startDate, 'End date: ', endDate, 'dates: ', dates)
  }, [dates])

  useEffect(() => {
    const missingDates = findMissingReportDates(startDate, endDate, ReportList)
    // console.log("Отсутствуют отчеты за следующие даты:", missingDates);
    if (missedReportsDates.length !== 0) missedReportsDates(missingDates)
  }, [ReportList])

  const getReportsList = async () => {
    const response = await api.fetchReportsByDateInterval(creatorId, startDate, endDate)
    if (response.isSuccess === true) {
      setReportList(transformReportsData(response.data).sort((a, b) => b.date - a.date))
    }
  }

  function transformReportsData (serverReports) {
    return serverReports.map(report => {
      // Преобразуем дату в формат Date
      const reportDate = new Date(report.date)
      // console.log("date = ", reportDate);

      // Преобразуем проекты, добавляя изображение и название проекта
      const projects = report.item_reports.map(item => ({
        img_project: DefaultProjectIcon,
        project_id: item.project_id
      }))

      // Суммируем все часы работы по проектам в этом отчете
      const totalHours = report.item_reports.reduce((total, item) => total + item.hours_work, 0)
      const totalMinutes = report.item_reports.reduce((total, item) => total + item.minutes_work, 0)

      // Место работы (из work_home)
      const workPlace = report.work_home_reason || 'Офис' // Если work_home пустое

      return {
        id: report.id,
        date: reportDate,
        projects,
        work_place: workPlace,
        time: totalHours + Math.round(totalMinutes / 60)
      }
    })
  }

  useEffect(() => {
    // console.log(dates);
    const container = containerRef.current
    const content = contentRef.current
    if (content.scrollHeight + 47 >= container.clientHeight) {
      container.style.height = (48 - (container.clientHeight - content.scrollHeight) + container.clientHeight) + 'px'
    }
  }, [])

  return (
        <div className="main-content-report-box">
            <div className="main-content-report">
                <div className="week-reports-wrapper" ref={containerRef}>
                    <div className="main-brief-reports-box-wrapper" ref={contentRef}>
                        <HeaderWeekBox />
                        <div className="main-brief-reports-box">
                        {ReportList.map((item, index) => (
                            <MainWeekBox key={index} id={item.id} date={item.date} projects={item.projects} workPlace={item.work_place} time={item.time} projectsList={projectsList} />
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default BriefReviewPage
