import useApi from './useApi'

const useApiMessage = () => {
  const request = useApi().request

  const sendWorkStatusMessage = async (body) => {
    return request({ method: 'POST', url: '/api/message/work-status', data: body })
  }

  const sendLeaveStatusMessage = async (body) => {
    return request({ method: 'POST', url: '/api/message/leave-status', data: body })
  }

  return { sendWorkStatusMessage, sendLeaveStatusMessage }
}

export default useApiMessage
