import CloseIcon from '../../icons/icon_close.svg'
import '../../css/profile_edit_modal.css'
import StaffRoleFilter from './StaffRoleFilter'
import { StaffPositionEnum } from '../Helpers/StaffExtensions'
import { useState, useEffect } from 'react'
import AssignProjects from './AssignProjects'
import useApiStaff from '../../api/staff.js'

const ProfileEditModal = (props) => {
  const api = useApiStaff()
  const filters = [StaffPositionEnum.DEVELOPER, StaffPositionEnum.MANAGER]
  const [profile, setProfile] = useState(props.modalInfo)
  const [newProfileLogin, setNewProfileLogin] = useState(undefined)
  const [newProfilePassword, setNewProfilePassword] = useState(undefined)
  const [apiError, setApiError] = useState(null)

  const onSelectFilter = (role) => {
    setProfile({ ...profile, role })
    props.modalInfo.role = role
  }

  const setFirstnameFilter = (value) => {
    setProfile({ ...profile, first: value })
  }

  const setLastnameFilter = (value) => {
    setProfile({ ...profile, last: value })
  }

  const setSlackIdFilter = (value) => {
    setProfile({ ...profile, slackId: value })
  }

  const setEmailFilter = (value) => {
    setProfile({ ...profile, email: value })
    getNewProfileLogin(value)
    getNewProfilePassword(value)
  }

  const onSelectProjects = (selectedProjects) => {
    setProfile({ ...profile, assigned_projects: selectedProjects })
  }

  const onSelectStaff = (selectedStaff) => {
    setProfile({ ...profile, assigned_staff: selectedStaff })
  }

  useEffect(() => {
    if (apiError) {
      setApiError(null)
    }
  }, [profile])

  const onSaveEditProfile = async () => {
    const body = {
      first: profile.first,
      last: profile.last,
      role: profile.role,
      id: profile.id,
      email: profile.email,
      slack_id: profile.slackId ? profile.slackId : '',
      projects: profile.assigned_projects
        ? profile.assigned_projects.map((project) => project.id)
        : props.modalInfo.projects.map((project) => project.id),

      staff: profile.assigned_staff
        ? profile.assigned_staff.map((staff) => staff.user_id)
        : props.modalInfo.assignments.map((assignment) => assignment.user_id)
    }
    const response = await api.editStaff(body)
    if (response.isSuccess === true) {
      props.closeModal(true)
    } else {
      setApiError(response.error)
    }
  }

  const onSaveNewProfile = async () => {
    if (isAllowedToCreateNewProfile() === false) {
      return
    }
    const body = {
      login: profile.email,
      password: newProfilePassword,
      first: profile.first,
      last: profile.last,
      role: profile.role,
      id: profile.id,
      email: profile.email,
      slack_id: profile.slackId ? profile.slackId : '',
      projects: profile.assigned_projects.map((project) => project.id),
      staff:
        profile.role === StaffPositionEnum.MANAGER
          ? profile.assigned_staff
            ? profile.assigned_staff.map((staff) => staff.user_id)
            : []
          : []
    }
    const response = await api.createStaff(body)
    if (response.isSuccess === true) {
      props.closeModal(true)
    } else {
      setApiError(response.error.data.message)
    }
  }

  const onCloseModal = () => {
    props.closeModal(false)
  }

  const isAllowedToCreateNewProfile = () => {
    if (props.type === 'add') {
      const result =
        (newProfileLogin &&
          newProfileLogin.length > 0 &&
          newProfilePassword &&
          newProfilePassword.length > 0 &&
          profile.first &&
          profile.first.length > 0 &&
          profile.last &&
          profile.last.length > 0 &&
          profile.role &&
          profile.email &&
          profile.email.length > 0) === true
      return result
    }
    if (props.type === 'edit') {
      const result =
        (profile.first &&
          profile.first.length > 0 &&
          profile.last &&
          profile.last.length > 0) === true
      // console.log(profile.first, profile.first.length, profile.last, profile.role, result, props.type)
      return result
    }
  }

  const getNewProfilePassword = async (input) => {
    const encoder = new TextEncoder()
    const data = encoder.encode(input)

    // Вычисляем хэш SHA-256
    const hashBuffer = await crypto.subtle.digest('SHA-256', data)

    // Преобразуем массив байтов в строку в формате HEX
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('')

    setNewProfilePassword(input.length > 0 ? hashHex.slice(0, 10) : undefined)
  }

  const getNewProfileLogin = async (input) => {
    try {
      if (typeof input !== 'string') {
        setNewProfileLogin(undefined)
        // throw new Error('Input must be a string.')
      }

      const atIndex = input.indexOf('@')
      if (atIndex === -1) {
        setNewProfileLogin(undefined)
        // throw new Error("Invalid email address. '@' not found.")
      }

      // console.log(input.slice(0, atIndex))
      setNewProfileLogin(input.slice(0, atIndex))
    } catch (error) {
      // console.error(error)
    }
  }

  return (
    <div>
      <div>
        <div className='top-modal-bar'>
          <p className='modal-title'>{props.type === 'edit' ? 'Управление пользователем' : 'Новый пользователь'}</p>
          <img className='close-info' onClick={onCloseModal} src={CloseIcon} />
        </div>
        <div className='staff-names-section'>
          <p className='staff-name-title'>Сотрудник</p>
          <div className='staff-inputs-wrapper'>
            <input
              className='staff-name-input'
              placeholder='Имя'
              value={profile.first}
              onChange={(e) => setFirstnameFilter(e.target.value)}
            ></input>
            <input
              className='staff-name-input'
              placeholder='Фамилия'
              value={profile.last}
              onChange={(e) => setLastnameFilter(e.target.value)}
            ></input>
          </div>
        </div>
        <div className='staff-slack-id-section'>
          <p className='staff-name-title'>Slack ID</p>
          <div className='staff-inputs-wrapper'>
            <input
              className='staff-name-input'
              placeholder='Slack ID'
              value={profile.slackId}
              onChange={(e) => setSlackIdFilter(e.target.value)}
            ></input>
          </div>
        </div>
          <>
            <div className='staff-email-section'>
              <p className='staff-name-title'>Электронная почта</p>
              <div className='staff-inputs-wrapper'>
                <input
                  disabled={props.type === 'edit'}
                  className='staff-name-input'
                  placeholder='Email'
                  value={profile.email}
                  onChange={(e) => setEmailFilter(e.target.value)}
                ></input>
              </div>
            </div>
            <div>
              {newProfilePassword !== undefined && (
                <p className='staff-name-title'>Будет пароль: {newProfilePassword}</p>
              )}
            </div>
          </>
        <div className='divider' />
        {props.type === 'add' && (
          <>
            <div className='staff-role-section'>
              <p className='staff-role-title'>Роль</p>
              <StaffRoleFilter
                currentRole={props.modalInfo.role}
                filters={filters}
                onSelectFilter={onSelectFilter}
                showIcons={false}
              />
            </div>
            <div className='divider' />
          </>
        )}
        <div className='staff-assignes-section'>
          <p className='staff-role-title'>Назначения</p>
          <div className='staff-assignes-selectors'>
            <AssignProjects
              isInModal={true}
              selectedProjects={onSelectProjects}
              projects={props.projectsList}
              role={props.modalInfo.role}
              userId={props.modalInfo.id}
            />
            {props.modalInfo.role === StaffPositionEnum.MANAGER && (
              <AssignProjects
                isInModal={true}
                selectedProjects={onSelectStaff}
                staff={props.staffList}
                role={props.modalInfo.role}
                userId={props.modalInfo.id}
              />
            )}
          </div>
        </div>
        <div className='save-changes-wrapper'>
          { apiError && <p className='api-error'>{apiError}</p>}
          <button
            className={`button-save-changes${isAllowedToCreateNewProfile() === true ? '' : '-disabled'}`}
            onClick={props.type === 'edit' ? onSaveEditProfile : onSaveNewProfile}
          >
            Сохранить изменения
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProfileEditModal
