import ArrowIcon from '../../icons/icon_arrow.svg'
import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from 'react';
import { format, getMonth, getYear, addMonths, subYears } from 'date-fns';
import '../../css/select_year.css';

const SelectYear = forwardRef((props, ref) => {
    const Year = new Date();
    const YearArray = [{id: 0, name:getYear(Year)}, 
                       {id: 1, name:getYear(subYears(Year, 1))},
                       {id: 2, name:getYear(subYears(Year, 2))},
                       {id: 3, name:getYear(subYears(Year, 3))},
                       {id: 4, name:getYear(subYears(Year, 4))},
    ]
    const [currentYear, setCurrentYear] = useState(YearArray[0]);
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);
    const elementRef1 = useRef(null);

    const changeYearWithoutVisible = (year) => {
        props.setYear(year.name);
        setCurrentYear(year);
    }; 

    useImperativeHandle(ref, () => ({
        changeYearWithoutVisible
    }));

    const changeYear = (year) => {
        props.setYear(year.name);
        setCurrentYear(year);
        VisibleArray();
    }; 

    const VisibleArray = () => {
        setIsVisible(!isVisible);
    };

    const handleClickOutside = (event) => {
        if (elementRef.current && !elementRef.current.contains(event.target) && !elementRef1.current.contains(event.target)) {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    return (
        <div className="select-year-container">
            <div className="picker-year" ref={elementRef1} onClick={VisibleArray}>
                <p>{currentYear.name}</p>
                <img src={ArrowIcon} />
            </div>
            {isVisible &&
            <div className="array-years" ref={elementRef}>
                {YearArray.map((item, index) => (
                    <p className="item-year" key={index} onClick={() => changeYear(item)}>{item.name}</p>
                ))}
            </div>}
        </div>
    );

});

export default SelectYear;