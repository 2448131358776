import '../../css/main.css';
import editPencilIcon from '../../icons/edit_pencil.svg';
import deleteBoxIcon from '../../icons/delete_box.svg';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getYear, getDate, getMonth} from 'date-fns';
import ProjectBox from '../ProjectBox';
import { GlobalStateContext } from '../GlobalStateContext';
import { getHourString } from '../Helpers/TimeExtensions';

const MainWeekBox = ({ id, date, projects, work_place, time }) => {
    const { changeDay } = useContext(GlobalStateContext)
    const [tm, setTm] = useState('')
    const navigate = useNavigate();

    useEffect(() => {
        setTm(time);
    }, []);

    const GoToEditReport = () => {
        changeDay(date);
        navigate('/history');
    }

    return (
        <div className="admin-column-box">
            <div className="row-box" style={{ borderRight: "1px solid rgba(85, 85, 85, 1)" }}>1</div>
            <div className="row-box" style={{ borderRight: "1px solid rgba(85, 85, 85, 1)", gap: "10px" }}>
                {/* //{projects.map((item) => (
                    <ProjectBox img={item.img_project} text={item.name_project} />
                //))} */}
            </div>
            <div className="row-box" style={{ borderRight: "1px solid rgba(85, 85, 85, 1)" }}>{tm}</div>
            <div className="row-box" style={{ borderRight: "1px solid rgba(85, 85, 85, 1)", paddingRight: "12px" }}>
                <p>Test</p>
            </div>
            <div className="row-box" style={{ justifyContent: "center" }}>
                <img src={editPencilIcon} onClick={GoToEditReport}/>
                <img src={deleteBoxIcon} style={{ marginLeft: "12px" }} />
                </div>
        </div>
    );
};

export default MainWeekBox;