import React, { useState, useEffect } from 'react'
import '../../css/confirm_modal.css'
import { motion, AnimatePresence } from 'framer-motion'

const ConfirmModal = ({ text, onConfirm, onCancel, isOpen }) => {
  const [isVisible, setIsVisible] = useState(isOpen)

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true)
    }
  }, [isOpen])

  const handleClose = () => {
    setIsVisible(false) // Начинаем анимацию исчезновения
  }

  return (
    <AnimatePresence
      onExitComplete={() => {
        if (!isVisible) {
          onCancel() // Вызываем onCancel только после завершения анимации закрытия
        }
      }}
    >
      {isVisible && (
        <motion.div
          className="modal-overlay"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.15, ease: 'easeOut' }}
        >
          <div className="modal-content">
            <p>{text || 'У вас есть изменения в выбранном отчете! Вы действительно хотите продолжить? При продолжении все несохраненные данные могут быть удалены.'}</p>
            <div className="modal-buttons">
              <button className="confirm-button" onClick={onConfirm}>
                Продолжить
              </button>
              <button className="cancel-button" onClick={handleClose}>
                Отмена
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default ConfirmModal
