import '../../css/main.css';
import React, { useEffect } from 'react';
import RightArrowIcon from '../../icons/icon_right_arrow.svg';
import { formatDateComma } from '../Helpers/DateExtensions';
import { getHourString } from '../Helpers/TimeExtensions';

const LastReportsBox = ({date, reportsList, id, openModal, isDetailedStaffInfo }) => {

    useEffect(() => {
        //console.log("StaffLastReportsList: ", reportsList, date);
        
    }, [reportsList, date]);

    const GoToSeeSelectedReport = () => {
        if (reportsList[0].hours_work) {
            openModal({
                reportId: id,
                date: date,
                name: ""
            });
        } else {}
    }

    function sumHoursWork() {
        let resultHours = 0;
        let resultMinutes = 0;
        reportsList.map(item => {
            resultHours = resultHours + item.hours_work;
            resultMinutes = resultMinutes + item.minutes_work;
        });
        return resultHours + Math.floor(resultMinutes / 60);
    }

    return (
        <div key={Math.random()} className="report-box-container" onClick={GoToSeeSelectedReport}>
                <p> { formatDateComma(date) } </p>
                <p style={{ color: (new Date(date) > new Date()) ? "#777" : ((isDetailedStaffInfo && sumHoursWork() === 0) ? "#FE5353" : "#C6C6C6") }}> { isDetailedStaffInfo ? (getHourString(sumHoursWork())) : (sumHoursWork() ? (getHourString(sumHoursWork())) : "") } </p>
                {reportsList[0] && reportsList[0].hours_work !== null && !isDetailedStaffInfo && <img src={RightArrowIcon} alt="Стрелочка" />}
        </div>
    );
};

export default LastReportsBox;