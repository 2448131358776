// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.header-day-report p{
    color: #EDEDED;
    font-family: "RobotoMedium";
    font-size: 18px;
    margin: 0;
}

.header-day-report {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.main-day-report {
    background-color: #2D2D2D;
    border-radius: 4px;
    padding: 16px 16px 0px 16px;
    margin-top: 20px;
}

.footer-day-report {
    background-color: #2D2D2D;
    border-radius: 4px;
    padding: 8px 26px 2px 16px;
    margin-top: 12px;
}

.name-project-day-report {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.name-project-day-report p {
    margin: 0;
    color: #CECECE;
    font-family: "RobotoRegular";
    font-size: 16px;
}

.project-day-report {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.name-project {
    display: flex;
    align-items: center;
    gap: 6px;
}

.name-project p {
    margin: 0;
    color: #CECECE;
    font-family: "RobotoRegular";
    font-size: 20px;
}

.text-project-day-report {
    color: #F3ECEC;
    font-family: "RobotoRegular";
    font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/css/block_day_report.css"],"names":[],"mappings":";AACA;IACI,cAAc;IACd,2BAA2B;IAC3B,eAAe;IACf,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,2BAA2B;IAC3B,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,0BAA0B;IAC1B,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,SAAS;IACT,cAAc;IACd,4BAA4B;IAC5B,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,SAAS;IACT,cAAc;IACd,4BAA4B;IAC5B,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,4BAA4B;IAC5B,eAAe;AACnB","sourcesContent":["\n.header-day-report p{\n    color: #EDEDED;\n    font-family: \"RobotoMedium\";\n    font-size: 18px;\n    margin: 0;\n}\n\n.header-day-report {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 20px;\n}\n\n.main-day-report {\n    background-color: #2D2D2D;\n    border-radius: 4px;\n    padding: 16px 16px 0px 16px;\n    margin-top: 20px;\n}\n\n.footer-day-report {\n    background-color: #2D2D2D;\n    border-radius: 4px;\n    padding: 8px 26px 2px 16px;\n    margin-top: 12px;\n}\n\n.name-project-day-report {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.name-project-day-report p {\n    margin: 0;\n    color: #CECECE;\n    font-family: \"RobotoRegular\";\n    font-size: 16px;\n}\n\n.project-day-report {\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n}\n\n.name-project {\n    display: flex;\n    align-items: center;\n    gap: 6px;\n}\n\n.name-project p {\n    margin: 0;\n    color: #CECECE;\n    font-family: \"RobotoRegular\";\n    font-size: 20px;\n}\n\n.text-project-day-report {\n    color: #F3ECEC;\n    font-family: \"RobotoRegular\";\n    font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
