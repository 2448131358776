import { GlobalStateContext } from '../GlobalStateContext'
import { useContext, useEffect, useState } from 'react'
import calendarImage from '../../icons/calendar_icon.svg'
import useApiMessage from '../../api/message'

const Schedule = () => {
  const apiMessage = useApiMessage()
  const { getNotifications, changeGetNotifications, changeOnSickLeave, changeOnVacation, onSickLeave, onVacation, getProfileInfo } = useContext(GlobalStateContext)

  const [originalSickLeaveStatus] = useState(onSickLeave)
  const [originalVacationStatus] = useState(onVacation)

  const [isOnSickLeave, setIsOnSickLeave] = useState(onSickLeave)
  const [isOnVacation, setIsOnVacation] = useState(onVacation)

  useEffect(() => {
    setIsOnSickLeave(onSickLeave)
    setIsOnVacation(onVacation)
  }, [onSickLeave, onVacation])

  const changeNotifications = () => {
    changeGetNotifications()
  }

  const changeSickLeave = (e) => {
    const checked = e.target.checked
    const requestBody = {
      userId: getProfileInfo().id,
      date: formatDate(new Date()),
      isSickLeave: checked
    }
    // sendMessage(requestBody)
    setIsOnSickLeave(checked)
    if (checked) {
      setIsOnVacation(false)
    }
  }

  const changeVacation = (e) => {
    const checked = e.target.checked
    const requestBody = {
      userId: getProfileInfo().id,
      date: formatDate(new Date()),
      isVacation: checked
    }
    // sendMessage(requestBody)
    setIsOnVacation(checked)
    if (checked) {
      setIsOnSickLeave(false)
    }
  }

  const onClickSendMessage = () => {
    const requestBody = {
      userId: getProfileInfo().id,
      date: formatDate(new Date()),
      isVacation: isOnVacation === true,
      isSickLeave: isOnSickLeave === true
    }
    if (originalSickLeaveStatus === true && isOnSickLeave === false && isOnVacation === true) {
      const requestBody = {
        userId: getProfileInfo().id,
        date: formatDate(new Date()),
        isVacation: false,
        isSickLeave: false
      }
      sendMessage(requestBody, false)
    }
    if (originalVacationStatus === true && isOnVacation === false && isOnSickLeave === true) {
      const requestBody = {
        userId: getProfileInfo().id,
        date: formatDate(new Date()),
        isVacation: false,
        isSickLeave: false
      }
      sendMessage(requestBody, false)
    }
    setTimeout(() => {
      sendMessage(requestBody)
    }, 200)
  }

  const sendMessage = async (requestBody, setupResponseValues = true) => {
    const response = await apiMessage.sendLeaveStatusMessage(requestBody)
    if (response.isSuccess === true && setupResponseValues === true) {
      changeOnSickLeave(response.data.isSickLeave)
      changeOnVacation(response.data.isVacation)
    }
  }

  function formatDate (date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // Месяцы начинаются с 0
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  return (
        <div className="main-content-settings">
            <p style={{ marginTop: '24px', color: '#EDEDED', fontFamily: 'RobotoMedium', fontSize: '16px' }}>Рабочее время</p>
            <div className="default-notifications">
                <div className="radio-button-default-mode">
                    <div className="calendar-checkbox-container">
                        <input
                            type="radio"
                            checked={false}
                        />
                        <label style={{ left: '0px' }}> </label>
                    </div>
                    <p>По умолчанию</p>
                </div>
                <div className="input-default-mode">
                    <p>Понедельник – Пятница</p>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                        <p>с</p>
                        <input maxLength={5} style={{ width: '51px' }}></input>
                        <p>до</p>
                        <input maxLength={5} style={{ width: '51px' }}></input>
                    </div>
                </div>
            </div>
            <div className="line"></div>
            <div className="custom-notifications">
                <div className="radio-button-default-mode">
                    <div className="calendar-checkbox-container">
                        <input
                            type="radio"
                            checked={false}
                        />
                        <label style={{ left: '0px' }}> </label>
                    </div>
                    <p>Настроить расписание</p>
                </div>
                <div className="select-date-custom-notifications">
                    <p>На период:</p>
                    <div className="date-picker-custom-notifications">
                        <img src={calendarImage} />
                        <p>01.07.2024 - 05.07.2024</p>
                    </div>
                </div>
                <div className="header-custom-input">
                    <p>День недели</p>
                    <p>Уведомление</p>
                </div>
                <div className="day-of-week-checkboxes">
                    <div className="item-day-of-week-checkboxes">
                        <div className="checkbox-item-day-of-week">
                            <label className="bottom-panel-checkbox-text-input">
                                <input type='checkbox'></input>
                                <span className="checkmark"></span>
                            </label>
                            <p>Понедельник</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                            <p style={{ color: '#FFFFFF', fontFamily: 'RobotoRegular', fontSize: '14px' }}>с</p>
                            <input maxLength={5} style={{ width: '51px' }}></input>
                            <p style={{ color: '#FFFFFF', fontFamily: 'RobotoRegular', fontSize: '14px' }}>до</p>
                            <input maxLength={5} style={{ width: '51px' }}></input>
                        </div>
                    </div>
                    <div className="item-day-of-week-checkboxes">
                        <div className="checkbox-item-day-of-week">
                            <label className="bottom-panel-checkbox-text-input">
                                <input type='checkbox'></input>
                                <span className="checkmark"></span>
                            </label>
                            <p>Вторник</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                            <p style={{ color: '#FFFFFF', fontFamily: 'RobotoRegular', fontSize: '14px' }}>с</p>
                            <input maxLength={5} style={{ width: '51px' }}></input>
                            <p style={{ color: '#FFFFFF', fontFamily: 'RobotoRegular', fontSize: '14px' }}>до</p>
                            <input maxLength={5} style={{ width: '51px' }}></input>
                        </div>
                    </div>
                    <div className="item-day-of-week-checkboxes">
                        <div className="checkbox-item-day-of-week">
                            <label className="bottom-panel-checkbox-text-input">
                                <input type='checkbox'></input>
                                <span className="checkmark"></span>
                            </label>
                            <p>Среда</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                            <p style={{ color: '#FFFFFF', fontFamily: 'RobotoRegular', fontSize: '14px' }}>с</p>
                            <input maxLength={5} style={{ width: '51px' }}></input>
                            <p style={{ color: '#FFFFFF', fontFamily: 'RobotoRegular', fontSize: '14px' }}>до</p>
                            <input maxLength={5} style={{ width: '51px' }}></input>
                        </div>
                    </div>
                    <div className="item-day-of-week-checkboxes">
                        <div className="checkbox-item-day-of-week">
                            <label className="bottom-panel-checkbox-text-input">
                                <input type='checkbox'></input>
                                <span className="checkmark"></span>
                            </label>
                            <p>Четверг</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                            <p style={{ color: '#FFFFFF', fontFamily: 'RobotoRegular', fontSize: '14px' }}>с</p>
                            <input maxLength={5} style={{ width: '51px' }}></input>
                            <p style={{ color: '#FFFFFF', fontFamily: 'RobotoRegular', fontSize: '14px' }}>до</p>
                            <input maxLength={5} style={{ width: '51px' }}></input>
                        </div>
                    </div>
                    <div className="item-day-of-week-checkboxes">
                        <div className="checkbox-item-day-of-week">
                            <label className="bottom-panel-checkbox-text-input">
                                <input type='checkbox'></input>
                                <span className="checkmark"></span>
                            </label>
                            <p>Пятница</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                            <p style={{ color: '#FFFFFF', fontFamily: 'RobotoRegular', fontSize: '14px' }}>с</p>
                            <input maxLength={5} style={{ width: '51px' }}></input>
                            <p style={{ color: '#FFFFFF', fontFamily: 'RobotoRegular', fontSize: '14px' }}>до</p>
                            <input maxLength={5} style={{ width: '51px' }}></input>
                        </div>
                    </div>
                </div>
                <div className="synchronize-schedule-text" style={{ alignItems: 'center', marginTop: '24px' }}>
                    <p style={{ margin: '0', color: '#8E8E8E', fontFamily: 'RobotoRegular', fontSize: '16px' }}>Перенести расписание на следующую неделю</p>
                    <div className={getNotifications === 1 ? 'get-notifications-button' : 'get-notifications-button enabled'} onClick={changeNotifications} style={{ width: '14%' }}>
                        <div className={getNotifications === 1 ? 'get-notifications-button-slider' : 'get-notifications-button-slider enabled'}></div>
                    </div>
                </div>
            </div>
            <div className="line"></div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                    <div className="checkbox-item-day-of-week">
                        <label className="bottom-panel-checkbox-text-input" style={{ opacity: (isOnVacation === true) ? 0.5 : 1 }}>
                            <input
                                type="checkbox"
                                onChange={changeSickLeave}
                                checked={isOnSickLeave === true}
                                disabled={isOnVacation === true}
                            />
                            <span className="checkmark"></span>
                        </label>
                        <p>На больничном</p>
                    </div>
                    <div className="checkbox-item-day-of-week">
                        <label className="bottom-panel-checkbox-text-input" style={{ opacity: (isOnSickLeave === true) ? 0.5 : 1 }}>
                            <input
                                type="checkbox"
                                onChange={changeVacation}
                                checked={isOnVacation === true}
                                disabled={isOnSickLeave === true}
                            />
                            <span className="checkmark"></span>
                        </label>
                        <p>В отпуске</p>
                    </div>
                </div>
                <div
                onClick={onClickSendMessage}
                className={'send-vacation-leave-status-button' + ((originalSickLeaveStatus !== isOnSickLeave || originalVacationStatus !== isOnVacation) ? '' : ' disabled')}>
                    <p>Отправить</p>
                    </div>
            </div>
        </div>
  )
}

export default Schedule
