import '../../css/detailed_review_page.css'
import BlockDayReport from './BlockDayReport'
import DefaultProjectIcon from '../../icons/default_project_icon.svg'
import React, { useState, useEffect, useContext } from 'react'
import { GlobalStateContext } from '../GlobalStateContext'
import { findMissingReportDates } from '../Helpers/ReportExtensions'
import useApiReports from '../../api/reports'

const DetailedReviewPage = ({ dates, startDate, endDate, missedReportsDates, isModalReport, isExternalReport, externalReport, id, projectsList }) => {
  const api = useApiReports()
  const [ReportList, setReportList] = useState([])
  const [loading, setLoading] = useState(false)

  const { getProfileInfo } = useContext(GlobalStateContext)

  useEffect(() => {
    if (isExternalReport === true) {
      setLoading(true)
    }
    if ((startDate || endDate) && !isExternalReport) {
      getReportsList()
    }
    // console.log("Start date: ", startDate, "End date: ", endDate, "dates: ", dates);
  }, [dates])

  useEffect(() => {
    if (isExternalReport === true && externalReport !== undefined && externalReport !== null) {
      setReportList(transformReportsData(Array.isArray(externalReport) ? externalReport : Array.of(externalReport)).sort((a, b) => b.date - a.date))
    }
  }, [externalReport])

  useEffect(() => {
    const missingDates = findMissingReportDates(startDate, endDate, ReportList)
    // console.log("Отсутствуют отчеты за следующие даты:", missingDates, ReportList);
    if (missedReportsDates.length !== 0) missedReportsDates(missingDates)
  }, [ReportList])

  const getReportsList = async () => {
    setLoading(true)
    const response = await api.fetchReportsByDateInterval(id || getProfileInfo().id, startDate, endDate)
    if (response.isSuccess === true) {
      setReportList(transformReportsData(response.data).sort((a, b) => b.date - a.date))
    }
    if (response) {
      setLoading(false)
    }
  }

  function transformReportsData (serverReports) {
    return serverReports.map(report => {
      // Преобразуем дату в формат Date
      const reportDate = new Date(report.date)

      // Преобразуем проекты, добавляя изображение и название проекта
      const projects = report.item_reports.map(item => ({
        img_project: DefaultProjectIcon,
        project_id: item.project_id,
        project_progress_text: item.text_report,
        hours_work: item.hours_work,
        minutes_work: item.minutes_work
      }))

      // Суммируем все часы работы по проектам в этом отчете
      const totalHours = report.item_reports.reduce((total, item) => total + item.hours_work, 0)
      const totalMinutes = report.item_reports.reduce((total, item) => total + item.minutes_work, 0)

      // Место работы (из work_home)
      const workPlace = report.work_home_reason || 'Не указано' // Если work_home пустое

      const isWorkHome = report.is_work_home

      return {
        id: report.id,
        date: reportDate,
        projects,
        is_work_home: isWorkHome,
        work_place: workPlace,
        time: totalHours + Math.round(totalMinutes / 60)
      }
    })
  }

  if (ReportList.length === 0 && loading === true) {
    return (
      <div className={isModalReport ? 'main-report-modal-detailed-review' : 'main-report-detailed-review'}>
        <div className='circular-progress-bar-wrapper'>
          <div className={'circular-progress-bar'}></div>
        </div>
      </div>
    )
  } else if (ReportList.length === 0 && loading === false) {
    return (<div className={isModalReport ? 'main-report-modal-detailed-review' : 'main-report-detailed-review'}>
      <div className='reports-not-founded'>
        <p>Не найдено отчетов за выбранный период</p>
        </div>
    </div>)
  } else {
    return (
      <div className={isModalReport ? 'main-report-modal-detailed-review' : 'main-report-detailed-review'}>
        <div className={isModalReport ? 'modal-container-detailed-review' : 'container-detailed-review'}>
          {ReportList.map((item, index) => (
            <BlockDayReport key={index} id={item.id} date={item.date} projects={item.projects} workPlace={item.work_place} isWorkHome={item.is_work_home} projectsList={projectsList} />
          ))}
        </div>
      </div>
    )
  }

  // return (
  //   <div className={isModalReport ? 'main-report-modal-detailed-review' : 'main-report-detailed-review'}>
  //     {
  //       // (ReportList.length === 0 && loading === true)
  //       //   ? <div className='circular-progress-bar-wrapper'>
  //       //     <div className={'circular-progress-bar'}></div>
  //       //   </div>
  //       //   : <div className={isModalReport ? 'modal-container-detailed-review' : 'container-detailed-review'}>
  //       //     {ReportList.map((item, index) => (
  //       //       <BlockDayReport key={index} id={item.id} date={item.date} projects={item.projects} workPlace={item.work_place} isWorkHome={item.is_work_home} projectsList={projectsList} />
  //       //     ))}
  //       //   </div>
  //     }
  //   </div>
  // )
}

export default DetailedReviewPage
