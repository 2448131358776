// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ConfirmModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
  
  .modal-content {
    background: #313133;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .modal-content p {
    font-family: "RobotoRegular";
    font-size: 16px;
    color: #EDEDED;
  }
  
  .modal-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
  }
  
  .confirm-button {
    background: #0079C5;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cancel-button {
    background: #FE5353;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .confirm-button:hover {
    background: #0065A4;
  }
  
  .cancel-button:hover {
    background: #c82333;
  }
  `, "",{"version":3,"sources":["webpack://./src/css/confirm_modal.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,0CAA0C;IAC1C,kBAAkB;EACpB;;EAEA;IACE,4BAA4B;IAC5B,eAAe;IACf,cAAc;EAChB;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,6BAA6B;EAC/B;;EAEA;IACE,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":["/* ConfirmModal.css */\n.modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 10000;\n  }\n  \n  .modal-content {\n    background: #313133;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n    text-align: center;\n  }\n\n  .modal-content p {\n    font-family: \"RobotoRegular\";\n    font-size: 16px;\n    color: #EDEDED;\n  }\n  \n  .modal-buttons {\n    margin-top: 20px;\n    display: flex;\n    justify-content: space-around;\n  }\n  \n  .confirm-button {\n    background: #0079C5;\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .cancel-button {\n    background: #FE5353;\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .confirm-button:hover {\n    background: #0065A4;\n  }\n  \n  .cancel-button:hover {\n    background: #c82333;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
