import ToolbarAdmin from "../Toolbar.jsx";
import profileButtonImage from "../../icons/profile_img_icon.svg"
import "../../css/administration.css";
import "../../css/main.css";
import HeaderAdminBox from './HeaderAdminBox.jsx';
import SearchIcon from '../../icons/icon_search.svg';
import MultiSelectText from "../../jsx/MultiSelectText.jsx";
import MultiSelectProjects from "../MultiSelectProjects.jsx";
import AdministrationUserBox from './AdministrationUserBox.jsx';
import { GlobalStateContext } from "../GlobalStateContext.jsx";
import { useContext, useRef, useState } from "react";
import Profile from "../Profile.jsx";

const AdministrationPage = () => {

    const profileRef = useRef(null);
    const profileRef1 = useRef(null);
    const [profile, setVisibleProfile] = useState(false);
    const { getProfileInfo } = useContext(GlobalStateContext);

    const onSelectPositions = (selectedPositions) => {
    }

    const onSelectProjects = (selectedProjects) => {
    }

    const changeVisibleProfile = () => {
        setVisibleProfile(!profile);
      };

      const handleClickOutside = (event) => {
        if (profileRef.current && !profileRef.current.contains(event.target) && !profileRef1.current.contains(event.target)) {
          setVisibleProfile(false);
        }
      };

    return (
        <div className='main-wrapper' onClick={handleClickOutside}>
            <ToolbarAdmin page={"administration"} />
            <div className="content-report">
                <div className="header-report admin">
                <div className="button-add-user">
                        Создать пользователя
                     </div>
                    <div className="header-report-item">
                        <p>Администрирование</p>
                    </div>
                    <div className="header-report-item" style={{ background: "transparent" }}>
                        <span className="profile-box">
                            <img src={getProfileInfo().avatar} style={{ width: "2vw", height: "2vw" }} onClick={changeVisibleProfile} ref={profileRef1} />
                        </span>
                    </div>
                </div>
                <div className="main-report" style={{ alignItems: "normal" }}>
                <div className={profile == false ? "profile-invisible" : "profile-visible"} ref={profileRef}>
            <Profile closeProfile={changeVisibleProfile} />
          </div>
                    <div className="main-content-administration">
                        <div className="content_administration">
                            <div className="filter-toolbar">
                                <div className="filters-admin">
                                    <div className="search-by-name">
                                        <img src={SearchIcon} />
                                        <input className="textarea-search-by-name" placeholder="Поиск по имени">

                                        </input>
                                    </div>
                                    <div className="filter-job-title">
                                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <p>Должность:</p>
                                            <MultiSelectText selectedPositions={onSelectPositions} />
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <p>Проекты:</p>
                                            <MultiSelectProjects selectedProjects={onSelectProjects} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <HeaderAdminBox />
                            <div style={{ paddingTop: "20px" }}></div>
                            <AdministrationUserBox id="1" date="20.09.2024" projects="Piranha" work_place="Не указано test" time="8 часов" />
                            <AdministrationUserBox id="1" date="20.09.2024" projects="Prodvigate" work_place="Из дома" time="4 часа" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdministrationPage;