// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plus-week-button {
}

.plus-week-button.disabled {
    pointer-events: none;
    opacity: 0;
    z-index: -1;
}

.calendar-center-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/css/weekly_calendar.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;IACI,oBAAoB;IACpB,UAAU;IACV,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,QAAQ;IACR,eAAe;AACnB","sourcesContent":[".plus-week-button {\n}\n\n.plus-week-button.disabled {\n    pointer-events: none;\n    opacity: 0;\n    z-index: -1;\n}\n\n.calendar-center-wrapper {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 4px;\n    cursor: default;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
