// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.main-report-detailed-review {
    display: flex;
    width: 67%;
    height: 92%;
    margin: auto;
    position: relative;
    overflow-x: scroll;
    display: inline-block;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.main-report-detailed-review::-webkit-scrollbar {
    display: none;
  }

.container-detailed-review {
    padding: 32px 50px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 71.8%;
    margin: auto;
    margin-top: 7.2%;
    margin-bottom: 5%;
    border-radius: 12px;
    background: linear-gradient(0deg, rgba(0, 126, 196, 0.1) 0%, rgba(54, 62, 255, 0.02) 100%);
}

.modal-container-detailed-review {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    border-radius: 12px;
}

.main-report-modal-detailed-review {
    display: flex;
    width: 100%;
    height: 92%;
    margin: 0;
    position: relative;
    overflow-x: scroll;
    display: inline-block;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.main-report-modal-detailed-review::-webkit-scrollbar {
    display: none;
  }`, "",{"version":3,"sources":["webpack://./src/css/detailed_review_page.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,UAAU;IACV,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,qBAAqB;IACrB,wBAAwB;AAC5B;;AAEA;IACI,aAAa;EACf;;AAEF;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,0FAA0F;AAC9F;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,WAAW;IACX,SAAS;IACT,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,qBAAqB;IACrB,wBAAwB;AAC5B;;AAEA;IACI,aAAa;EACf","sourcesContent":["\n.main-report-detailed-review {\n    display: flex;\n    width: 67%;\n    height: 92%;\n    margin: auto;\n    position: relative;\n    overflow-x: scroll;\n    display: inline-block;\n    scrollbar-width: none;\n    -ms-overflow-style: none;\n}\n\n.main-report-detailed-review::-webkit-scrollbar {\n    display: none;\n  }\n\n.container-detailed-review {\n    padding: 32px 50px;\n    display: flex;\n    flex-direction: column;\n    gap: 40px;\n    width: 71.8%;\n    margin: auto;\n    margin-top: 7.2%;\n    margin-bottom: 5%;\n    border-radius: 12px;\n    background: linear-gradient(0deg, rgba(0, 126, 196, 0.1) 0%, rgba(54, 62, 255, 0.02) 100%);\n}\n\n.modal-container-detailed-review {\n    display: flex;\n    flex-direction: column;\n    gap: 40px;\n    width: 100%;\n    border-radius: 12px;\n}\n\n.main-report-modal-detailed-review {\n    display: flex;\n    width: 100%;\n    height: 92%;\n    margin: 0;\n    position: relative;\n    overflow-x: scroll;\n    display: inline-block;\n    scrollbar-width: none;\n    -ms-overflow-style: none;\n}\n\n.main-report-modal-detailed-review::-webkit-scrollbar {\n    display: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
