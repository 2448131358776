import '../../css/profile_delete_modal.css'
import CloseIcon from '../../icons/icon_close.svg'
import useApiProjects from '../../api/projects.js'

const ProjectDeleteModal = (props) => {
  const api = useApiProjects()

  const onCloseModal = () => {
    props.closeModal(false)
  }

  const onDeleteProject = async () => {
    const response = await api.deleteProject(props.modalInfo.id)
    if (response.isSuccess === true) {
      props.closeModal(true)
    }
  }

  return (
    <div className='profile-delete-modal-wrapper'>
      <div className='top-modal-bar-delete'>
        <p className='modal-title-delete'>Удаление проекта</p>
        <img className='close-info-delete' onClick={onCloseModal} src={CloseIcon} />
      </div>
      <p className='profile-delete-modal-text'>
        {`Вы действительно хотите удалить проект "${props.modalInfo.name}"? Это действие нельзя будет отменить.`}
      </p>
      <div className='save-changes-wrapper'>
          <button
            className={'button-save-changes'}
            onClick={onDeleteProject}
          >
            Удалить
          </button>
        </div>
    </div>
  )
}

export default ProjectDeleteModal
