/* eslint-disable array-callback-return */
import '../../css/main.css'
import editPencilIcon from '../../icons/edit_pencil.svg'
import deleteBoxIcon from '../../icons/delete_box.svg'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ProjectBox from '../ProjectBox'
import { convertStaffPositionToString, StaffPositionEnum } from '../Helpers/StaffExtensions'
import DefaultProjectIcon from '../../icons/default_project_icon.svg'

const ProjectItemBox = ({ project, staffList, openEditProjectModal, openDeleteProjectModal }) => {
  const navigate = useNavigate()
  const [assignedDevelopers, setAssignedDevelopers] = useState([])
  const [assignedManagers, setAssignedManagers] = useState([])

  const onDeleteStaff = () => {
    // navigate(`/write-report?date=${format(date, 'yyyy-MM-dd')}`)
  }

  const onOpenEditProjectModal = () => {
    // openEditProfileModal(project)
  }

  const onOpenDeleteProjectModal = () => {
    // openDeleteProjectModal(id, name)
  }

  useEffect(() => {
    getAssignedDevelopers()
    getAssignedManagers()
  }, [project])

  const getAssignedDevelopers = () => {
    // staffList.map((staff) => {
    //   console.log(staff)
    // })

    const result = []
    project.assigned_developers.map((id) => {
      if (staffList.find((staff) => (staff.user_id === id) && staff.role === StaffPositionEnum.DEVELOPER)) {
        // console.log(staffList.find((staff) => staff.user_id === id))
        result.push(staffList.find((staff) => (staff.user_id === id)).firstname + ' ' + staffList.find((staff) => staff.user_id === id).lastname)
        // result = result + ' ' + staffList.find((staff) => staff.user_id === id).firstname + ' ' + staffList.find((staff) => staff.user_id === id).lastname
        console.log(result.join('; '))
        setAssignedDevelopers(result)
      }
    })
  }

  const getAssignedManagers = () => {
    // staffList.map((staff) => {
    //   console.log(staff)
    // })

    const result = []
    project.assigned_managers.map((id) => {
      if (staffList.find((staff) => (staff.user_id === id) && staff.role === StaffPositionEnum.MANAGER)) {
        // console.log(staffList.find((staff) => staff.user_id === id))
        result.push(staffList.find((staff) => (staff.user_id === id)).firstname + ' ' + staffList.find((staff) => staff.user_id === id).lastname)
        // result = result + ' ' + staffList.find((staff) => staff.user_id === id).firstname + ' ' + staffList.find((staff) => staff.user_id === id).lastname
        console.log(result.join('; '))
        setAssignedManagers(result)
      }
    })
  }

  return (
    <div className='admin-column-box' style={{ padding: '8px 0' }}>
      <div className='row-box' style={{ borderRight: '1px solid rgba(85, 85, 85, 1)' }}>
      <div className="one-project-box-container">
            <img src={DefaultProjectIcon}/>
            <p>{project.name}</p>
        </div>
      </div>
      <div className='row-box' style={{
        borderRight: '1px solid rgba(85, 85, 85, 1)',
        justifyContent: project && project.description.length > 0 ? 'flex-start' : 'flex-end',
        paddingRight: '16px'
      }}>
        {assignedManagers.length > 0 ? assignedManagers.join('; ') : 'Нет назначений'}
      </div>
      <div
        className='row-box'
        style={{
          borderRight: '1px solid rgba(85, 85, 85, 1)',
          gap: '10px',
          paddingRight: '16px',
          justifyContent: project && project.description.length > 0 ? 'flex-start' : 'flex-end',
          columnGap: '4px',
          rowGap: '6px',
          display: 'flex',
          flexWrap: 'wrap'
        }}
      >
        { project.description ? project.description : 'Нет описания' }
        {/* {projects.length > 0
          ? projects.map((item, index) => <ProjectBox key={index} img={DefaultProjectIcon} text={item.name} />)
          : 'Проект не назначен'} */}
      </div>
      <div
        className='row-box'
        style={{
          borderRight: '1px solid rgba(85, 85, 85, 1)',
          paddingRight: '16px',
          justifyContent: staffList && assignedDevelopers.length > 0 ? 'flex-start' : 'flex-end'
        }}
      >
        {assignedDevelopers.length > 0 ? assignedDevelopers.join('; ') : 'Нет назначений'}
      </div>
      <div className='row-box' style={{ justifyContent: 'center' }}>
        <img src={editPencilIcon} onClick={onOpenEditProjectModal} />
        <img src={deleteBoxIcon} style={{ marginLeft: '12px' }} onClick={onOpenDeleteProjectModal} />
      </div>
    </div>
  )
}

export default ProjectItemBox
