import DefaultProjectIcon from "../../icons/default_project_icon.svg"
import HomePinIcon from "../../icons/icon_home_pin.svg"
import { getMonth, getYear, getDate } from 'date-fns';
import "../../css/block_day_report.css";
import { getHourString } from '../../jsx/Helpers/TimeExtensions';

const BlockDayReport = ({ id, date, projects, work_place, isWorkHome, projectsList }) => {
    const count_projects_of_day = [1, 2, 3];
    const names_months = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];

    const retrieveProjectName = (item) => {
        const projectItem = projectsList ? projectsList.find(project => project.id === item.project_id) : "null";
        return projectItem ? projectItem.name : "Проект не выбран";
}

    return (
        <div className="day-report">
            <div className="header-day-report">
                <p>{`${getDate(date)} ${names_months[getMonth(date)]} ${getYear(date)}`}</p>
                { isWorkHome && (
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <img src={HomePinIcon} style={{paddingRight: "8px"}} alt="Стрелочка" /> 
                              <p className="text-project-day-report"> Работал из дома </p>
                    </div>
                    ) }
            </div>
            <div className="main-day-report">
                {projects.map((item, index) => (
                    <div key={index} className="project-day-report">
                        <div className="name-project-day-report">
                            <div className="name-project">
                                <img src={DefaultProjectIcon}/>
                                <p>{retrieveProjectName(item)}</p>
                            </div>
                            <p>{ getHourString(item.hours_work) }</p>
                        </div>
                        <p className="text-project-day-report"> {item.project_progress_text} </p>
                    </div>
                ))}
            </div>
            {
                isWorkHome && (
                    <div className="footer-day-report">
                        <p className="text-project-day-report">{work_place}</p>
                    </div>)
            }
            {/* <div className="footer-day-report">
                <p className="text-project-day-report">{work_place + "  " + isWorkHome}</p>
            </div> */}
        </div>
    );
};

export default BlockDayReport;