// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Roboto-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Roboto-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Roboto-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Roboto-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Roboto-ThinItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./Inter-ExtraLight.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./Inter-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'RobotoLight';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'RobotoBold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
    font-weight: 700;
    font-style: bold;
}

@font-face {
    font-family: 'RobotoMedium';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'RobotoRegular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'RobotoThinItalic';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'InterExtraLight';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'InterRegular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('truetype');
    font-weight: 400;
    font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/fonts/fonts.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,+DAAiD;IACjD,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,+DAAgD;IAChD,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,+DAAkD;IAClD,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,4BAA4B;IAC5B,+DAAmD;IACnD,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,+BAA+B;IAC/B,+DAAsD;IACtD,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;IAC9B,+DAAqD;IACrD,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;IAC3B,+DAAkD;IAClD,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":["@font-face {\n    font-family: 'RobotoLight';\n    src: url('./Roboto-Light.ttf') format('truetype');\n    font-weight: 400;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'RobotoBold';\n    src: url('./Roboto-Bold.ttf') format('truetype');\n    font-weight: 700;\n    font-style: bold;\n}\n\n@font-face {\n    font-family: 'RobotoMedium';\n    src: url('./Roboto-Medium.ttf') format('truetype');\n    font-weight: 500;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'RobotoRegular';\n    src: url('./Roboto-Regular.ttf') format('truetype');\n    font-weight: 400;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'RobotoThinItalic';\n    src: url('./Roboto-ThinItalic.ttf') format('truetype');\n    font-weight: 200;\n    font-style: italic;\n}\n\n@font-face {\n    font-family: 'InterExtraLight';\n    src: url('./Inter-ExtraLight.ttf') format('truetype');\n    font-weight: 200;\n    font-style: italic;\n}\n\n@font-face {\n    font-family: 'InterRegular';\n    src: url('./Inter-Regular.ttf') format('truetype');\n    font-weight: 400;\n    font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
