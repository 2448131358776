import useApi from './useApi'

const useApiReports = () => {
  const request = useApi().request

  const fetchReportsByDateInterval = (id, startDate, endDate) => {
    return request({ method: 'GET', url: `/api/reports?creator_id=${id}&start_date=${startDate}&end_date=${endDate}` })
  }

  const getReportById = async (id) => {
    return request({ method: 'GET', url: `/api/reports/${id}` })
  }

  const saveReport = (body) => {
    return request({ method: 'POST', url: '/api/reports/', data: body })
  }

  return { fetchReportsByDateInterval, getReportById, saveReport }
}

export default useApiReports
