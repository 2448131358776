import useApi from './useApi'

const useApiSummaryReport = () => {
  const request = useApi().request

  const getSummaryReportByDate = async (date) => {
    return request({ method: 'GET', url: `/api/summary-report?date=${date}` })
  }

  return { getSummaryReportByDate }
}

export default useApiSummaryReport
