import ToolbarAdmin from '../Toolbar.jsx'
import '../../css/administration.css'
import '../../css/main.css'
import SearchIcon from '../../icons/icon_search.svg'
import MultiSelectProjects from '../MultiSelectProjects.jsx'
import AdministrationUserBox from './AdministrationUserBox.jsx'
import { GlobalStateContext } from '../GlobalStateContext.jsx'
import { useContext, useEffect, useRef, useState } from 'react'
import Profile from '../Profile.jsx'
import axios from 'axios'
import Modal from 'react-modal'
import MultiSelectRole from '../MultiSelectRole.jsx'
import { StaffPositionEnum } from '../Helpers/StaffExtensions.js'
import ProfileEditModal from './ProfileEditModal.jsx'
import ProfileDeleteModal from './ProfileDeleteModal.jsx'
import HeaderProjectsBox from './HeaderProjectsBox.jsx'
import ProjectItemBox from './ProjectItemBox.jsx'
import AssignProjects from './AssignProjects.jsx'

const ProjectsPage = () => {
  const profileRef = useRef(null)
  const profileRef1 = useRef(null)
  const [profile, setVisibleProfile] = useState(false)
  const { getProfileInfo, getCurrentBackendUrl } = useContext(GlobalStateContext)
  const [staffList, setStaffList] = useState([])
  const [NameFilter, setNameFilter] = useState('')
  const [PositionsFilter, setPositionsFilter] = useState([])
  const [ProjectsFilter, setProjectsFilter] = useState([])
  const [AvailableProjects, setAvailableProjects] = useState([])
  const [AvailableFilterPositions, setAvailableFilterPositions] = useState([])
  const [loading, setLoading] = useState(true)

  const [managersFilter, setManagersFilter] = useState([])
  const [developersFilter, setDevelopersFilter] = useState([])

  const [profileModalOpened, setProfileModalOpened] = useState(false)
  const [deleteProfileModalOpened, setDeleteProfileModalOpened] = useState(false)
  const [profileModalInfo, setProfileModalInfo] = useState({})
  const [profileModalType, setProfileModalType] = useState('')

  const onSelectPositions = (selectedPositions) => {
    setPositionsFilter(selectedPositions)
  }

  const onSelectProjects = (selectedProjects) => {
    setProjectsFilter(selectedProjects)
  }

  const changeVisibleProfile = () => {
    setVisibleProfile(!profile)
  }

  const handleClickOutside = (event) => {
    if (
      profileRef.current &&
        !profileRef.current.contains(event.target) &&
        !profileRef1.current.contains(event.target)
    ) {
      setVisibleProfile(false)
    }
  }

  const getStaffList = async () => {
    setAvailableFilterPositions([StaffPositionEnum.DEVELOPER, StaffPositionEnum.MANAGER])
    const token = localStorage.getItem('token')
    await axios
      .get(`${getCurrentBackendUrl()}/api/administration`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        setStaffList(response.data)
        setLoading(false)
      })
    // eslint-disable-next-line n/handle-callback-err
      .catch((error) => {
        // console.log('Error:', error);
      })
  }

  const fetchProjects = async () => {
    const token = localStorage.getItem('token')
    await axios
      .get(`${getCurrentBackendUrl()}/api/projects/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        const data = response.data
        setAvailableProjects(data)
      })
    // eslint-disable-next-line n/handle-callback-err
      .catch((error) => {
        // console.log('Error:', error);
      })
  }

  useEffect(() => {
    getStaffList()
    fetchProjects()
  }, [getProfileInfo])

  const openEditProjectModal = (id, first, last, role, email, slackId, date, projects, assignments) => {
    setProfileModalType('edit')
    setProfileModalOpened(true)
    setProfileModalInfo({ id, first, last, role, email, slackId, date, projects, assignments })
  }

  const openDeleteProjectModal = (id, first, last) => {
    setDeleteProfileModalOpened(true)
    setProfileModalInfo({ id, first, last })
  }

  const openCreateProjectModal = () => {
    setProfileModalType('add')
    setProfileModalOpened(true)
  }

  const closeEditProfileModal = (withReload) => {
    setProfileModalType('')
    setDeleteProfileModalOpened(false)
    setProfileModalOpened(false)
    setProfileModalInfo({})
    if (withReload) {
      setLoading(true)
      getStaffList()
      fetchProjects()
    }
  }

  const onSelectManagers = (selectedManagers) => {
    setManagersFilter(selectedManagers)
  }

  const onSelectDevelopers = (selectedDevelopers) => {
    console.log(selectedDevelopers)
    setDevelopersFilter(selectedDevelopers)
  }

  const customStyles = {
    content: {
      display: 'inline-block',
      width: '600px',
      maxWidth: '50vw',
      maxHeight: '80vh',
      padding: '32px 40px 39px 40px',
      border: 'none',
      outline: 'none',
      borderRadius: '12px',
      backgroundColor: '#313133',
      overflowY: 'scroll'
    },
    overlay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(18, 22, 25, 0.75)',
      margin: 'auto',
      zIndex: 1000
    }
  }

  return (
      <div className='main-wrapper' onClick={handleClickOutside}>
        <ToolbarAdmin page={'projects'} />
        <Modal className='modal-content' isOpen={profileModalOpened} style={customStyles}>
          <ProfileEditModal
            type={profileModalType}
            modalInfo={profileModalInfo}
            projectsList={AvailableProjects}
            staffList={staffList}
            closeModal={closeEditProfileModal}
          />
        </Modal>
        <Modal className='modal-content' isOpen={deleteProfileModalOpened} style={customStyles}>
        <ProfileDeleteModal modalInfo={profileModalInfo} closeModal={closeEditProfileModal} />
        </Modal>
        <div className='content-report'>
          <div className='header-report admin'>
            <div className='button-add-user' onClick={openCreateProjectModal}>Создать пользователя</div>
            <div className='header-report-item'>
              <p>Администрирование</p>
            </div>
            <div className='header-report-item' style={{ background: 'transparent' }}>
              <span className='profile-box'>
                <img
                  src={getProfileInfo().avatar}
                  onClick={changeVisibleProfile}
                  ref={profileRef1}
                />
              </span>
            </div>
          </div>
          <div className='main-report' style={{ alignItems: 'normal' }}>
            <div className={profile === false ? 'profile-invisible' : 'profile-visible'} ref={profileRef}>
              <Profile closeProfile={changeVisibleProfile} />
            </div>
            <div className='main-content-administration'>
            {loading === true
              ? (
                  <div className='circular-progress-bar-wrapper'>
                    <div className={'circular-progress-bar'}></div>
                  </div>
                )
              : (
              <div className='content_administration'>
                <div className='filter-toolbar'>
                  <div className='filters-admin'>
                    <div className='search-by-name'>
                      <img src={SearchIcon} />
                      <input
                        className='textarea-search-by-name'
                        placeholder='Поиск по проекту'
                        value={NameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                      ></input>
                    </div>
                    <div className='filter-job-title'>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <p>Менеджер:</p>
                        <AssignProjects
                          onlyManagerStaff={true}
                          staff={staffList}
                          selectedProjects={onSelectManagers}
                          projectsName={AvailableProjects.map((project) => project.name)}
                        />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <p>Разработчик:</p>
                        <AssignProjects
                          onlyDevStaff={true}
                          staff={staffList}
                          selectedProjects={onSelectDevelopers}
                          projectsName={AvailableProjects.map((project) => project.name)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <HeaderProjectsBox />
                <div style={{ paddingTop: '20px' }}></div>
                <div className='staff-list-wrapper'>
                  <div>
                  {
                //   staffList
                //     .filter(
                //       (item) =>
                //         (item.firstname + ' ' + item.lastname).toLowerCase().includes(NameFilter.toLowerCase()) && // Фильтруем по имени
                //       (PositionsFilter.length !== 0
                //         ? PositionsFilter.some((position) => position === item.role)
                //         : true) && // Фильтруем по должности
                //       (ProjectsFilter.length !== 0
                //         ? item.projects.some((project) => ProjectsFilter.includes(project.name)) // Проверка на наличие проекта в выбранных проектах
                //         : true)
                //     )
                //     .map((staff) => (
                    AvailableProjects && AvailableProjects
                      .filter((project) => project.name.toLowerCase().includes(NameFilter.toLowerCase()) &&
                      (developersFilter.length !== 0
                        ? developersFilter.some((employee) => // если не нужно показывать все проекты всех выбранных участников, то использовать every
                          project.assigned_developers.includes(employee.user_id)
                        )
                        : true) &&
                      (managersFilter.length !== 0
                        ? managersFilter.some((employee) => // если не нужно показывать все проекты всех выбранных участников, то использовать every
                          project.assigned_managers.includes(employee.user_id)
                        )
                        : true))
                      .map((project) => (
                    <ProjectItemBox
                      key={project.id}
                      project={project}
                      staffList={staffList}
                      openEditProjectModal={openEditProjectModal}
                      openDeleteProjectModal={openDeleteProjectModal}
                    />
                      ))}
                  </div>
                </div>
              </div>
                )}
            </div>
          </div>
        </div>
      </div>
  )
}

export default ProjectsPage
