import { useEffect, useState } from 'react'
import '../../css/summary_report_webhooks_modal.css'
import useApiWebhooks from '../../api/webhooks'
import { Alert, Snackbar } from '@mui/material'
import CloseIcon from '../../icons/icon_close.svg'

const SummaryReportWebhooksModal = ({ closeModal }) => {
  const api = useApiWebhooks()

  // Состояния для хранения времени задач
  const [reminderHour, setReminderHour] = useState('')
  const [reminderMinute, setReminderMinute] = useState('')
  const [firstReportHour, setFirstReportHour] = useState('')
  const [firstReportMinute, setFirstReportMinute] = useState('')
  const [secondReportHour, setSecondReportHour] = useState('')
  const [secondReportMinute, setSecondReportMinute] = useState('')

  const [error, setError] = useState('')
  const [settingsModel, setSettingsModel] = useState([])

  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('error')

  useEffect(() => {
    fetchDataFromBackend()
  }, [])

  const handleHourChange = (setter) => (e) => {
    const value = e.target.value
    if (value === '' || /^[0-9]{0,2}$/.test(value)) {
      setter(value)
    }
  }

  const handleMinuteChange = (setter) => (e) => {
    const value = e.target.value
    if (value === '' || /^[0-9]{0,2}$/.test(value)) {
      setter(value)
    }
  }

  const fixHourPlusThree = (hour) => {
    if (hour >= 0 && hour < 21) {
      return hour + 3
    } else if (hour === 21) {
      return 0
    } else if (hour === 22) {
      return 1
    } else if (hour === 23) {
      return 2
    }
  }

  const fixHourMinusThree = (hour) => {
    if (hour > 3 && hour <= 23) {
      return hour - 3
    } else if (hour === 2) {
      return 23
    } else if (hour === 1) {
      return 22
    } else if (hour === 0) {
      return 21
    }
  }

  // Запрос данных с бэка и установка значений в стейты
  const fetchDataFromBackend = async () => {
    const response = await api.getSummaryReportWebhooksSettings()
    if (response.isSuccess) {
    //   console.log('response webhooks settings = ', response.data)
      setSettingsModel(response.data)

      // Парсим полученные данные и обновляем стейты
      response.data.forEach((task) => {
        switch (task.task_type) {
          case 'reminderSendReport':
            setReminderHour((fixHourPlusThree(task.hour).toString()))
            setReminderMinute(task.minute.toString())
            break
          case 'firstSendSummaryReportTime':
            setFirstReportHour(fixHourPlusThree(task.hour).toString())
            setFirstReportMinute(task.minute.toString())
            break
          case 'secondSendSummaryReportTime':
            setSecondReportHour(fixHourPlusThree(task.hour).toString())
            setSecondReportMinute(task.minute.toString())
            break
          default:
            break
        }
      })
    } else {
      console.log('Ошибка при загрузке настроек:', response.error)
    }
  }

  // Валидация времени
  const validateTimes = () => {
    const reminderTimeInMinutes = parseInt(reminderHour || 0) * 60 + parseInt(reminderMinute || 0)
    const firstReportTimeInMinutes = parseInt(firstReportHour || 0) * 60 + parseInt(firstReportMinute || 0)
    const secondReportTimeInMinutes = parseInt(secondReportHour || 0) * 60 + parseInt(secondReportMinute || 0)

    if (reminderHour === '' || firstReportHour === '' || secondReportHour === '' || reminderMinute === '' || firstReportMinute === '' || secondReportMinute === '') {
      setError('Пожалуйста, заполните все поля.')
      return false
    }

    if (reminderTimeInMinutes >= firstReportTimeInMinutes) {
      setError('Время напоминания должно быть раньше времени первой отправки сводного отчета.')
      return false
    }
    if (secondReportTimeInMinutes <= firstReportTimeInMinutes) {
      setError('Время второй отправки сводного отчета должно быть позже времени первой отправки.')
      return false
    }

    setError('')
    return true
  }

  // Функция сохранения изменений
  const saveChangesToBackend = async () => {
    if (!validateTimes()) return

    const body = [
      { task_type: 'reminderSendReport', hour: fixHourMinusThree(parseInt(reminderHour)), minute: parseInt(reminderMinute), is_active: true },
      { task_type: 'firstSendSummaryReportTime', hour: fixHourMinusThree(parseInt(firstReportHour)), minute: parseInt(firstReportMinute), is_active: true },
      { task_type: 'secondSendSummaryReportTime', hour: fixHourMinusThree(parseInt(secondReportHour)), minute: parseInt(secondReportMinute), is_active: true }
    ]

    const response = await api.updateSummaryReportWebhooksSettings(body)
    if (response.isSuccess) {
      // console.log('Данные успешно обновлены:', response)
      setSnackbarSeverity('success')
      setSnackbarMessage('Настройки успешно сохранены!')
      setOpenSnackbar(true)
      setTimeout(() => {
        closeModal()
      }, 1500)
    } else {
      setSnackbarMessage(`Ошибка: ${response.error.message}`)
      setSnackbarSeverity('error')
      setOpenSnackbar(true)
    }
  }

  // Закрытие снэкбара
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const onCloseModal = () => {
    closeModal()
  }

  return (
    settingsModel && <div className='main-webhook-settings-modal-wrapper'>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <div className='close-settings-modal-wrapper'>
            <img className='close-settings-modal' onClick={onCloseModal} src={CloseIcon} />
            </div>
            {/* Время отправки напоминания загрузить отчёт */}
            <div className='setting-row'>
                <p>Время отправки напоминания загрузить отчёт:</p>
                <div className='pick-time'>
                    <span className='input-time'>
                        <input
                            maxLength={2}
                            placeholder='Часы.'
                            inputMode='numeric'
                            type='text'
                            pattern='[0-9]*'
                            value={reminderHour}
                            onChange={handleHourChange(setReminderHour)}
                            />
                    </span>
                    <p>:</p>
                    <span className='input-time'>
                        <input
                            maxLength={2}
                            placeholder='Мин.'
                            inputMode='numeric'
                            type='text'
                            pattern='[0-9]*'
                            value={reminderMinute}
                            onChange={handleMinuteChange(setReminderMinute)}
                            />
                    </span>
                </div>
            </div>
            <div className='section-divider'></div>

            {/* Время первой отправки сводного отчета */}
            <div className='setting-row'>
                <p>Время первой отправки сводного отчета:</p>
                <div className='pick-time'>
                    <span className='input-time'>
                        <input
                            maxLength={2}
                            placeholder='Часы.'
                            inputMode='numeric'
                            type='text'
                            pattern='[0-9]*'
                            value={firstReportHour}
                            onChange={handleHourChange(setFirstReportHour)}
                        />
                    </span>
                    <p>:</p>
                    <span className='input-time'>
                        <input
                            maxLength={2}
                            placeholder='Мин.'
                            inputMode='numeric'
                            type='text'
                            pattern='[0-9]*'
                            value={firstReportMinute}
                            onChange={handleMinuteChange(setFirstReportMinute)}
                        />
                    </span>
                </div>
            </div>
            <div className='section-divider'></div>

            {/* Время второй отправки сводного отчета */}
            <div className='setting-row'>
                <p>Время второй отправки сводного отчета:</p>
                <div className='pick-time'>
                    <span className='input-time'>
                        <input
                            maxLength={2}
                            placeholder='Часы.'
                            inputMode='numeric'
                            type='text'
                            pattern='[0-9]*'
                            value={secondReportHour}
                            onChange={handleHourChange(setSecondReportHour)}
                        />
                    </span>
                    <p>:</p>
                    <span className='input-time'>
                        <input
                            maxLength={2}
                            placeholder='Мин.'
                            inputMode='numeric'
                            type='text'
                            pattern='[0-9]*'
                            value={secondReportMinute}
                            onChange={handleMinuteChange(setSecondReportMinute)}
                        />
                    </span>
                </div>
            </div>

            {/* Плашка с ошибкой */}
            {error && <div className="error-message">{error}</div>}

            {/* Кнопка для сохранения изменений */}
            <div className='save-button-wrapper'>
                <div className='save-changes-result'></div>
                <div className='save-button' onClick={saveChangesToBackend} disabled={!!error}>
                    <p>Сохранить изменения</p>
                </div>
            </div>
        </div>
  )
}

export default SummaryReportWebhooksModal
