import ToolbarAdmin from "../Toolbar";
import profileButtonImage from "../../icons/profile_img_icon.svg"
import LeftIcon from "../../icons/icon_back_arrow.svg"
import calendarImage from '../../icons/calendar_icon.svg';
import leftButtonImage from '../../icons/icon_left.svg';
import rightButtonImage from '../../icons/icon_right.svg';
import "../../css/summary_day_report.css";
import "../../css/main.css";
import { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalStateContext } from '../GlobalStateContext.jsx';
import Profile from "../../jsx/Profile.jsx";
import Calendar from "../../jsx/DaysCalendar/ExternalDayListenerCalendar.jsx";
import SummaryDayReportRightBar from "./SummaryDayReportRightBar.jsx";
import SummaryDayReportItem from "./SummaryDayReportItem.jsx";
import SummaryDayReportFilter from "./SummaryDayReportFilter.jsx";

const SummaryDayReportPage = () => {

    // Получаем объект location с текущим URL
    const location = useLocation();  
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);

    const isValidDateFormat = (dateString) => {
      const regex = /^(20[0-4][0-9]|2050)-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
      return regex.test(dateString);
  };

    const initialDate = (params.get('date') && isValidDateFormat(params.get('date'))) ? new Date(params.get('date')) : new Date();
    const [selectedDate, setSelectedDate] = useState(initialDate);

    const [profile, setVisibleProfile] = useState(false);
    const [calendar, setVisibleCalendar] = useState(false);

    const [availablePlusDays, setAvailablePlusDays] = useState(true);

    const [week, setWeek] = useState({name_week: '', select_week: new Date()});
    const childRef = useRef();
    const textRef = useRef(null);
    const profileRef = useRef(null);
    const profileRef1 = useRef(null);
    const calendarRef = useRef(null);
    const calendarRef1 = useRef(null);
    const { getProfileInfo, getCurrentBackendUrl } = useContext(GlobalStateContext);

    const [reportDay, setReportDay] = useState();
    const [ReportList, setReportList] = useState([]);
    const filters = [
      'Все',
      'Работал из дома',
      'Работал из офиса',
      'Отчет не создан'
    ]
    const [filter, setFilter] = useState(0);

    const changeVisibleProfile = () => {
        setVisibleProfile(!profile);
      }

      const changeVisibleCalendar = () => {
        setVisibleCalendar(!calendar);
      }

      const MinusDay = () => {
        childRef.current.changeDayThroughArrow('-');
      };
    
      const PlusDay = () => {
        childRef.current.changeDayThroughArrow('+');
      };

    const handleClickOutside = (event) => {
        if (profileRef.current && !profileRef.current.contains(event.target) && !profileRef1.current.contains(event.target)) {
          setVisibleProfile(false);
        }
    
        if (calendarRef.current && !calendarRef.current.contains(event.target) && !calendarRef1.current.contains(event.target)) {
          setVisibleCalendar(false);
        }
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

      const updateDateInURL = (newDate) => {
        const params = new URLSearchParams(location.search);
        params.set('date', newDate);
        setReportList([]);
        getReportsList(newDate);
        navigate({
          pathname: location.pathname,
          search: params.toString(),
        });
      };

    useEffect(() => {
        const adjustFontSize = () => {
          const element = textRef.current;
          const initialFontSize = 18;
          let currentFontSize = initialFontSize;
    
          element.style.fontSize = `${initialFontSize}px`;
          element.style.overflow = 'hidden';
          while (element.clientHeight > 28) {
            currentFontSize -= 1;
            element.style.fontSize = `${currentFontSize}px`;
          }
    
          element.style.whiteSpace = 'normal';
        }
    
        adjustFontSize();
        window.addEventListener('resize', adjustFontSize);
    
        return () => {
          window.removeEventListener('resize', adjustFontSize);
        };
      }, [week]);

      const goBackToStaffPage = () => {
        navigate('/staff');
      }

      const getReportsList = async (date) => {
        await axios.get(`${getCurrentBackendUrl()}/api/summary-report?date=${date}`, {
        })
        .then((response) => {
            setReportList(response.data);
        })
        .catch((error) => {
            console.log('Error:', error);
        })
    }

    const getFormattedDate = (date) => {
        updateDateInURL(date);  
        const receivedDate = new Date(date);
        const today = new Date();
        setAvailablePlusDays(receivedDate.setHours(3, 0, 0, 0) < today.setHours(3, 0, 0, 0));
      }

      const changeDate = (day) => {
        setSelectedDate(new Date(day));
        updateDateInURL(new Date(day));
      };

      const changeNameDay = (name_day) => {
        setReportDay(name_day);
      }

      const onSelectFilter = (filter) => {
        const selectedFilter = filter;
        setFilter(selectedFilter);
      }

      const isInFilter = (item) => {
        if (filter === 0) {
          return true;
        } else if (filter === 1) {
          return item.is_work_home === true;
        } else if (filter === 2) {
          return item.is_work_home === false;
        } else if (filter === 3) {
          return item.is_work_home === null;
        }
      }

    return (
        <div className='main-wrapper'>
            <ToolbarAdmin page={"staff"} />
            <div className="content-report">
            
            <div className="header">
          <div className="back-to-staff" onClick={goBackToStaffPage}>
            <img src={LeftIcon} style={{ marginRight: "8px" }} />
            <p style={{ fontSize: "16px" }}> Вернуться к списку </p>
          </div>
          <div className="header-report-item">
            <p> { 'Сводный отчет за день' } </p>
          </div>
          <div className="header-report-item">
          <SummaryDayReportFilter filters={filters} onSelectFilter={onSelectFilter} />
          </div>
          <div className="header-report-item" style={{ padding: "0px 10px", justifyContent: "space-between" }}>
            <img src={leftButtonImage} alt="Previous Day" onClick={MinusDay} />
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "4px", cursor: "default" }} onClick={changeVisibleCalendar} ref={calendarRef1}>
              <img src={calendarImage} alt="Calendar" />
              <p style={{ margin: "0" }} ref={textRef}>{reportDay}</p>
            </div>
            { availablePlusDays && <img src={rightButtonImage} alt="Next Day" onClick={PlusDay} />}
          </div>
          <div className="header-report-item" style={{ background: "transparent" }}>
            <span className="profile-box">
              <img src={ getProfileInfo().avatar ? (getProfileInfo().avatar) : profileButtonImage } style={{ width: "2vw", height: "2vw" }} onClick={changeVisibleProfile} ref={profileRef1}/>
            </span>
          </div>
        </div>
            
                <div className="main-report" style={{ alignItems: "normal" }}>

                <div className={profile == false ? "profile-invisible" : "profile-visible"} ref={profileRef}>
            <Profile closeProfile={changeVisibleProfile}/>
          </div>
          <div className={calendar == false ? "calendar-invisible" : "calendar-visible"} ref={calendarRef}>
            <Calendar changeDayInParent={changeDate} formattedDate={getFormattedDate} ref={childRef} selectedDate={selectedDate} changeNameDay={changeNameDay} />
          </div>

          <div className="main-content">

          <div className="main-detailed-staff-info">

<div style={{padding: '0px 32px', marginBottom: '32px'}}>
  {
    ReportList.length > 0 ? ReportList.filter(item => isInFilter(item)).map((item, index) => (
      <SummaryDayReportItem key={index} report={item} />
    )) : <div className="no-report-wrapper">
      <div className="no-report">
      Загрузка отчётов...
      </div>
      </div>
  }
</div>

        </div>

        <div className="right-bar-section">
        <SummaryDayReportRightBar reportList={ReportList} currentDay={params.get('date')} />
        </div>
          </div>
                </div>
            </div>
        </div>
    )
}

export default SummaryDayReportPage;