import "../../css/detailed_review_page.css";
import BlockDayReport from './BlockDayReport';
import DefaultProjectIcon from "../../icons/default_project_icon.svg"
import { addDays } from 'date-fns';
import React, { useState, useRef, useEffect, useContext } from 'react';
import axios from 'axios';
import { GlobalStateContext } from '../GlobalStateContext';


const DetailedReviewPage = ({ dates, startDate, endDate, missedReportsDates, isModalReport, isExternalReport, externalReport, id, projectsList }) => {
    const [ReportList, setReportList] = useState([]);

    const { getCurrentBackendUrl, getProfileInfo } = useContext(GlobalStateContext);

    useEffect(() => {
        if ((startDate || endDate) && !isExternalReport) {
                getReportsList();
        }
        //console.log("Start date: ", startDate, "End date: ", endDate, "dates: ", dates);
    }, [dates]);


    useEffect(() => {
        if (isExternalReport && externalReport != undefined) {
            setReportList(transformReportsData(Array.isArray(externalReport) ? externalReport : Array.of(externalReport)).sort((a, b) => b.date - a.date));
        }
    }, [externalReport]);

    useEffect(() => {
        const missingDates = findMissingReportDates(startDate, endDate, ReportList);
        //console.log("Отсутствуют отчеты за следующие даты:", missingDates, ReportList);
        if (missedReportsDates.length !== 0) missedReportsDates(missingDates);
    }, [ReportList]);

    const getReportsList = async () => {
        await axios.get(`${getCurrentBackendUrl()}/api/reports?creator_id=${id ? id : getProfileInfo().id}&end_date=${endDate}&start_date=${startDate}`, {
        })
            .then((response) => {
                //console.log('Success parse data for weekly report:', transformReportsData(response.data));
                setReportList(transformReportsData(response.data).sort((a, b) => b.date - a.date));
            })
            .catch((error) => {
                //console.log('Error:', error);
            }) 
    }

    function transformReportsData(serverReports) {
        return serverReports.map(report => {
            // Преобразуем дату в формат Date
            const reportDate = new Date(report.date);
    
            // Преобразуем проекты, добавляя изображение и название проекта
            const projects = report.item_reports.map(item => ({
                img_project: DefaultProjectIcon,
                project_id: item.project_id,
                project_progress_text: item.text_report,
                hours_work: item.hours_work
            }));
    
            // Суммируем все часы работы по проектам в этом отчете
            const totalHours = report.item_reports.reduce((total, item) => total + item.hours_work, 0);
            const totalMinutes = report.item_reports.reduce((total, item) => total + item.minutes_work, 0);
    
            // Место работы (из work_home)
            const workPlace = report.work_home_reason || "Не указано"; // Если work_home пустое

            const isWorkHome = report.is_work_home;
    
            return {
                id: report.id,
                date: reportDate,
                projects: projects,
                is_work_home: isWorkHome,
                work_place: workPlace,
                time: totalHours + Math.round(totalMinutes / 60)
            };
        });
    }

        // Функция для получения всех дат в интервале
function getDatesInRange(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        dates.push(new Date(currentDate));  // Копируем текущую дату
        currentDate.setDate(currentDate.getDate() + 1);  // Переходим на следующий день
    }

    return dates;
}

// Функция для поиска отсутствующих дат
function findMissingReportDates(startDate, endDate, serverReports) {
    // Преобразуем стартовую и конечную дату в объекты Date
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Получаем список всех дат в указанном интервале
    const allDates = getDatesInRange(start, end);

    // Получаем список дат с отчётами
    const reportDates = serverReports.map(report => new Date(report.date).setHours(0, 0, 0, 0)); // Сравниваем только даты без времени

    // Фильтруем даты, для которых нет отчётов
    const missingDates = allDates.filter(date => !reportDates.includes(date.setHours(0, 0, 0, 0)));

    // Преобразуем даты в формат "DD.MM.YY" для вывода
    return missingDates.filter(date => date <= new Date()).map(date => date.toLocaleDateString('ru-RU'));
}
    
    return (
        <div className={isModalReport ? "main-report-modal-detailed-review" : "main-report-detailed-review"}>
            <div className={isModalReport ? "modal-container-detailed-review" : "container-detailed-review"}>
{                ReportList.map((item, index) => (
                    <BlockDayReport key={index} id={item.id} date={item.date} projects={item.projects} work_place={item.work_place} isWorkHome={item.is_work_home} projectsList={projectsList}/>
                ))}
            </div>
        </div>
    );

};

export default DetailedReviewPage;