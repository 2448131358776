import DeleteSelectItem from '../../icons/icon_delete_select_item.svg'
import ArrowSelectIcon from '../../icons/icon_select_arrow.svg'
import ProfileImageIcon from '../../icons/profile_img_icon.svg'
import React, { useState, useEffect } from 'react'
import '../../css/multiselect.css'
import '../../css/assign_staff.css'
import { StaffPositionEnum } from '../Helpers/StaffExtensions'

const AssignStaff = ({ role, userId, staff, onSelectStaff, onlyDevStaff, onlyManagerStaff, selectedStaffList }) => {
  const [SelectedItems, setSelectedItems] = useState([])
  const [VisibleArray, setVisibleArray] = useState(false)

  useEffect(() => {
    if (selectedStaffList && selectedStaffList.length > 0 && selectedStaffList[0] !== undefined && isNaN(selectedStaffList[0])) {
      setSelectedItems(selectedStaffList)
      // console.log('selectedStaffList: ', selectedStaffList)
    }
  }, [selectedStaffList])

  const changeVisibleArray = () => {
    setVisibleArray(!VisibleArray)
  }

  const AddItemInArray = (item) => {
    if (!SelectedItems.includes(item)) {
      setSelectedItems((prevItems) => [...prevItems, item])
      setVisibleArray(false)
    } else {
      if (checkIsSelected(item)) {
        DeleteItemInArray(item)
        setVisibleArray(false)
      }
    }
  }

  const DeleteItemInArray = (item) => {
    setSelectedItems((items) => { return items.filter((itemArray) => itemArray !== item) })
  }

  const checkIsSelected = (item) => {
    return SelectedItems.includes(item)
  }

  const detectSelectedStaff = () => {
    // Найти выбранного сотрудника по его ID
    const selectedStaff = staff.find(staff => staff.user_id === userId)

    // Если сотрудник не найден или assignments пустой, вернуть пустой массив
    if (!selectedStaff || !selectedStaff.assignments) {
      return
    }

    // Создать список сотрудников из assignments, сопоставляя их по user_id
    const assignedStaff = selectedStaff.assignments.map(assignment => {
      const staffResult = staff.find(staff => staff.user_id === assignment.user_id)
      return (staffResult || null)
    })

    // Фильтровать null значения (если какой-то сотрудник отсутствует в staffList)
    // console.log('assignedStaff: ', assignedStaff.filter(staff => staff !== null))
    setSelectedItems(assignedStaff.filter(staff => staff !== null))
  }

  useEffect(() => {
    onSelectStaff(SelectedItems)
    // console.log('SelectedItems: ', SelectedItems)
  }, [SelectedItems])

  useEffect(() => {
    if (!selectedStaffList) {
      detectSelectedStaff()
    }
  }, [role])

  return (
    <div className="main-multiselect-project">
      <div className="picker-multiselect-project-profile" style={{ backgroundColor: 'rgba(45, 45, 45, 1)' }} onClick={changeVisibleArray}>
        {SelectedItems.length === 0 ? <p>Выберите сотрудника</p> : ''}
        <div className="selected-items-project">
          {SelectedItems && SelectedItems.map((item, index) => (
            <div className="item-multiselect-project" key={index}>
              <img className='item-multiselect-project-icon' src={item.avatar ? item.avatar : ProfileImageIcon} />
              <p>{`${item.firstname} ${item.lastname}`}</p>
              <img src={DeleteSelectItem} className='item-multiselect-project-delete' onClick={(e) => {
                e.stopPropagation()
                DeleteItemInArray(item)
              }} />
            </div>
          ))}
        </div>
        <img className='expand-arrow' src={ArrowSelectIcon} />
      </div>
      {VisibleArray &&
        <div className="available-staff-list">
          {staff.sort((a, b) => a.lastname.localeCompare(b.lastname)).filter(staff => staff.user_id !== userId && (onlyDevStaff ? (staff.role !== StaffPositionEnum.MANAGER) : true) && (onlyManagerStaff ? (staff.role !== StaffPositionEnum.DEVELOPER) : true)).map((item, index) => (
              <div className="item-array-multiselect" key={index} onClick={() => AddItemInArray(item)}>
                <img className='item-multiselect-project-icon' src={item.avatar ? item.avatar : ProfileImageIcon} />
                <p className='staff-item-name'>{`${item.lastname} ${item.firstname}`}</p>
                <input type='checkbox' className={'item-checkbox' + (checkIsSelected(item) ? ' checked' : '')} id={index} name={index} checked={checkIsSelected(item)} />
                <label htmlFor={index}></label>
              </div>
          ))
          }
        </div>}
    </div>
  )
}

export default AssignStaff
