import React, { useState, useEffect, useContext } from 'react';
import { GlobalStateContext } from './GlobalStateContext';
import { useNavigate } from 'react-router-dom';
import Logo from '../icons/logo.svg';
import ReportIcon from '../icons/report_icon.svg';
import ReportIconActive from '../icons/report_icon_active.svg';
import HistoryReportsIcon from '../icons/report_history_icon.svg';
import HistoryReportsIconActive from '../icons/report_history_icon_active.svg';
import AdministrationIcon from '../icons/icon_administration.svg';
import ProjectsIcon from '../icons/icon_projects.svg';
import StaffIcon from '../icons/icon_staff.svg';
import "../css/toolbar.css";

const Toolbar = ({ page }) => {
    const navigate = useNavigate();
    const { getProfileInfo } = useContext(GlobalStateContext);
    const userRole = getProfileInfo().role;

    const weeks_reports = () => {
        navigate('/');
    };

    const report = () => {
        navigate('/history');
    };

    const adminPage = () => {
        navigate('/administration');
    }

    const staffPage = () => {
        navigate('/staff');
    }

    return (
<div className="toolbar">
    <div className="logo-box">
        <img src={Logo} className='logo' />
    </div>
    <div className="menu">
        <div>
            <div className={page === "write_report" ? 'element-toolbar active' : 'element-toolbar'} onClick={() => report()}>
                {page === "write_report" ? <img src={ReportIconActive} className='element-icon' /> : <img src={ReportIcon} className='element-icon' />}
                <label style={{ margin: 0 }} className={page === "write_report" ? 'element-text active' : 'element-text'}>Отчёт</label>
            </div>
            <div className={`element-toolbar ${page === "weekly_reports" ? "element-toolbar active" : "element-toolbar"}`} onClick={() => weeks_reports()}>
                {page === "weekly_reports" ? <img src={HistoryReportsIconActive} className='element-icon' /> : <img src={HistoryReportsIcon} className='element-icon' />}
                <label style={{ margin: 0 }} className={page === "weekly_reports" ? 'element-text active' : 'element-text'}>История отчётов</label>
            </div>

            {/* Администрирование и Проекты видны только при userRole 5, 6, 7 */}
            {(userRole >= 5 && userRole <= 7) && (
                <>
                    <div className={page === "administration" ? 'element-toolbar active' : 'element-toolbar'} onClick={adminPage}>
                        <img src={AdministrationIcon} className='element-icon'/>
                        <label style={{ margin: 0 }} className={page === "administration" ? 'element-text active' : 'element-text'}>Администрирование</label>
                    </div>
                    <div className={page === "projects" ? 'element-toolbar active' : 'element-toolbar'}>
                        <img src={ProjectsIcon} className='element-icon'/>
                        <label style={{ margin: 0 }} className={page === "projects" ? 'element-text active' : 'element-text'}>Проекты</label>
                    </div>
                </>
            )}

            {/* Сотрудники видны только при userRole от 4 до 7 */}
            {(userRole >= 4 && userRole <= 7) && (
                <div className={page === "staff" ? 'element-toolbar active' : 'element-toolbar'} onClick={staffPage}>
                    <img src={StaffIcon} className='element-icon'/>
                    <label style={{ margin: 0 }} className={page === "staff" ? 'element-text active' : 'element-text'}>Сотрудники</label>
                </div>
            )}
        </div>
    </div>
</div>

    );

}

export default Toolbar;