import '../../css/header_staff_box.css';
import EditReportIcon from '../../icons/three_points.svg'

const HeaderStaffBox = () => {

    return (
        <div className="header-staff-info">
            <div className="item-header-staff-info" style={{borderRight: "1px solid rgba(85, 85, 85, 1)"}}>
                Имя
            </div>
            <div className="item-header-staff-info" style={{borderRight: "1px solid rgba(85, 85, 85, 1)"}}>
                Должность
            </div>
            <div className="item-header-staff-info" style={{borderRight: "1px solid rgba(85, 85, 85, 1)"}}>
                Последние отчёты
            </div>
            <div className="item-header-staff-info" style={{borderRight: "1px solid rgba(85, 85, 85, 1)"}}>
                Проекты
            </div>
            <div className="item-header-staff-info" style={{borderRight: "1px solid rgba(85, 85, 85, 1)"}}>
                Нет отчёта
            </div>
            <div className="item-header-staff-info" style={{borderRight: "1px solid rgba(85, 85, 85, 1)"}}>
                Работа из дома
            </div>
            <div className="item-header-staff-info" style={{justifyContent: "center", margin: "0"}}>
                <img src={EditReportIcon}/>
            </div>
        </div>
    )

}

export default HeaderStaffBox;