import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { addDays, getMonth, getYear, subDays, isSameDay, format } from 'date-fns';
import './days_calendar.css';
import SelectMonth from './SelectMonth.jsx';
import SelectYear from './SelectYear.jsx';

const ExternalDayListenerCalendar = forwardRef((props, ref) => {
    const { selectedDate, changeDayInParent } = props;
    const [SelectedMonth, setSelectedMonth] = useState(getMonth(selectedDate));
    const [SelectedYear, setSelectedYear] = useState(getYear(selectedDate));
    const [SelectedDay, setSelectedDay] = useState(selectedDate);
    const childRef1 = useRef();
    const childRef2 = useRef();
    let WeeksOfMonth;
    let date;
    const names_days = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
    const names_months = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];

    const changeMonth = (month) => {
        setSelectedMonth(month);
    };

    const changeYear = (year) => {
        childRef2.current.changeCurrentYear(year);
        setSelectedYear(year);
    };

    useEffect(() => {
        setSelectedMonth(getMonth(selectedDate));
        setSelectedYear(getYear(selectedDate));
        setSelectedDay(selectedDate);
      }, [selectedDate]);

      const changeDayInContext = (day) => {
        const formattedDateString = format(day, 'yyyy-MM-dd');
        setSelectedDay(day);
        if (day.length < 12) {
            changeDayInParent(formattedDateString);  // Передаем обновленную дату в родительский компонент
        }
      };

    useImperativeHandle(ref, () => ({
        changeDayThroughArrow
    }));

    const changeDayThroughArrow = (arrow) => {
        if (arrow === '-') {
            changeDayInContext(new Date(subDays(SelectedDay, 1)))
        }
        else if (arrow === '+' && checkAvailablePlusDays()) {
            changeDayInContext(new Date(addDays(SelectedDay, 1)))
        }
    };

    const checkAvailablePlusDays = () => {
        const today = new Date();
        const nextDay = new Date(new Date(addDays(SelectedDay, 1)))
        return nextDay.setHours(0, 0, 0, 0) < today.setHours(3, 0, 0, 0);
    }

    const createWeek = (week) => {
        date = new Date(SelectedYear, SelectedMonth, 1);
        const dayElements = [];
        for (let i = 0; i <= 6; i++) {
            dayElements.push(week.start);
            week.start = new Date(addDays(week.start, 1));
        }
        return (
            dayElements.map((item) => (
                <div key={Math.random()} className={`day-days-item ${isSameDay(item, SelectedDay) ? "today" : ""} ${isAvailableDay(item) ? "" : "other"}`} style={{ cursor: isAvailableDay(item) ? "default" : "not-allowed" }} onClick={() => isAvailableDay(item) ? changeDayInContext(item) : {}}>{item.getDate()}</div>
            ))
        );
    };

    const isAvailableDay = (item) => {
        const isCurrentMonth = item.getMonth() === date.getMonth();
        const isFutureDate = new Date() < item;
        return isCurrentMonth && !isFutureDate;
    }

    function createMonth() {
        WeeksOfMonth = [];
        date = new Date(SelectedYear, SelectedMonth, 1);
        const this_month = date;
        for (let i = 0; i <= 5; i++) {
            const day = date.getDay();
            const diffToMonday = day === 0 ? 6 : day - 1;
            const startOfWeek = new Date(subDays(date, diffToMonday));
            const endOfWeek = new Date(addDays(startOfWeek, 6));
            const newWeek = {
                start: startOfWeek,
                end: endOfWeek
            };
            WeeksOfMonth.push(newWeek);
            date = new Date(addDays(date, 7));
        }
        const checkEnd = new Date(addDays(WeeksOfMonth[4].start, 7));
        if (checkEnd.getMonth() === this_month.getMonth()) {
            const day = date.getDay();
            const diffToMonday = day === 0 ? 6 : day - 1;
            const startOfWeek = new Date(subDays(date, diffToMonday));
            const endOfWeek = new Date(addDays(startOfWeek, 6));
            const newWeek = {
                start: startOfWeek,
                end: endOfWeek
            };
            WeeksOfMonth.push(newWeek);
        }
        date = new Date(subDays(date, 7));
    }

    useEffect(() => {
        const name_day = `${names_days[SelectedDay.getDay()]} ${SelectedDay.getDate()} ${names_months[SelectedDay.getMonth()]}`;
        const formattedDateString = format(SelectedDay, 'yyyy-MM-dd')
        props.formattedDate(formattedDateString)
        props.changeNameDay(name_day);
    }, [SelectedDay]);

    return (
        <div className="calendar-days-container">
            <div className="calendar-days-header">
                <SelectMonth curMonth={SelectedMonth} setMonth={changeMonth} ref={childRef2} />
                <SelectYear curYear={SelectedYear} setYear={changeYear} ref={childRef1} />
            </div>
            <div className="calendar-days-line"></div>
            <div className="calendar-days-main-header">
                <p>Пн</p>
                <p>Вт</p>
                <p>Ср</p>
                <p>Чт</p>
                <p>Пт</p>
                <p>Сб</p>
                <p>Вс</p>
            </div>
            <div className="calendar-days-main-content">
                <div className="dates-days-calendar" key={Math.random()}>
                    {createMonth()}
                    {WeeksOfMonth.map((item) => (
                        <div className="week-days-item" key={item.start}>
                            {createWeek(item)}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
});

export default ExternalDayListenerCalendar;