import '../css/main.css';
import EditReportIcon from '../icons/three_points.svg'

const HeaderWeekBox = () => {

    return(
        <div className="column-box" style={{background: "#313133"}}>
            <div className="row-box header" style={{borderRight: "1px solid rgba(85, 85, 85, 1)"}}>Дата</div>
            <div className="row-box header" style={{borderRight: "1px solid rgba(85, 85, 85, 1)"}}>Проекты</div>
            <div className="row-box header" style={{borderRight: "1px solid rgba(85, 85, 85, 1)"}}>Общее время отчета</div>
            <div className="row-box header" style={{borderRight: "1px solid rgba(85, 85, 85, 1)"}}>Работа из офиса/дома</div>
            <div className="row-box header" style={{justifyContent: "center"}}><img src={EditReportIcon}/></div>
        </div>
    );
};

export default HeaderWeekBox;