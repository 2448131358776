import React, { useState, useRef, useEffect } from 'react';
import SelectorIcon from "../icons/selector_projects_icon.svg"
import DefaultProjectIcon from "../icons/default_project_icon.svg"
import DeleteProjectIcon from "../icons/delete_project_icon.svg"
import "../css/select_project.css";

const SelectProject = ({ name_project, project_id, onProjectChange, projectsList }) => {
    const [currentProject, setCurrentProject] = useState(name_project);
    const [currentProjectId, setCurrentProjectId] = useState(project_id);
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);
    const elementRef1 = useRef(null);

    const changeProject = (project, id) => {
        const projectItem = projectsList.find(item => item.id === id)
        //console.log("founded project item: ", projectItem);
        setCurrentProject(id === 0 ? "Выбрать проект" : projectItem.name);
        setCurrentProjectId(id);

        onProjectChange(project, id);
        VisibleArray();
    };

    const VisibleArray = () => {
        setIsVisible(!isVisible);
    };

    const handleClickOutside = (event) => {
        if (elementRef.current && !elementRef.current.contains(event.target) && !elementRef1.current.contains(event.target)) {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const projectItem = projectsList ? projectsList.find(item => item.id === project_id) : "null";
        setCurrentProject(projectItem ? projectItem.name : "Выбрать проект");
    }, [projectsList, project_id]);


    return (
        <div className="cont">
            <div className="select-project-container">
                <div className="select-project-selector" ref={elementRef1} onClick={VisibleArray}>
                    {currentProject === "Выбрать проект" ? null : (<img src={DefaultProjectIcon} style={{ width: "24px", height: "24px" }} />)}
                    <p>

                        {currentProject}
                    </p>
                    <img src={SelectorIcon} />
                </div>
                {isVisible &&
                    <div className="projects-array" ref={elementRef}>
                        {projectsList.map((item, index) => (
                            <div className="projects-item" key={index} onClick={() => changeProject(item.name, item.id)}>
                                <img src={DefaultProjectIcon} />
                                <p style={{color: "#C6C6C6", fontSize: "14px"}}>{item.name}</p>
                            </div>
                        ))}
                        <div className="projects-item" onClick={() => changeProject("Выбрать проект", 0)}>
                            <img src={DeleteProjectIcon} />
                            <p style={{color: "#C6C6C6", fontSize: "14px"}}>Удалить проект</p>
                        </div>
                    </div>}
            </div>
        </div>
    )
}

export default SelectProject;