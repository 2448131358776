// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.profile-visible {
    position: fixed;
    display: flex;
    margin-right: 5px;
    margin-top: 5px;
    top: 9vh;
    right: 0.9vw;
    width: 20.8%;
    border-radius: 12px;
    background-color: #2D2D2D;
    z-index: 1000;
}

.profile-invisible {
    display: none;
}

.calendar-visible {
    position: fixed;
    display: flex;
    top: 9vh;
    right: calc(100vw - 95.3%);
    border-radius: 12px;
    background-color: #2D2D2D;
    z-index: 1000;
}

.calendar-invisible {
    display: none;
}

@media (max-width: 1024px) {
    .profile-visible {
        width: 35%
    }
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,aAAa;IACb,iBAAiB;IACjB,eAAe;IACf,QAAQ;IACR,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,QAAQ;IACR,0BAA0B;IAC1B,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI;IACJ;AACJ","sourcesContent":["\n.profile-visible {\n    position: fixed;\n    display: flex;\n    margin-right: 5px;\n    margin-top: 5px;\n    top: 9vh;\n    right: 0.9vw;\n    width: 20.8%;\n    border-radius: 12px;\n    background-color: #2D2D2D;\n    z-index: 1000;\n}\n\n.profile-invisible {\n    display: none;\n}\n\n.calendar-visible {\n    position: fixed;\n    display: flex;\n    top: 9vh;\n    right: calc(100vw - 95.3%);\n    border-radius: 12px;\n    background-color: #2D2D2D;\n    z-index: 1000;\n}\n\n.calendar-invisible {\n    display: none;\n}\n\n@media (max-width: 1024px) {\n    .profile-visible {\n        width: 35%\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
