import React, { useContext } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Main from './WeeksReport/WeeklyReportsPage.jsx'
import Login from './LoginPage.jsx'
import HistoryReportsPage from './DayReport/WriteReportPage.jsx'
import ForgotPasswordPage from './ForgotPasswordPage.jsx'
import DetailedReviewPage from './WeeksReport/DetailedReviewPage.jsx'
import AdministrationPage from './Admin-panel/AdministrationPage.jsx'
import ProjectsPage from './Admin-panel/ProjectsPage.jsx'
import DetailedStaffInfoPage from './Admin-panel/DetailedStaffInfoPage.jsx'
import StaffPage from './Admin-panel/StaffPage.jsx'
import { GlobalStateContext } from './GlobalStateContext'
import { StaffPositionEnum } from './Helpers/StaffExtensions.js'
import SummaryDayReportPage from './Admin-panel/SummaryDayReportPage.jsx'

const AdminPagesSet = [(<StaffPage />).type.name, (<DetailedStaffInfoPage />).type.name, (<AdministrationPage />).type.name, (<SummaryDayReportPage />).type.name]
const ManagerPagesSet = [(<StaffPage />).type.name, (<DetailedStaffInfoPage />).type.name]

const Application = () => {
  const { getToken, getProfileInfo } = useContext(GlobalStateContext)

  const ProtectedRoute = ({ element, isLoginStackPage }) => { // isLoginStackPage - флаг, обозначающий страницу из структуры логина (сейчас это Login/ForgotPasswordPage страницы)
    const token = getToken()

    if (!token) {
      if (isLoginStackPage === undefined) {
        return <Navigate to="/login" replace /> // Если токен пустой и вызвана страница не из структуры логина, перенаправляем на страницу логина
      } else {
        return element // Если токен пустой и вызвана страница из структуры логина, продолжаем
      }
    } else {
      if (isLoginStackPage === true) {
        return <Navigate to="/" replace /> // Если токен есть и вызвана страница из структуры логина, перенаправляем на главную страницу
      } else {
        return checkAccessToPageByUserRole(Number(getProfileInfo().role), element) // Если токен есть и вызвана страница не из структуры логина, возвращаем вызванный элемент
      }
    }
  }

  const checkAccessToPageByUserRole = (userRole, element) => {
    if ([StaffPositionEnum.DIRECTOR, StaffPositionEnum.ADMIN, StaffPositionEnum.SUPER_ADMIN].includes(userRole)) { // Проверяем, имеет ли пользователь права администратора
      return element // Если пользователь имеет права администратора, возвращаем любой вызванный элемент
    } else if ([StaffPositionEnum.MANAGER].includes(userRole)) { // Проверяем, имеет ли пользователь права менеджера
      if (ManagerPagesSet.includes(element.type.name)) {
        return element // Если пользователь имеет права менеджера и попробовал открыть страницу сотрудников, возвращаем этот элемент
      } else if (AdminPagesSet.includes(element.type.name)) {
        return <Navigate to="/" replace /> // Если пользователь имеет права менеджера и попробовал открыть админ-страницу, перенаправляем на главную страницу
      } else {
        return element // Если пользователь является менеджером и попробовал открыть общедоступную страницу, возвращаем любой вызванный элемент
      }
    } else { // В данном кейсе пользователь не является администратором и не имеет менеджерских прав
      if (AdminPagesSet.includes(element.type.name)) {
        return <Navigate to="/" replace /> // Если пользователь не имеет права администратора и попробовал открыть админ-страницу, перенаправляем на главную страницу
      } else {
        return element // Если пользователь не имеет права администратора, но попробовал открыть общедоступную страницу, возвращаем любой вызванный элемент
      }
    }
  }

  return (
    <Routes>
      {/* { Общедоступные страницы } */}
      <Route path="/" element={<ProtectedRoute element={<Main />} />} />
      <Route path="/login" element={<ProtectedRoute element={<Login />} isLoginStackPage={true} />} />
      <Route path="/write-report" element={<ProtectedRoute element={<HistoryReportsPage />} />} />
      <Route path="/forgot-password" element={<ProtectedRoute element={<ForgotPasswordPage />} isLoginStackPage={true} />} />
      <Route path="/detailed-review" element={<ProtectedRoute element={<DetailedReviewPage />} />} />

      {/* { Набор страниц, доступных только админу } */}
      <Route path="/management" element={<ProtectedRoute element={<AdministrationPage />} />} />
      <Route path="/summary-day-report" element={<ProtectedRoute element={<SummaryDayReportPage />} />} />
      <Route path="/projects" element={<ProtectedRoute element={<ProjectsPage />} />} />

      {/* { Набор страниц, доступных менеджеру и админу } */}
      <Route path="/detailed-staff-info" element={<ProtectedRoute element={<DetailedStaffInfoPage />} />} />
      <Route path="/staff" element={<ProtectedRoute element={<StaffPage />} />} />

      {/* { Любая несуществующая страница } */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

export default Application
