import IconHome from '../../icons/icon_home.svg'
import IconOffice from '../../icons/icon_office.svg'
import IconNoReport from '../../icons/icon_no_report.svg'
import IconTime from '../../icons/summary_report_item_time.svg'
import DefaultProjectIcon from '../../icons/default_project_icon.svg'
import ArrowIconShrinked from '../../icons/icon_arrow_shrinked.svg'
import ArrowIconExpanded from '../../icons/icon_arrow_expanded.svg'
import { useEffect, useState } from 'react'
import '../../css/summary_day_report.css'
import { onCopyReport } from '../Helpers/ReportExtensions'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { SvgIcon } from '@mui/material'
import { getHourString, getMinuteString } from '../Helpers/TimeExtensions'
import ClickableLinksText from '../../components/ClickableLinksText'
import { format } from 'date-fns'

const SummaryDayReportItem = ({ report, selectedDate }) => {
  const [workPlaceIcon, setWorkPlaceIcon] = useState(IconNoReport)
  const [workPlaceTitle, setWorkPlaceTitle] = useState('Отчет не создан')
  const [isExpanded, setIsExpanded] = useState(false)
  const [showSendReportDate, setShowSendReportDate] = useState(false)

  useEffect(() => {
    setShowSendReportDate(isSelectedDateGreaterThanMin())
  }, [selectedDate])

  const expandItem = () => {
    if (report.item_reports.length === 0) {
      setIsExpanded(false)
    } else {
      setIsExpanded(!isExpanded)
    }
  }

  const getWorkPlaceIcon = () => {
    if (report.is_work_home === true) {
      setWorkPlaceIcon(IconHome)
    } else if (report.is_work_home === false) {
      setWorkPlaceIcon(IconOffice)
    } else {
      setWorkPlaceIcon(IconNoReport)
    }
  }

  const getWorkPlaceTitle = () => {
    if (report.is_work_home === true) {
      setWorkPlaceTitle('Работал из дома')
    } else if (report.is_work_home === false) {
      setWorkPlaceTitle('Работал из офиса')
    } else {
      setWorkPlaceTitle('Отчет не создан')
    }
  }

  function sumHoursWork () {
    let resultHours = 0
    let resultMinutes = 0

    // eslint-disable-next-line array-callback-return
    report.item_reports.map(itemReport => {
      resultHours = resultHours + itemReport.hours_work
      resultMinutes = resultMinutes + itemReport.minutes_work
    })

    return resultHours + Math.floor(resultMinutes / 60)
  }

  useEffect(() => {
    if (report) {
      getWorkPlaceIcon()
      getWorkPlaceTitle()
    }
  }, [report])

  const handleCopyAllReportInfo = () => {
    const projectNamesList = []
    report.item_reports.map(item => projectNamesList.push(item.project_name))
    onCopyReport(report.item_reports, null, null, projectNamesList)
  }

  const handleCopyBlockReportInfo = (item) => {
    onCopyReport([item], null, item.project_name)
  }

  function formatDate (inputDate) {
    const date = new Date(inputDate)

    date.setHours(date.getHours() - 3)

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()

    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')

    return `${day}.${month}.${year} в ${hours}:${minutes}`
  }

  function isSelectedDateGreaterThanMin () {
    const selected = new Date(selectedDate) // Сегодняшняя дата
    const comparisonDate = new Date('2025-03-12') // 12 марта 2025 года

    return selected > comparisonDate
  }

  if (!isExpanded) {
    return (
      <div className="summary-report-item-shrinked">
        {(report.updated_at && showSendReportDate === true) && (
          <div className='report-send-date'>Время отправки отчёта: {formatDate(report.updated_at)}</div>
        )}
        <div className="summary-report-item-shrinked-topbar" style={{ alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div onClick={expandItem}>
              <img
                className={'summary-report-item-shrinked-arrow-' + (report.item_reports.length === 0 ? 'disabled' : '')}
                src={ArrowIconShrinked}
                alt="expand"
              />
              <span className="summary-report-item-shrinked-title">{report.name}</span>
            </div>
            {
              report.item_reports.length > 0 &&
              <SvgIcon className='copy-report-block' alt="copy" component={ContentCopyIcon} style={{ marginLeft: '16px', marginTop: '4px', width: '18px', height: '18px', padding: '4px' }} onClick={handleCopyAllReportInfo}></SvgIcon>
            }
          </div>
          <div className="summary-report-item-shrinked-workplace">
            <img src={workPlaceIcon} alt="workplace" />
            <span>{workPlaceTitle}</span>
          </div>
        </div>
        <div className="summary-report-item-shrinked-projectbar">
          <div className="summary-report-item-projects">
            {report.item_reports.length > 0
              ? report.item_reports.map((item, index) => (
                <div key={index} className="summary-report-item-project">
                  <img src={DefaultProjectIcon} alt="project" />
                  <span>{item.project_name}</span>
                </div>
              ))
              : <span className="summary-report-item-project-not-selected">Проекты не выбраны</span>
            }
          </div>
          <div className="summary-report-item-shrinked-time">
            <img src={IconTime} alt="time" />
            <span className="summary-report-item-shrinked-projectbar-span">{sumHoursWork()} ч.</span>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="summary-report-item-shrinked">
        {(report.updated_at && showSendReportDate === true) && (
          <div className='report-send-date'>Время отправки отчёта: {formatDate(report.updated_at)}</div>
        )}
        <div className="summary-report-item-shrinked-topbar" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <div onClick={expandItem}>
            <img src={ArrowIconExpanded} alt="expand" />
            <span className="summary-report-item-shrinked-title">{report.name}</span>
          </div>
          <div className='copy-report-block' style={{ height: '14px' }} onClick={handleCopyAllReportInfo}>Скопировать весь отчет</div>
          <div className="summary-report-item-shrinked-workplace">
            <img src={workPlaceIcon} alt="workplace" />
            <span>{workPlaceTitle}</span>
          </div>
        </div>
        <div className="summary-report-item-shrinked-projectbar">
          <div className="summary-report-item-projects-title">Проекты</div>
          <div className="summary-report-item-shrinked-time">
            <img className="summary-report-item-shrinked-projectbar-img" src={IconTime} alt="time" />
            <span className="summary-report-item-shrinked-projectbar-span">{sumHoursWork()} ч.</span>
          </div>
        </div>
        <div className="summary-report-item-projects-expanded">
          {report.item_reports.length > 0 && report.item_reports.map((item, index) => (
            <div key={index}>
              <div className='summary-report-item-project-expanded-top'>
                <div className="summary-report-item-project">
                  <img src={DefaultProjectIcon} alt="project" />
                  <span>{item.project_name}</span>
                  <SvgIcon className='copy-report-block' alt="copy" component={ContentCopyIcon} style={{ marginLeft: '12px', width: '18px', height: '18px', padding: '4px' }} onClick={() => handleCopyBlockReportInfo(item)}></SvgIcon>
                </div>
                <span className="summary-report-item-expanded-projectbar-span">{getHourString(item.hours_work) + ' ' + getMinuteString(item.minutes_work)}</span>
              </div>
              <div className="summary-report-item-project-expanded-bottom">
                <ClickableLinksText text={item.text_report} />
              </div>
            </div>
          ))
          }
        </div>
      </div>
    )
  }
}

export default SummaryDayReportItem
