import IconHome from '../../icons/icon_home.svg';
import IconOffice from '../../icons/icon_office.svg';
import IconNoReport from '../../icons/icon_no_report.svg';
import IconTime from '../../icons/summary_report_item_time.svg';
import DefaultProjectIcon from "../../icons/default_project_icon.svg"
import ArrowIconShrinked from "../../icons/icon_arrow_shrinked.svg";
import ArrowIconExpanded from "../../icons/icon_arrow_expanded.svg";
import { useEffect, useState } from 'react';
import '../../css/summary_day_report.css';

const SummaryDayReportItem = ({ report }) => {

    const [workPlaceIcon, setWorkPlaceIcon] = useState(IconNoReport);
    const [workPlaceTitle, setWorkPlaceTitle] = useState("Отчет не создан");
    const [isExpanded, setIsExpanded] = useState(false);

    const expandItem = () => {
        if (report.item_reports.length === 0) {
            setIsExpanded(false);
        } else {
            setIsExpanded(!isExpanded);
        }
    }

    const getWorkPlaceIcon = () => {
        if (report.is_work_home === true) {
            setWorkPlaceIcon(IconHome);
        } else if (report.is_work_home === false) {
            setWorkPlaceIcon(IconOffice);
        } else {
            return
        }
    }

    const getWorkPlaceTitle = () => {
        if (report.is_work_home === true) {
            setWorkPlaceTitle("Работал из дома");
        } else if (report.is_work_home === false) {
            setWorkPlaceTitle("Работал из офиса");
        } else {
            setWorkPlaceTitle("Отчет не создан");
        }
    }

    function sumHoursWork() {
        let resultHours = 0;
        let resultMinutes = 0;
  
          report.item_reports.map(itemReport => {
            resultHours = resultHours + itemReport.hours_work;
            resultMinutes = resultMinutes + itemReport.minutes_work;
          })
  
        return resultHours + Math.floor(resultMinutes / 60);
    }

    useEffect(() => {
        if (report) {
            getWorkPlaceIcon();
            getWorkPlaceTitle();
        }
    }, [report])

    if (!isExpanded) {
        return (
        <div className="summary-report-item-shrinked" onClick={expandItem}>
        <div className="summary-report-item-shrinked-topbar">
        <div>
        <img 
        className={`summary-report-item-shrinked-arrow-` + (report.item_reports.length === 0 ? "disabled" : "")} 
        src={ArrowIconShrinked} 
        alt="expand" 
        />
        <span className="summary-report-item-shrinked-title">{ report.name }</span>
        </div>
        <div className="summary-report-item-shrinked-workplace">
        <img src={workPlaceIcon} alt="workplace"/>
        <span>{workPlaceTitle}</span>
        </div>
        </div>
        <div className="summary-report-item-shrinked-projectbar">
          <div className="summary-report-item-projects">
            { report.item_reports.length > 0 ? report.item_reports.map(item => (
            <div className="summary-report-item-project">
            <img src={DefaultProjectIcon} alt="project"/>
            <span>{ item.project_name }</span>
            </div>
            ))
            : <span className="summary-report-item-project-not-selected">Проекты не выбраны</span>
        }
          </div>
          <div className="summary-report-item-shrinked-time">
            <img src={IconTime} alt="time"/>
            <span className="summary-report-item-shrinked-projectbar-span">{sumHoursWork()} ч.</span>
          </div>
        </div>
      </div>
        )
    } else {
        return (
            <div className="summary-report-item-shrinked" onClick={expandItem}>
            <div className="summary-report-item-shrinked-topbar">
            <div>
            <img src={ArrowIconExpanded} alt="expand" />
            <span className="summary-report-item-shrinked-title">{ report.name }</span>
            </div>
            <div className="summary-report-item-shrinked-workplace">
            <img src={workPlaceIcon} alt="workplace"/>
            <span>{workPlaceTitle}</span>
            </div>
            </div>
            <div className="summary-report-item-shrinked-projectbar">
                <div className="summary-report-item-projects-title">Проекты</div>
              <div className="summary-report-item-shrinked-time">
                <img className="summary-report-item-shrinked-projectbar-img" src={IconTime} alt="time"/>
                <span className="summary-report-item-shrinked-projectbar-span">{ sumHoursWork() } ч.</span>
              </div>
            </div>
            <div className="summary-report-item-projects-expanded">
                { report.item_reports.length > 0 && report.item_reports.map(item => (
                <div>
                <div className='summary-report-item-project-expanded-top'>
                <div className="summary-report-item-project">
                <img src={DefaultProjectIcon} alt="project"/>
                <span>{ item.project_name }</span>
                </div>
                <span className="summary-report-item-expanded-projectbar-span">{ item.hours_work } часа</span>
                </div>
                <div className="summary-report-item-project-expanded-bottom">
                <span>{ item.text_report }</span>
                </div>
                </div>
                ))
            }
              </div>
          </div>
        );  
    }
}

export default SummaryDayReportItem;