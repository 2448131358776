import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef} from 'react';
import { addDays, getMonth, getYear, subDays, isSameDay } from 'date-fns';
import './weeks_calendar.css';
import SelectMonth from './SelectMonth.jsx';
import SelectYear from './SelectYear.jsx';

const Calendar = forwardRef((props, ref) => {
    const [SelectRadioButton, setRadioButton] = useState(1);
    const [SelectedMonth, setSelectedMonth] = useState(getMonth(new Date()));
    const [SelectedYear, setSelectedYear] = useState(getYear(new Date()));
    const [VisibleHideSection, setVisibleHideSection] = useState(false);
    const childRef1 = useRef();
    const childRef2 = useRef();
    let WeeksOfMonth;
    let date;
    const names_months = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];
    const [availableWeeks, setAvailableWeeks] = useState([false, false, false, false, false, false]);
    

    const setRadio = (id) => {
        createMonth();
        setRadioButton(id);
        ////console.log(id);
        //const name_week = `${(WeeksOfMonth[SelectRadioButton - 1].start.getDate()).toString().padStart(2, '0')} ${names_months[WeeksOfMonth[SelectRadioButton - 1].start.getMonth()]} - ${(WeeksOfMonth[SelectRadioButton - 1].end.getDate()).toString().padStart(2, '0')} ${names_months[WeeksOfMonth[SelectRadioButton - 1].end.getMonth()]}`;
        ////console.log("aaa:", WeeksOfMonth[SelectRadioButton - 1]);
        //props.changeWeekly(name_week, WeeksOfMonth[SelectRadioButton - 1], SelectedYear);
    };

    const checkIsFutureWeek = () => {
        const today = new Date();
        const isFutureWeek = WeeksOfMonth[SelectRadioButton - 1].end.setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0);
        props.nextWeekAvailable(isFutureWeek);
        return isFutureWeek;
    }

    const changeWeekThroughArrow = (arrow) => {
        if (arrow === '-') {
            if (SelectRadioButton === 1){
                setRadio(5);
                if (SelectedMonth === 0) {
                    setSelectedMonth(11);
                    setSelectedYear(SelectedYear - 1);
                    childRef1.current.changeYearWithoutVisible({ id: 0, name: SelectedYear - 1});
                    childRef2.current.changeMonthWithoutVisible({ id: 11, name: "none"});
                }
                else {
                    setSelectedMonth(SelectedMonth - 1);
                    childRef2.current.changeMonthWithoutVisible({ id: SelectedMonth - 1, name: "none"});
                }
            }
            else {
                setRadio(SelectRadioButton - 1);
            }
        }
        else if (arrow === '+') {
            if (SelectRadioButton === 5){
                setRadio(1);
                if (SelectedMonth === 11) {
                    setSelectedMonth(0);
                    setSelectedYear(SelectedYear + 1);
                    childRef1.current.changeYearWithoutVisible({ id: 0, name: SelectedYear + 1});
                    childRef2.current.changeMonthWithoutVisible({ id: 0, name: "none"});
                }
                else {
                    setSelectedMonth(SelectedMonth + 1);
                    childRef2.current.changeMonthWithoutVisible({ id: SelectedMonth + 1, name: "none"});
                }
            }
            else {
                setRadio(SelectRadioButton + 1);
            }
        }
    };

    useImperativeHandle(ref, () => ({
        changeWeekThroughArrow
    }));

    const changeMonth = (month) => {
        setSelectedMonth(month);
    };

    const changeYear = (year) => {
        childRef2.current.changeCurrentYear(year);
        setSelectedYear(year);
    };

    const createWeek = (week) => {
        date = new Date(SelectedYear, SelectedMonth, 1);
        const dayElements = [];
        for (let i = 0; i <= 6; i++) {
            dayElements.push(week.start);
            week.start = new Date(addDays(week.start, 1));
        }
        return (
            dayElements.map((item) => (
                <div key={Math.random()} className={`day-item ${isSameDay(item, new Date()) ? "today" : ""} ${isAvailableDay(item) ? "" : "other"}`}>{item.getDate()}</div>
            ))
        );
    };

    const isAvailableDay = (item) => {
        const isCurrentMonth = item.getMonth() === date.getMonth();
        const isFutureDate = new Date() < item;
        return isCurrentMonth && !isFutureDate;
    }

    function createMonth() {
        WeeksOfMonth = [];
        date = new Date(SelectedYear, SelectedMonth, 1);
        const this_month = date;
        for (let i = 0; i <= 5; i++) {
            const day = date.getDay();
            const diffToMonday = day === 0 ? 6 : day - 1;
            const startOfWeek = new Date(subDays(date, diffToMonday));
            const endOfWeek = new Date(addDays(startOfWeek, 6));
            const newWeek = {
                start: startOfWeek,
                end: endOfWeek
            };
            WeeksOfMonth.push(newWeek);
            date = new Date(addDays(date, 7));

            availableWeeks[i] = new Date() >= newWeek.start;
        }
        date = new Date(subDays(date, 7));
    };


    useEffect(() => {
        createMonth();
        const nowDate = new Date();
        WeeksOfMonth.forEach((item, index) => {
            if (nowDate >= item.start && nowDate <= item.end.setHours(23, 59, 0, 0)) {
                setRadio(index + 1);
            }
        });
    }, []);

    useEffect(() => {
        checkIsFutureWeek();
    }, [SelectRadioButton]);

    useEffect(() => {
        createMonth();
        if (WeeksOfMonth[5] != undefined){
            setVisibleHideSection(true);
            //console.log(VisibleHideSection);
            const name_week = `${(WeeksOfMonth[SelectRadioButton - 1].start.getDate()).toString().padStart(2, '0')} ${names_months[WeeksOfMonth[SelectRadioButton - 1].start.getMonth()]} - ${(WeeksOfMonth[SelectRadioButton - 1].end.getDate()).toString().padStart(2, '0')} ${names_months[WeeksOfMonth[SelectRadioButton - 1].end.getMonth()]}`;
            //console.log(WeeksOfMonth[SelectRadioButton - 1]);
            props.changeWeekly(name_week, WeeksOfMonth[SelectRadioButton - 1], SelectedYear);
        }
        else {
            setVisibleHideSection(false);
            if (SelectRadioButton === 6) {
                setRadioButton(5);
                const name_week = `${(WeeksOfMonth[4].start.getDate()).toString().padStart(2, '0')} ${names_months[WeeksOfMonth[4].start.getMonth()]} - ${(WeeksOfMonth[4].end.getDate()).toString().padStart(2, '0')} ${names_months[WeeksOfMonth[4].end.getMonth()]}`;
                props.changeWeekly(name_week, WeeksOfMonth[4], SelectedYear);
            }
            else {
                const name_week = `${(WeeksOfMonth[SelectRadioButton - 1].start.getDate()).toString().padStart(2, '0')} ${names_months[WeeksOfMonth[SelectRadioButton - 1].start.getMonth()]} - ${(WeeksOfMonth[SelectRadioButton - 1].end.getDate()).toString().padStart(2, '0')} ${names_months[WeeksOfMonth[SelectRadioButton - 1].end.getMonth()]}`;
                props.changeWeekly(name_week, WeeksOfMonth[SelectRadioButton - 1], SelectedYear);
            }
        }
        if (availableWeeks[SelectRadioButton - 1] === false) {
            const lastAvailableWeek = availableWeeks.lastIndexOf(true);
            setRadioButton(lastAvailableWeek + 1);
        }

    }, [SelectedMonth, SelectedYear, SelectRadioButton]);

    return (
        <div className="calendar-container">
            <div className="calendar-header">
                <SelectMonth setMonth={changeMonth} ref={childRef2}/>
                <SelectYear setYear={changeYear} ref={childRef1}/>
            </div>
            <div className="calendar-line"></div>
            <div className="calendar-main-header">
                <p style={{ fontFamily: "RobotoRegular", fontSize: "12px" }}>Неделя</p>
                <p>Пн</p>
                <p>Вт</p>
                <p>Ср</p>
                <p>Чт</p>
                <p>Пт</p>
                <p>Сб</p>
                <p>Вс</p>
            </div>
            <div className="calendar-main-content">
                <div className={!VisibleHideSection ? "checkboxes-calendar" : "checkboxes-calendar visible"}>
                    <div className="calendar-checkbox-container" onClick={() => availableWeeks[0] ? setRadio(1) : {}}>
                        <input
                            type="radio"
                            checked={SelectRadioButton === 1 ? true : false}
                            disabled={availableWeeks[0] === false ? true : false}
                        />
                        <label> </label>
                    </div>
                    <div className="calendar-checkbox-container" onClick={() => availableWeeks[1] ? setRadio(2) : {}}>
                        <input
                            type="radio"
                            checked={SelectRadioButton === 2 ? true : false}
                            disabled={availableWeeks[1] === false ? true : false}
                        />
                        <label> </label>
                    </div>
                    <div className="calendar-checkbox-container" onClick={() => availableWeeks[2] ? setRadio(3) : {}}>
                        <input
                            type="radio"
                            checked={SelectRadioButton === 3 ? true : false}
                            disabled={availableWeeks[2] === false ? true : false}
                        />
                        <label> </label>
                    </div>
                    <div className="calendar-checkbox-container" onClick={() => availableWeeks[3] ? setRadio(4) : {}}>
                        <input
                            type="radio"
                            checked={SelectRadioButton === 4 ? true : false}
                            disabled={availableWeeks[3] === false ? true : false}
                        />
                        <label> </label>
                    </div>
                    <div className="calendar-checkbox-container" onClick={() => availableWeeks[4] ? setRadio(5) : {}}>
                        <input
                            type="radio"
                            checked={SelectRadioButton === 5 ? true : false}
                            disabled={availableWeeks[4] === false ? true : false}
                        />
                        <label> </label>
                    </div>
                    <div className="calendar-checkbox-container" onClick={() => availableWeeks[5] ? setRadio(6) : {}}>
                        <input
                            type="radio"
                            checked={SelectRadioButton === 6 ? true : false}
                            disabled={availableWeeks[5] === false ? true : false}
                        />
                        <label> </label>
                    </div>
                </div>
                <div className="dates-calendar">
                    {createMonth()}
                    {WeeksOfMonth.map((item) => (
                        <div className="week-item" key={item.start}>
                            {createWeek(item)}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
});

export default Calendar;