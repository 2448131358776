import CloseIcon from '../../icons/icon_close.svg'
import '../../css/project_edit_modal.css'
import { StaffPositionEnum } from '../Helpers/StaffExtensions'
import { useState, useEffect } from 'react'
import AssignStaff from './AssignStaff.jsx'
import useApiProjects from '../../api/projects.js'

const ProjectEditModal = (props) => {
  const api = useApiProjects()
  const [project, setProject] = useState(props.modalInfo)
  const [apiError, setApiError] = useState(null)

  const setProjectNameFilter = (value) => {
    setProject({ ...project, name: value })
  }

  const setProjectDescriptionFilter = (value) => {
    setProject({ ...project, description: value })
  }

  const onSelectDevelopers = (selectedStaff) => {
    setProject({ ...project, assigned_developers: selectedStaff.map((staff) => staff.user_id) })
  }

  const onSelectManagers = (selectedStaff) => {
    setProject({ ...project, assigned_managers: selectedStaff.map((staff) => staff.user_id) })
  }

  useEffect(() => {
    if (apiError) {
      setApiError(null)
    }
    // console.log(props)
  }, [project])

  const onSaveEditProject = async () => {
    const body = {
      name: project.name,
      description: project.description ? project.description : '',
      assigned_managers: (project.assigned_managers && isNaN(project.assigned_managers[0])) ? project.assigned_managers.map((item) => item.user_id) : project.assigned_managers,
      assigned_developers: project.assigned_developers && isNaN(project.assigned_developers[0]) ? project.assigned_developers.map((item) => item.user_id) : project.assigned_developers
    }
    const response = await api.updateProject(body, project.id)
    if (response.isSuccess === true) {
      props.closeModal(true)
    } else {
      setApiError(response.error.response)
      console.error('Error:', response.error)
    }
  }

  const onSaveNewProject = async () => {
    if (isAllowedToCreateNewProject() === false) {
      return
    }
    const body = {
      name: project.name,
      description: project.description ? project.description : '',
      assigned_managers: project.assigned_managers,
      assigned_developers: project.assigned_developers
    }
    const response = await api.createProject(body)
    if (response) {
      props.closeModal(true)
    } else {
      // setApiError(error.response.data.message)
    }
  }

  const onCloseModal = () => {
    props.closeModal(false)
  }

  const isAllowedToCreateNewProject = () => {
    if (props.type === 'add') {
      const result =
        (
          project.name &&
          project.name.length > 0
        ) === true
      return result
    }
    if (props.type === 'edit') {
      const result =
        (project.name &&
          project.name.length > 0
        ) === true
      // console.log(profile.first, profile.first.length, profile.last, profile.role, result, props.type)
      return result
    }
  }

  return (
    <div>
      <div>
        <div className='top-modal-bar'>
          <p className='modal-title'>{props.type === 'edit' ? 'Администрирование проекта' : 'Новый проект'}</p>
          <img className='close-info' onClick={onCloseModal} src={CloseIcon} />
        </div>
        <div className='staff-names-section'>
          <p className='staff-name-title'>Название</p>
          <div className='staff-inputs-wrapper'>
            {/* <input
              className='staff-name-input'
              placeholder='Имя'
              value={profile.first}
              onChange={(e) => setFirstnameFilter(e.target.value)}
            ></input> */}
            <input
              className='project-input'
              placeholder='Название'
              value={project.name}
              onChange={(e) => setProjectNameFilter(e.target.value)}
            ></input>
          </div>
        </div>
        <div className='staff-slack-id-section'>
          <p className='staff-name-title'>Описание/Стек технологии</p>
          <div className='staff-inputs-wrapper'>
            <textarea
              style={{ maxLines: 5 }}
              className='project-input-multiline'
              placeholder='Описание/Стек технологии'
              value={project.description}
              onChange={(e) => setProjectDescriptionFilter(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className='divider'></div>
        <div className='staff-assignes-section'>
          <p className='staff-role-title'>Назначить менеджера</p>
          <div className='staff-assignes-selectors'>
              <AssignStaff
                onlyManagerStaff
                onSelectStaff={onSelectManagers}
                selectedStaffList={project.assigned_managers}
                staff={props.staffList}
                role={props.modalInfo.role}
                userId={props.modalInfo.id}
              />
          </div>
        </div>
        <div className='staff-assignes-section'>
          <p className='staff-role-title'>Назначить разработчика</p>
          <div className='staff-assignes-selectors'>
              <AssignStaff
                onlyDevStaff
                onSelectStaff={onSelectDevelopers}
                selectedStaffList={project.assigned_developers}
                staff={props.staffList}
                role={props.modalInfo.role}
                userId={props.modalInfo.id}
              />
          </div>
        </div>
        <div className='save-changes-wrapper'>
          { apiError && <p className='api-error'>{apiError}</p>}
          <button
            className={`button-save-changes${isAllowedToCreateNewProject() === true ? '' : '-disabled'}`}
            onClick={props.type === 'edit' ? onSaveEditProject : onSaveNewProject}
          >
            Сохранить изменения
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProjectEditModal
