import DeleteSelectItem from '../icons/icon_delete_select_item.svg';
import ArrowSelectIcon from '../icons/icon_select_arrow.svg';
import React, { useState, useEffect } from 'react';
import '../css/multiselect.css';
import { StaffPositionEnum } from './Helpers/StaffExtensions';

const MultiSelectText = ( {selectedPositions, availablePositions} ) => {
    const [SelectedItems, setSelectedItems] = useState([]);
    const [VisibleArray, setVisibleArray] = useState(false);
    const ArrayItems = availablePositions ? availablePositions : ['Разработчик', 'Менеджер']

    useEffect(() => {
        selectedPositions(SelectedItems.map(role => StaffPositionEnum[role]));
    }, [SelectedItems]);

    const changeVisibleArray = () => {
        setVisibleArray(!VisibleArray);
    }

    const AddItemInArray = (item) => {
        if (!SelectedItems.includes(item)) {
            setSelectedItems((prevItems) => [...prevItems, item]);
        }
    }

    const DeleteItemInArray = (item) => {
        setSelectedItems((items) => { return items.filter((item_array) => item_array != item)});
    }

    return (
        <div className="main-multiselect">
            <div className="picker-multiselect" onClick={changeVisibleArray}>
                {SelectedItems.length === 0 ? <p>Выберите должность</p> : ""}
                <div className="selected-items">
                {SelectedItems.map((item, index) => (
                    <div className="item-multiselect" key={index}>
                        <p>{item}</p>
                        <img src={DeleteSelectItem} onClick={(e) => {
                e.stopPropagation(); 
                DeleteItemInArray(item);
              }}/>
                    </div>
                ))}
                </div>
                <img className='expand-arrow' src={ArrowSelectIcon} />
            </div>
            {VisibleArray &&
            <div className="array-multiselect-items">
                {ArrayItems.map((item, index) => (
                    <div className="item-array-multiselect" key={index} onClick={() => AddItemInArray(item)}>
                        <p>{item}</p>
                    </div>
                ))}
            </div>}
        </div>
    )

}

export default MultiSelectText;