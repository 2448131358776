import "../css/forgot_password.css";
import { GlobalStateContext } from './GlobalStateContext';
import React, { useContext } from 'react';

function ForgotPasswordPage() {
    const { enableDarkMode }  = useContext(GlobalStateContext);


    return (
        <div className={`container-forgot-page ${enableDarkMode > 1 ? "dark" : ""}`}>
            <div className={`container-forgot ${enableDarkMode > 1 ? "dark" : ""}`}>
                <div className="forgot-password-box">
                    <div>
                        <label className={`label-forgot-password ${enableDarkMode > 1 ? "dark" : ""}`}>Забыли пароль?</label>
                        <p className={`p-bold-forgot-password ${enableDarkMode > 1 ? "dark" : ""}`}>Не о чем беспокоиться, мы отправим вам сообщение, которое поможет вам сбросить пароль</p>
                    </div>
                </div>
                <form className="form-forgot-password">
                    <p className={`p-regular-forgot-password ${enableDarkMode > 1 ? "dark" : ""}`}>
                        Email Address
                    </p>
                    <input
                        className={`input-forgot-password ${enableDarkMode > 1 ? "dark" : ""}`}
                        style={{ marginBottom: 32 }}
                        type="text"
                        id="email"
                        placeholder="Введите личный или рабочий адрес электронной почты"
                    />
                    <button className={`button-forgot-password ${enableDarkMode > 1 ? "dark" : ""}`}>
                        Отправить ссылку для сброса
                    </button>
                </form>
            </div>
        </div>
    )
}

export default ForgotPasswordPage;