import ToolbarAdmin from '../Toolbar.jsx'
import '../../css/administration.css'
import '../../css/main.css'
import HeaderAdminBox from './HeaderAdminBox.jsx'
import SearchIcon from '../../icons/icon_search.svg'
import MultiSelectProjects from '../MultiSelectProjects.jsx'
import AdministrationUserBox from './AdministrationUserBox.jsx'
import { GlobalStateContext } from '../GlobalStateContext.jsx'
import { useContext, useEffect, useRef, useState } from 'react'
import Profile from '../Profile.jsx'
import Modal from 'react-modal'
import MultiSelectRole from '../MultiSelectRole.jsx'
import { StaffPositionEnum } from '../Helpers/StaffExtensions.js'
import ProfileEditModal from './ProfileEditModal.jsx'
import ProfileDeleteModal from './ProfileDeleteModal.jsx'
import useApiProjects from '../../api/projects.js'
import useApiAdministration from '../../api/administration.js'

const AdministrationPage = () => {
  const apiProjects = useApiProjects()
  const apiAdministration = useApiAdministration()
  const profileRef = useRef(null)
  const profileRef1 = useRef(null)
  const [profile, setVisibleProfile] = useState(false)
  const { getProfileInfo } = useContext(GlobalStateContext)
  const [staffList, setStaffList] = useState([])
  const [NameFilter, setNameFilter] = useState('')
  const [PositionsFilter, setPositionsFilter] = useState([])
  const [ProjectsFilter, setProjectsFilter] = useState([])
  const [AvailableProjects, setAvailableProjects] = useState([])
  const [AvailableFilterPositions, setAvailableFilterPositions] = useState([])
  const [loading, setLoading] = useState(true)

  const [profileModalOpened, setProfileModalOpened] = useState(false)
  const [deleteProfileModalOpened, setDeleteProfileModalOpened] = useState(false)
  const [profileModalInfo, setProfileModalInfo] = useState({})
  const [profileModalType, setProfileModalType] = useState('')

  const onSelectPositions = (selectedPositions) => {
    setPositionsFilter(selectedPositions)
  }

  const onSelectProjects = (selectedProjects) => {
    setProjectsFilter(selectedProjects)
  }

  const changeVisibleProfile = () => {
    setVisibleProfile(!profile)
  }

  const handleClickOutside = (event) => {
    if (
      profileRef.current &&
      !profileRef.current.contains(event.target) &&
      !profileRef1.current.contains(event.target)
    ) {
      setVisibleProfile(false)
    }
  }

  const getStaffList = async () => {
    setAvailableFilterPositions([StaffPositionEnum.DEVELOPER, StaffPositionEnum.MANAGER])
    const response = await apiAdministration.loadAdministrationPage()
    if (response.isSuccess === true) {
      setStaffList(response.data)
      setLoading(false)
    }
  }

  const fetchProjects = async () => {
    const response = await apiProjects.fetchProjects()

    if (response.isSuccess === true) {
      setAvailableProjects(response.data)
    }
  }

  useEffect(() => {
    getStaffList()
    fetchProjects()
  }, [getProfileInfo])

  const openEditProfileModal = (id, first, last, role, email, slackId, date, projects, assignments) => {
    setProfileModalType('edit')
    setProfileModalOpened(true)
    setProfileModalInfo({ id, first, last, role, email, slackId, date, projects, assignments })
  }

  const openDeleteProfileModal = (id, first, last) => {
    setDeleteProfileModalOpened(true)
    setProfileModalInfo({ id, first, last })
  }

  const openCreateProfileModal = () => {
    setProfileModalType('add')
    setProfileModalOpened(true)
  }

  const closeEditProfileModal = (withReload) => {
    setProfileModalType('')
    setDeleteProfileModalOpened(false)
    setProfileModalOpened(false)
    setProfileModalInfo({})
    if (withReload) {
      setLoading(true)
      getStaffList()
      fetchProjects()
    }
  }

  const customStyles = {
    content: {
      display: 'inline-block',
      width: '600px',
      maxWidth: '50vw',
      maxHeight: '80vh',
      padding: '32px 40px 39px 40px',
      border: 'none',
      outline: 'none',
      borderRadius: '12px',
      backgroundColor: '#313133',
      overflowY: 'scroll'
    },
    overlay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(18, 22, 25, 0.75)',
      margin: 'auto',
      zIndex: 1000
    }
  }

  const filteredStaff = staffList
    ?.filter(
      (item) =>
        (item.firstname + ' ' + item.lastname).toLowerCase().includes(NameFilter.toLowerCase()) && // Фильтруем по имени
      (PositionsFilter.length !== 0
        ? PositionsFilter.some((position) => position === item.role)
        : true) && // Фильтруем по должности
      (ProjectsFilter.length !== 0
        ? item.projects.some((project) => ProjectsFilter.includes(project.name)) // Проверка на наличие проекта в выбранных проектах
        : true)
    )
    .sort((a, b) => a.lastname.localeCompare(b.lastname))

  return (
    <div className='main-wrapper' onClick={handleClickOutside}>
      <ToolbarAdmin page={'administration'} />
      <Modal className='modal-content' isOpen={profileModalOpened} style={customStyles}>
        <ProfileEditModal
          type={profileModalType}
          modalInfo={profileModalInfo}
          projectsList={AvailableProjects}
          staffList={staffList}
          closeModal={closeEditProfileModal}
        />
      </Modal>
      <Modal className='modal-content' isOpen={deleteProfileModalOpened} style={customStyles}>
      <ProfileDeleteModal modalInfo={profileModalInfo} closeModal={closeEditProfileModal} />
      </Modal>
      <div className='content-report'>
        <div className='header-report admin'>
          <div className='button-add-user' onClick={openCreateProfileModal}>Создать пользователя</div>
          <div className='header-report-item'>
            <p>Администрирование</p>
          </div>
          <div className='header-report-item' style={{ background: 'transparent' }}>
            <span className='profile-box'>
              <img
                src={getProfileInfo().avatar}
                onClick={changeVisibleProfile}
                ref={profileRef1}
              />
            </span>
          </div>
        </div>
        <div className='main-report' style={{ alignItems: 'normal' }}>
          <div className={profile === false ? 'profile-invisible' : 'profile-visible'} ref={profileRef}>
            <Profile closeProfile={changeVisibleProfile} />
          </div>
          <div className='main-content-administration'>
          {loading === true
            ? (
                <div className='circular-progress-bar-wrapper'>
                  <div className={'circular-progress-bar'}></div>
                </div>
              )
            : (
            <div className='content_administration'>
              <div className='filter-toolbar'>
                <div className='filters-admin'>
                  <div className='search-by-name'>
                    <img src={SearchIcon} />
                    <input
                      className='textarea-search-by-name'
                      placeholder='Поиск по имени'
                      value={NameFilter}
                      onChange={(e) => setNameFilter(e.target.value)}
                    ></input>
                  </div>
                  <div className='filter-job-title'>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <p>Проекты:</p>
                      <MultiSelectProjects
                        selectedProjects={onSelectProjects}
                        projectsName={AvailableProjects.map((project) => project.name)}
                      />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <p>Должность:</p>
                      <MultiSelectRole
                        selectedPositions={onSelectPositions}
                        availablePositions={AvailableFilterPositions}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <HeaderAdminBox />
              <div style={{ paddingTop: '20px' }}></div>
              <div className='staff-list-wrapper'>
                <div>
                      {filteredStaff.length === 0
                        ? (
                          <div className='no-items-found'>Сотрудники не найдены</div>
                          )
                        : (
                            filteredStaff.map((staff) => (
                            <AdministrationUserBox
                              key={staff.user_id}
                              id={staff.user_id}
                              first={staff.firstname}
                              last={staff.lastname}
                              role={staff.role}
                              email={staff.email}
                              slackId={staff.slack_id}
                              date={staff.date}
                              projects={staff.projects}
                              assignments={staff.assignments}
                              openEditProfileModal={openEditProfileModal}
                              openDeleteProfileModal={openDeleteProfileModal}
                            />
                            ))
                          )}
                </div>
              </div>
            </div>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdministrationPage
