import useApi from './useApi'

const useApiStaff = () => {
  const request = useApi().request

  const getManagersAndDevelopersList = async (startDate, endDate) => {
    return request({ method: 'GET', url: `/api/staff?role=1&role=4&startDate=${startDate}&endDate=${endDate}` })
  }

  const createStaff = async (body) => {
    return request({ method: 'POST', url: '/api/staff/create', data: body })
  }

  const editStaff = async (body) => {
    return request({ method: 'POST', url: '/api/staff/edit', data: body })
  }

  const deleteStaff = async (id) => {
    return request({ method: 'DELETE', url: `/api/staff/delete/${id}/` })
  }

  return { getManagersAndDevelopersList, createStaff, editStaff, deleteStaff }
}

export default useApiStaff
