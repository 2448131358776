// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.header-adm-info {
    height: 5.6vh;
    background-color: #313133;
    display: grid;
    grid-template-columns: 15.5% 15.5% 35.7% 23.8% 9.7%;
    align-items: center;
}

.item-header-adm-info {
    height: 80%;
    margin-left: 16px;
    display: flex;
    align-items: center;
    font-family: "RobotoMedium";
    font-size: 20px;
    color: #EDEDED;
}`, "",{"version":3,"sources":["webpack://./src/css/header_admin_box.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,yBAAyB;IACzB,aAAa;IACb,mDAAmD;IACnD,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,eAAe;IACf,cAAc;AAClB","sourcesContent":["\n.header-adm-info {\n    height: 5.6vh;\n    background-color: #313133;\n    display: grid;\n    grid-template-columns: 15.5% 15.5% 35.7% 23.8% 9.7%;\n    align-items: center;\n}\n\n.item-header-adm-info {\n    height: 80%;\n    margin-left: 16px;\n    display: flex;\n    align-items: center;\n    font-family: \"RobotoMedium\";\n    font-size: 20px;\n    color: #EDEDED;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
