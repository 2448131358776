import useApi from './useApi'

const useApiWebhooks = () => {
  const request = useApi().request

  const getSummaryReportWebhooksSettings = async () => {
    return request({ method: 'GET', url: '/api/webhooks/summary-report/' })
  }

  const updateSummaryReportWebhooksSettings = async (body) => {
    return request({ method: 'POST', url: '/api/webhooks/summary-report/', data: body })
  }

  return { getSummaryReportWebhooksSettings, updateSummaryReportWebhooksSettings }
}

export default useApiWebhooks
