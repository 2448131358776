import ToolbarAdmin from "../Toolbar";
import profileButtonImage from "../../icons/profile_img_icon.svg"
import LeftIcon from "../../icons/icon_back_arrow.svg"
import calendarImage from '../../icons/calendar_icon.svg';
import leftButtonImage from '../../icons/icon_left.svg';
import rightButtonImage from '../../icons/icon_right.svg';
import "../../css/staff.css";
import "../../css/main.css";
import "../../css/detailed-staff-info.css";
import DetailedReviewPage from '../WeeksReport/DetailedReviewPage.jsx';
import { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalStateContext } from '../GlobalStateContext.jsx';
import Profile from "../../jsx/Profile.jsx";
import Calendar from "../../jsx/WeeksCalendar/Calendar.jsx";
import DetailedStaffInfoRightBar from "./DetailedStaffInfoRightBar.jsx";
import { convertDateString } from '../../jsx/Helpers/DateExtensions.js';
import { sortProjectsByRole } from "../Helpers/ProjectsExtensions.js";

const DetailedStaffInfoPage = () => {

  const navigate = useNavigate();

    const location = useLocation(); // Хук для получения данных из state
    const staffName = location.state.name || '';
    const staffPosition = location.state.position || '';
    const staffId = location.state.id || '';

    const [profile, setVisibleProfile] = useState(false);
    const [calendar, setVisibleCalendar] = useState(false);

    const [NextWeekAvailable, setNextWeekAvailable] = useState(true);

    const [week, setWeek] = useState({name_week: '', select_week: new Date()});
    const childRef = useRef();
    const textRef = useRef(null);
    const profileRef = useRef(null);
    const profileRef1 = useRef(null);
    const calendarRef = useRef(null);
    const calendarRef1 = useRef(null);
    const { getProfileInfo, getCurrentBackendUrl } = useContext(GlobalStateContext);

    const [StartDate, setStartDate] = useState("");
    const [EndDate, setEndDate] = useState("");


    const [StaffInfo, setStaffInfo] = useState([]);
    const [NameFilter, setNameFilter] = useState('');
    const [PositionsFilter, setPositionsFilter] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [ReportList, setReportList] = useState([]);
    const [ProjectsList, setProjectsList] = useState([]);
    const [ReportModalInfo, setReportModalInfo] = useState({
        date: new Date(),
        name: 'abcd'
    });

    const changeVisibleProfile = () => {
        setVisibleProfile(!profile);
      }

      const changeVisibleCalendar = () => {
        setVisibleCalendar(!calendar);
      }

    const MinusWeek = () => {
        childRef.current.changeWeekThroughArrow('-');
      }

    const PlusWeek = () => {
        childRef.current.changeWeekThroughArrow('+');
      }

      const changeWeeklyReview = (week_name, selectWeek, selectedYear) => {
        setWeek({name_week: week_name, select_week: selectWeek});
        const [startDateStr, endDateStr] = week_name.split(' - ');
        const a = startDateStr + " " + selectedYear;
        const b = endDateStr + " " + selectedYear;
    
        const { startDate: newStartDate, endDate: newEndDate } = convertDateString(a + " - " + b);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        
        //console.log("current selected week = " + StartDate + " - " + EndDate, newStartDate, newEndDate, convertDateString(a + " - " + b));
      };

    const handleClickOutside = (event) => {
        if (profileRef.current && !profileRef.current.contains(event.target) && !profileRef1.current.contains(event.target)) {
          setVisibleProfile(false);
        }
    
        if (calendarRef.current && !calendarRef.current.contains(event.target) && !calendarRef1.current.contains(event.target)) {
          setVisibleCalendar(false);
        }
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

      useEffect(() => {
        getAllProjects();
    }, []);

      const getAllProjects = async () => {
        await axios.get(`${getCurrentBackendUrl()}/api/projects`)
        .then((response) => {
            //console.log("getProjects: ", response.data);
            setProjectsList(sortProjectsByRole(Number(getProfileInfo().id), Number(getProfileInfo().role), response.data));
        })
        .catch((error) => {
            //console.log('Error:', error);
        })
      }

    useEffect(() => {
        const adjustFontSize = () => {
          const element = textRef.current;
          const initialFontSize = 18;
          let currentFontSize = initialFontSize;
    
          element.style.fontSize = `${initialFontSize}px`;
          element.style.overflow = 'hidden';
          while (element.clientHeight > 28) {
            currentFontSize -= 1;
            element.style.fontSize = `${currentFontSize}px`;
          }
    
          element.style.whiteSpace = 'normal';
        }
    
        adjustFontSize();
        window.addEventListener('resize', adjustFontSize);
    
        return () => {
          window.removeEventListener('resize', adjustFontSize);
        };
      }, [week]);

      const goBackToStaffPage = () => {
        navigate('/staff');
      }

      useEffect(() => {
        if ((StartDate || EndDate) && week.select_week) {
                getReportsList();
        }
        //console.log("Start date: ", StartDate, "End date: ", EndDate, "dates: ", week.select_week);
    }, [week.select_week]);

      const getReportsList = async () => {
        await axios.get(`${getCurrentBackendUrl()}/api/reports?creator_id=${staffId}&end_date=${EndDate}&start_date=${StartDate}`, {
        })
            .then((response) => {
                //console.log('Success parse data for weekly report:', response.data);
                setReportList(response.data);
                //setReportList(transformReportsData(response.data).sort((a, b) => b.date - a.date));
            })
            .catch((error) => {
                //console.log('Error:', error);
            }) 
    }

    const updateNextWeekAvailableDates = (available) => {
      setNextWeekAvailable(available);
    }

    return (
        <div className='main-wrapper'>
            <ToolbarAdmin page={"staff"} />
            <div className="content-report">
            
            <div className="header-report">
          <div className="back-to-staff" onClick={goBackToStaffPage}>
            <img src={LeftIcon} style={{ marginRight: "8px" }} />
            <p style={{ fontSize: "16px" }}> Вернуться к списку </p>
          </div>
          <div className="header-report-item">
            <p> { staffName + "/" + staffPosition } </p>
          </div>
          <div className="header-report-item" style={{ padding: "0px 10px", justifyContent: "space-between" }}>
            <img src={leftButtonImage} onClick={MinusWeek} />
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "4px", cursor: "default" }} onClick={changeVisibleCalendar} ref={calendarRef1}>
              <img src={calendarImage} />
              <p style={{ margin: "0" }} ref={textRef}>{week.name_week}</p>
            </div>
            { NextWeekAvailable == true && <img src={rightButtonImage} onClick={PlusWeek} />}
          </div>
          <div className="header-report-item" style={{ background: "transparent" }}>
            <span className="profile-box">
              <img src={ getProfileInfo().avatar ? (getProfileInfo().avatar) : profileButtonImage } style={{ width: "2vw", height: "2vw" }} onClick={changeVisibleProfile} ref={profileRef1}/>
            </span>
          </div>
        </div>
            
                <div className="main-report" style={{ alignItems: "normal" }}>

                <div className={profile == false ? "profile-invisible" : "profile-visible"} ref={profileRef}>
            <Profile closeProfile={changeVisibleProfile}/>
          </div>
          <div className={calendar == false ? "calendar-invisible" : "calendar-visible"} ref={calendarRef}>
            <Calendar changeWeekly={changeWeeklyReview} ref={childRef} nextWeekAvailable={updateNextWeekAvailableDates} />
          </div>

          <div className="main-content">

          <div className="main-detailed-staff-info">


<div className="top-bar">
    <p className="title">Отчет</p>
</div>
<div style={{ height: '1px',  width: 'auto', backgroundColor: '#555555', marginTop: '12px', marginBottom: '20px', marginLeft: '32px', marginRight: '32px'}}></div>

<div style={{padding: '0px 32px', marginBottom: '32px'}}>
<DetailedReviewPage dates={week.select_week} startDate={StartDate} endDate={EndDate} missedReportsDates={() => {}} isModalReport={true} isExternalReport={true} externalReport={ReportList} projectsList={ProjectsList}/>
</div>

        </div>

        <div className="right-bar-section">
        <DetailedStaffInfoRightBar reportList={ReportList} endDate={EndDate} projectsList={ProjectsList} />
        </div>
          </div>
                </div>
            </div>
        </div>
    )
}

export default DetailedStaffInfoPage;