// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-info {
    margin-top: -20px;
    margin-right: -16px;
}

.top-modal-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.title {
    color: #EDEDED;
    font-size: 20px;
    font-family: "RobotoMedium";
    margin: 0px;
    margin-top: 12px;
}

.staff-name {
    color: #EDEDED;
    font-size: 16px;
    font-family: "RobotoMedium";
    margin: 0px;
    margin-top: 24px;
}`, "",{"version":3,"sources":["webpack://./src/css/modal_report_info.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,cAAc;IACd,eAAe;IACf,2BAA2B;IAC3B,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,2BAA2B;IAC3B,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".close-info {\n    margin-top: -20px;\n    margin-right: -16px;\n}\n\n.top-modal-bar {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    gap: 10px;\n}\n\n.title {\n    color: #EDEDED;\n    font-size: 20px;\n    font-family: \"RobotoMedium\";\n    margin: 0px;\n    margin-top: 12px;\n}\n\n.staff-name {\n    color: #EDEDED;\n    font-size: 16px;\n    font-family: \"RobotoMedium\";\n    margin: 0px;\n    margin-top: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
