import { useState, useEffect, useContext } from 'react';
import '../css/send_message_window.css';
import calendarImage from '../icons/calendar_icon.svg';
import { formatDateComma } from './Helpers/DateExtensions';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { GlobalStateContext } from './GlobalStateContext';


const SendMessageWindow = ({ closeModal, currentDate }) => {
    const [SelectRadioButton, setRadioButton] = useState('radio1');
    const { getCurrentBackendUrl, getProfileInfo } = useContext(GlobalStateContext);

    const [SlackMessage, setSlackMessage] = useState({
        userId: getProfileInfo().id,
        date: currentDate,
        workFromHome: true,
        comment: '' 
    });

    const setRadio1 = () => {
        setRadioButton('radio1');
    };

    const setRadio2 = () => {
        setRadioButton('radio2');
    };

    const close = () => {
        closeModal();
    }

    const sendMessage = async () => {
        if (SlackMessage.comment.length > 0) {
            await axios.post(`${getCurrentBackendUrl()}/api/message/work-status`, SlackMessage)
            .then((response) => {
              //console.log('Success:', response.data);
              closeModal();
          })
            .catch((error) => {
              //console.log('Error:', error);
          })
        }
    }

    const updateMessageComment = (e) => {
        setSlackMessage(prevState => ({
            ...prevState,
            comment: e.target.value
          }));
    }

    useEffect(() => {
        setSlackMessage(prevState => ({
            ...prevState,
            workFromHome: SelectRadioButton === 'radio1' ? true : false
          }));

          //console.log("Current message for post in Slack: ", SlackMessage);

    }, [SelectRadioButton, SlackMessage.comment]);

    const onChangeCheckedBox = (e) => {
    }

    return (
        <div className="send-message-main">
            <p>Сообщение</p>
            <div className="checkboxes-date">
                <div className="checkboxes">
                    <div className="checkboxes-container" onClick={setRadio1}>
                        <input
                            type="radio"
                            checked={SelectRadioButton === 'radio1' ? true : false}
                            onChange={onChangeCheckedBox}
                        />
                        <label>Работаю из дома</label>
                    </div>
                    <div className="checkboxes-container" onClick={setRadio2}>
                        <input
                            type="radio"
                            checked={SelectRadioButton === 'radio2' ? true : false}
                            onChange={onChangeCheckedBox}
                        />
                        <label>Не работаю</label>
                    </div>
                </div>
                <div className="date">
                    <div className="date-container">
                        <img src={calendarImage}/>
                        <p> { formatDateComma(currentDate) } </p>
                    </div>
                </div>
            </div>
            <div className="send-message-container-input">
                <textarea
                    type="text"
                    placeholder="Ваш комментарий"
                    value={SlackMessage.comment}
                    onChange={updateMessageComment}
                >
                </textarea>
            </div>
            <div className="send-message-container-buttons">
                <button style={{backgroundColor: "transparent", border: "1px solid rgba(0, 126, 197, 1)"}} onClick={close}>Отменить</button>
                <button style={{backgroundColor: SlackMessage.comment.length === 0 ? "#7F7F7F" : "rgba(0, 126, 197, 1)", border: "none"}} onClick={sendMessage}>Отправить сообщение</button>
            </div>
        </div>
    );

};

export default SendMessageWindow;