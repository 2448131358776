import { useState } from 'react'
import ConfirmModal from './DayReport/ConfirmModal'

const useConfirmModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [resolvePromise, setResolvePromise] = useState(null)
  const [modalDate, setModalDate] = useState('')

  const confirm = (date) => {
    setModalDate(date)
    setIsOpen(true)
    return new Promise((resolve) => {
      setResolvePromise(() => resolve)
    })
  }

  const handleConfirm = () => {
    setIsOpen(false)
    if (resolvePromise) resolvePromise(true)
  }

  const handleCancel = () => {
    setIsOpen(false)
    if (resolvePromise) resolvePromise(false)
  }

  const modal = isOpen
    ? (
    <ConfirmModal date={modalDate} onConfirm={handleConfirm} onCancel={handleCancel} isOpen={isOpen} />
      )
    : null

  return { confirm, modal }
}

export default useConfirmModal
