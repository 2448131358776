export function getHourString(number) {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;
  
    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return `${number} часов`;
    }
  
    if (lastDigit === 1) {
      return `${number} час`;
    }
  
    if (lastDigit >= 2 && lastDigit <= 4) {
      return `${number} часа`;
    }
  
    return `${number} часов`;
  }