import DeleteSelectItem from '../icons/icon_delete_select_item.svg'
import ArrowSelectIcon from '../icons/icon_select_arrow.svg'
import DefaultProjectIcon from '../icons/default_project_icon.svg'
import React, { useState, useEffect } from 'react'
import '../css/multiselect.css'

const MultiSelectProjects = ({ projectsName, selectedProjects }) => {
  const [SelectedItems, setSelectedItems] = useState([])
  const [VisibleArray, setVisibleArray] = useState(false)
  const ArrayItems = projectsName || ['Project_1', 'Project_2', 'Project_3', 'Project_4', 'Project_5']

  const changeVisibleArray = () => {
    setVisibleArray(!VisibleArray)
  }

  const AddItemInArray = (item) => {
    if (!SelectedItems.includes(item)) {
      setSelectedItems((prevItems) => [...prevItems, item])
      setVisibleArray(false)
    }
  }

  const DeleteItemInArray = (item) => {
    setSelectedItems((items) => { return items.filter((itemArray) => itemArray !== item) })
  }

  const checkIsSelected = (item) => {
    return SelectedItems.includes(item)
  }

  useEffect(() => {
    selectedProjects(SelectedItems)
  }, [SelectedItems])

  return (
        <div className="main-multiselect-project">
            <div className="picker-multiselect-project" onClick={changeVisibleArray}>
                {SelectedItems.length === 0 ? <p>Выберите проект</p> : ''}
                <div className="selected-items-project">
                {SelectedItems.map((item, index) => (
                    <div className="item-multiselect-project" key={index}>
                        <img className='item-multiselect-project-icon' src={DefaultProjectIcon}/>
                        <p>{item}</p>
                        <img src={DeleteSelectItem} className='item-multiselect-project-delete' onClick={(e) => {
                          e.stopPropagation()
                          DeleteItemInArray(item)
                        }}/>
                    </div>
                ))}
                </div>
                <img className='expand-arrow' src={ArrowSelectIcon} />
            </div>
            {VisibleArray &&
            <div className="array-multiselect-items">
                {ArrayItems.map((item, index) => (
                    <div className="item-array-multiselect" key={index} onClick={() => AddItemInArray(item)}>
                        <img className='item-multiselect-project-icon' src={DefaultProjectIcon}/>
                        <p className='staff-item-name'>{item}</p>
                        <input type='checkbox' className={'item-checkbox' + (checkIsSelected(item) ? ' checked' : '')} id={index} name={index} checked={checkIsSelected(item)} />
                        <label htmlFor={index}></label>
                    </div>
                ))}
            </div>}
        </div>
  )
}

export default MultiSelectProjects
