// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.available-staff-list {
    position: absolute;
    width: 20vw;
    margin-top: 3%;
    max-height: 140px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: #313133;
    padding: 5px;
    border-radius: 4px;
    z-index: 100;
}

.available-staff-list::-webkit-scrollbar {
    width: 4px;
    background-color: #313133;
}

.available-staff-list::-webkit-scrollbar-thumb {
    background-color: #555555;
    border-radius: 4px;
}

.available-staff-list::-webkit-scrollbar-track {
    background-color: #313133;
}

.staff-item-name {
    text-align: start;
    width: 100%;
    padding-left: 4%;
}`, "",{"version":3,"sources":["webpack://./src/css/assign_staff.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".available-staff-list {\n    position: absolute;\n    width: 20vw;\n    margin-top: 3%;\n    max-height: 140px;\n    overflow-y: scroll;\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n    background-color: #313133;\n    padding: 5px;\n    border-radius: 4px;\n    z-index: 100;\n}\n\n.available-staff-list::-webkit-scrollbar {\n    width: 4px;\n    background-color: #313133;\n}\n\n.available-staff-list::-webkit-scrollbar-thumb {\n    background-color: #555555;\n    border-radius: 4px;\n}\n\n.available-staff-list::-webkit-scrollbar-track {\n    background-color: #313133;\n}\n\n.staff-item-name {\n    text-align: start;\n    width: 100%;\n    padding-left: 4%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
