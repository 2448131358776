class TokenService {
    constructor(token) {
      this.token = null;
    }

    getToken() {
        return localStorage.getItem('token');
      }
    
      setToken(token) {
        localStorage.setItem('token', token);
      }
    
      removeToken() {
        localStorage.removeItem('token');
      }
    
      getRefreshToken() {
        return localStorage.getItem('refresh_token');
      }
    
      setRefreshToken(refreshToken) {
        localStorage.setItem('refresh_token', refreshToken);
      }
    
      removeRefreshToken() {
        localStorage.removeItem('refresh_token');
      }
    
      getUser() {
        return JSON.parse(localStorage.getItem('user'));
      }
    
      setUser(user) {
        localStorage.setItem('user', JSON.stringify(user));
      }
    
      removeUser() {
        localStorage.removeItem('user');
      }
}
export default new TokenService();