import { useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { GlobalStateContext } from '../jsx/GlobalStateContext'

const useApi = () => {
  const { getCurrentBackendUrl, logout } = useContext(GlobalStateContext)
  const navigate = useNavigate()
  const isLoggingOut = useRef(false) // Флаг предотвращает повторный логаут

  const api = axios.create({
    baseURL: getCurrentBackendUrl()
  })

  const request = async (config, checkToken = true) => {
    try {
      const token = localStorage.getItem('token')
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`
        }
      }

      const response = await api.request(config)
      return { isSuccess: true, data: response.data, status: response.status } // Явно возвращаем успешный ответ
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401 && checkToken === true) {
          handleUnauthorized()
        }
        return { isSuccess: false, error: error.response, status: error.response.status } // Возвращаем ошибку
      }
      return { isSuccess: false, error: 'Network error', status: 500 }
    }
  }

  const handleUnauthorized = () => {
    if (!isLoggingOut.current) {
      isLoggingOut.current = true
      localStorage.removeItem('token') // Очищаем невалидный токен
      logout()
      navigate('/login')
    }
  }

  return { request }
}

export default useApi
