import useApi from './useApi'

const useApiProfile = () => {
  const request = useApi().request

  const login = async (body) => {
    return request({ method: 'POST', url: '/api/login', data: body }, false)
  }

  const fetchProfileInfo = async (id) => {
    return request({ method: 'GET', url: `/api/profile/${id}` })
  }

  return { login, fetchProfileInfo }
}

export default useApiProfile
