import '../css/main.css';
import editPencilIcon from '../icons/edit_pencil.svg';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getYear, getDate, getMonth} from 'date-fns';
import ProjectBox from './ProjectBox';
import { GlobalStateContext } from './GlobalStateContext';
import { getHourString } from './Helpers/TimeExtensions';

const MainWeekBox = ({ id, date, projects, work_place, time, projectsList }) => {
    const { changeDay } = useContext(GlobalStateContext)
    const [tm, setTm] = useState('')
    const navigate = useNavigate();

    useEffect(() => {
        setTm(getHourString(time));
    }, []);

    const GoToEditReport = () => {
        changeDay(date);
        navigate('/history');
    }

    const retrieveProjectName = (item) => {
            const projectItem = projectsList ? projectsList.find(project => project.id === item.project_id) : "null";
            return projectItem ? projectItem.name : "Проект не выбран";
    }

    return (
        <div className="column-box">
            <div className="row-box" style={{ borderRight: "1px solid rgba(85, 85, 85, 1)" }}>{`${getDate(date).toString().padStart(2, '0')}.${(getMonth(date) + 1).toString().padStart(2, '0')}.${getYear(date)}`}</div>
            <div className="row-box" style={{ borderRight: "1px solid rgba(85, 85, 85, 1)", gap: "10px" }}>
                {projects.map((item, index) => (
                    <ProjectBox key={index} img={item.img_project} text={retrieveProjectName(item)} />
                ))}
            </div>
            <div className="row-box" style={{ borderRight: "1px solid rgba(85, 85, 85, 1)" }}>{tm}</div>
            <div className="row-box" style={{ borderRight: "1px solid rgba(85, 85, 85, 1)", paddingRight: "12px" }}>
                <p>{work_place}</p>
            </div>
            <div className="row-box" style={{ justifyContent: "center" }}><img src={editPencilIcon} onClick={GoToEditReport}/></div>
        </div>
    );
};

export default MainWeekBox;