import React, { useState, useContext } from 'react';
import Modal from 'react-modal';
import '../css/profile.css';
import { GlobalStateContext } from './GlobalStateContext';
import { useNavigate } from 'react-router-dom';
import DefaultIcon from '../icons/default_icon_user.svg'
import EditIcon from '../icons/edit_profile_icon.svg'
import LogoutIcon from '../icons/logout.svg'
import DarkModeIcon from '../icons/icon_dark_mode.svg'
import SettingsIcon from '../icons/icon_settings.svg'
import CloseIcon from '../icons/icon_close.svg'
import SettingsArrowIcon from '../icons/icon_arrow_profile.svg'
import Settings from '../jsx/Settings/Settings.jsx'

const Profile = ({ closeProfile }) => {
    const [getNotifications, setNotifications] = useState(false);
    const { enableDarkMode, changeTheme, logout, getProfileInfo } = useContext(GlobalStateContext);
    const [selectedOption, setSelectedOption] = useState('option1');
    const [SettingsIsOpen, setSettingsIsOpen] = useState(false);

    const navigate = useNavigate();

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const changeGetNotifications = () => {
        setNotifications(!getNotifications);
    }

    const close_profile = () => {
        closeProfile();
    };

    const ChangeDarkMode = () => {
        changeTheme();
    }

    const Logout = () => {
        logout();
        navigate('/login');
    };

    const openSettings = () => {
        setSettingsIsOpen(true);
    }

    const closeSettings = () => {
        setSettingsIsOpen(false);
    }

    const customStyles = {
        content: {
          display: 'inline-block',
          width: '40%',
          height: 'auto',
          padding: '32px 40px 39px 40px',
          border: 'none',
          outline: 'none',
          borderRadius: '12px',
          backgroundColor: '#313133',
          overflow: 'hidden',
        },
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(18, 22, 25, 0.75)',
          margin: 'auto',
          zIndex: 1000,
        }
      };



    return (
        <div className="profile-main">
            <Modal className="modal-window" isOpen={SettingsIsOpen} onRequestClose={closeSettings} style={customStyles}>
                <Settings />
            </Modal>
            <img className="close-profile" src={CloseIcon} onClick={close_profile} />
            <div className="profile-content">
                <div className="photo-user">
                    <span className="photo-box">
                    <img src = { getProfileInfo().avatar ? (getProfileInfo().avatar) : DefaultIcon } alt="Avatar" width="100" />
                        <img src={EditIcon} className="edit_icon" />
                    </span>
                    <p>{(getProfileInfo().firstname ? getProfileInfo().firstname : "Username") + " " + (getProfileInfo().lastname ? getProfileInfo().lastname : "")}</p>
                </div>
                <div className="block-items-profile" style={{ marginBottom: "6%" }} onClick={openSettings}>
                    <span className="set-dark-mode">
                        <div className="get-notifications-img">
                            <img src={SettingsIcon} />
                            <p>Настройки</p>
                        </div>
                        <img src={SettingsArrowIcon} />
                    </span>
                </div>
                <div className="line-profile" style={{ width: "100%", border: "1px solid #555555" }}></div>
                <div className="block-items-profile">
                    <span className="set-dark-mode">
                        <div className="get-notifications-img">
                            <img src={DarkModeIcon} />
                            <p>Тёмный режим</p>
                        </div>
                        <div className={enableDarkMode == 1 ? "get-notifications-button" : "get-notifications-button enabled"} onClick={ChangeDarkMode}>
                            <div className={enableDarkMode == 1 ? "get-notifications-button-slider" : "get-notifications-button-slider enabled"}></div>
                        </div>
                    </span>
                </div>
                <div className="block-items-profile" style={{ marginTop: "15.7%" }}>
                    <span className="logout-icon" style={{ marginLeft: "3px" }} onClick={Logout}>
                        <img src={LogoutIcon} />
                        <p style={{ margin: "0px" }}>Выйти из профиля</p>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Profile;