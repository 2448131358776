import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import seePassword from '../icons/eye.svg'
import Image from '../icons/login_image.svg';
import axios from 'axios';
import "../css/login.css";
import "../css/main.css";
import { GlobalStateContext } from './GlobalStateContext';

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { enableDarkMode } = useContext(GlobalStateContext);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const { setToken, setRefreshToken, setRoleAndId, updateProfileInfo, getCurrentBackendUrl } = useContext(GlobalStateContext);
    const [ProgressBarVisible, setProgressBarVisible] = useState(false);

    useEffect(() => {
        //console.log(enableDarkMode);
    }, []);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const navigate = useNavigate();


    const handleSubmit = async (e) => {
        e.preventDefault();
        setProgressBarVisible(true);
        try {
            await axios.post(`${getCurrentBackendUrl()}/api/login`, { username: username, password: password })
                .then((response) => {
                    setToken(response.data.access);
                    setRefreshToken(response.data.refresh);
                    setRoleAndId(response.data.role, response.data.id);
                    fetchProfileInfo(response.data.id);
                    setProgressBarVisible(false);
                })
                .catch((error) => {
                    if (error.response.status === 401) { //Пользователь не найден
                        alert('Введено неверное имя пользователя или пароль');
                        handleLoginError();
                    }
                })
        } catch (error) {
            alert('Возникла ошибка при отправке данных');
            handleLoginError();
        }
    };

    const handleLoginError = () => {
        setProgressBarVisible(false);
        setUsername("");
        setPassword("");
    }

    const fetchProfileInfo = async (id) => {
        const token = localStorage.getItem('token');
        await axios.get(`${getCurrentBackendUrl()}/api/profile/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
                //console.log("fetch profile response: ", response.data);
                updateProfileInfo(response.data);
                navigate('/');
            })
            .catch((error) => {
                //console.log('Error:', error);
                navigate('/');
            })
    }

    const handleForgotPassword = (event) => {
        navigate('/forgot-password');
    };
    return (
        <div className="container-login-page">
            <div className={`login-part ${enableDarkMode > 1 ? 'dark' : ''}`}>
                < div className={`login-container ${enableDarkMode > 1 ? "dark" : ""}`} >
                    <h2>Авторизация</h2>
                    <form onSubmit={handleSubmit}>
                        <div className={`form-group ${enableDarkMode > 1 ? "dark" : ""}`} style={{ marginBottom: "16px" }}>
                            <label htmlFor="username">Username</label>
                            <input
                                className={`input-login ${enableDarkMode > 1 ? "dark" : ""}`}
                                type="text"
                                id="username"
                                placeholder='Placeholder'
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                        <div className={`form-group ${enableDarkMode > 1 ? "dark" : ""}`}>
                            <label htmlFor="password">Password</label>
                            <input
                                className={`input-login ${enableDarkMode > 1 ? "dark" : ""}`}
                                style={{ paddingRight: 45 }}
                                type={passwordVisible ? "text" : "password"}
                                id="password"
                                placeholder='Placeholder'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required

                            />
                            <img className="see-password-icon" src={seePassword} onClick={togglePasswordVisibility} />
                            <p>Это должна быть комбинация минимум из 8 букв, цифр и символов</p>
                        </div>
                        <div className="checkbox-password">
                            <span className={`remember-user ${enableDarkMode > 1 ? "dark" : ""}`}>
                                <input className={`input-remember ${enableDarkMode > 1 ? "dark" : ""}`}
                                    type="checkbox"
                                />
                                <label style={{ marginBottom: 0 }}>Remember me</label>
                            </span>
                            <span className={`forgot-password ${enableDarkMode > 1 ? "dark" : ""}`} onClick={handleForgotPassword}>
                                Forgot Password?
                            </span>

                        </div>
                        <button className={`button-login ${enableDarkMode > 1 ? "dark" : ""}`} type="submit">Вход</button>
                    </form>
                    {ProgressBarVisible && <div style={{ marginBottom: "-120px", marginTop: "64px" }} className={`circular-progress-bar ${enableDarkMode > 1 ? "dark" : ""}`}></div>}
                </div >
            </div>
            <img className="image-login" src={Image} />
        </div >
    );
}

export default Login;