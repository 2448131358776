import ToolbarAdmin from '../Toolbar.jsx'
import '../../css/administration.css'
import '../../css/main.css'
import SearchIcon from '../../icons/icon_search.svg'
import { GlobalStateContext } from '../GlobalStateContext.jsx'
import { useContext, useEffect, useRef, useState } from 'react'
import Profile from '../Profile.jsx'
import Modal from 'react-modal'
import { StaffPositionEnum } from '../Helpers/StaffExtensions.js'
import HeaderProjectsBox from './HeaderProjectsBox.jsx'
import ProjectItemBox from './ProjectItemBox.jsx'
import AssignProjects from './AssignProjects.jsx'
import ProjectEditModal from './ProjectEditModal.jsx'
import ProjectDeleteModal from './ProjectDeleteModal.jsx'
import useApiProjects from '../../api/projects.js'
import useApiAdministration from '../../api/administration.js'

const ProjectsPage = () => {
  const apiProjects = useApiProjects()
  const apiAdministration = useApiAdministration()
  const profileRef = useRef(null)
  const profileRef1 = useRef(null)
  const [profile, setVisibleProfile] = useState(false)
  const { getProfileInfo } = useContext(GlobalStateContext)
  const [staffList, setStaffList] = useState([])
  const [NameFilter, setNameFilter] = useState('')
  const [PositionsFilter, setPositionsFilter] = useState([])
  const [ProjectsFilter, setProjectsFilter] = useState([])
  const [AvailableProjects, setAvailableProjects] = useState([])
  const [AvailableFilterPositions, setAvailableFilterPositions] = useState([])
  const [loading, setLoading] = useState(true)

  const [managersFilter, setManagersFilter] = useState([])
  const [developersFilter, setDevelopersFilter] = useState([])

  const [profileModalOpened, setProfileModalOpened] = useState(false)
  const [deleteProfileModalOpened, setDeleteProfileModalOpened] = useState(false)
  const [profileModalInfo, setProfileModalInfo] = useState({})
  const [profileModalType, setProfileModalType] = useState('')

  const onSelectPositions = (selectedPositions) => {
    setPositionsFilter(selectedPositions)
  }

  const onSelectProjects = (selectedProjects) => {
    setProjectsFilter(selectedProjects)
  }

  const changeVisibleProfile = () => {
    setVisibleProfile(!profile)
  }

  const handleClickOutside = (event) => {
    if (
      profileRef.current &&
        !profileRef.current.contains(event.target) &&
        !profileRef1.current.contains(event.target)
    ) {
      setVisibleProfile(false)
    }
  }

  const getStaffList = async () => {
    setAvailableFilterPositions([StaffPositionEnum.DEVELOPER, StaffPositionEnum.MANAGER])
    const response = await apiAdministration.loadAdministrationPage()
    if (response.isSuccess === true) {
      setStaffList(response.data)
      setLoading(false)
    }
  }

  const fetchProjects = async () => {
    const response = await apiProjects.fetchProjects()

    if (response.isSuccess === true) {
      setAvailableProjects(response.data)
    }
  }

  useEffect(() => {
    getStaffList()
    fetchProjects()
  }, [getProfileInfo])

  // eslint-disable-next-line camelcase
  const openEditProjectModal = (id, name, description, assigned_managers, assigned_developers) => {
    setProfileModalType('edit')
    setProfileModalOpened(true)
    // eslint-disable-next-line camelcase
    setProfileModalInfo({ id, name, description, assigned_managers, assigned_developers })
  }

  const openDeleteProjectModal = (id, name) => {
    setDeleteProfileModalOpened(true)
    setProfileModalInfo({ id, name })
  }

  const openCreateProjectModal = () => {
    setProfileModalType('add')
    setProfileModalOpened(true)
  }

  const closeEditProfileModal = (withReload) => {
    setProfileModalType('')
    setDeleteProfileModalOpened(false)
    setProfileModalOpened(false)
    setProfileModalInfo({})
    if (withReload) {
      setLoading(true)
      getStaffList()
      fetchProjects()
    }
  }

  const onSelectManagers = (selectedManagers) => {
    setManagersFilter(selectedManagers)
  }

  const onSelectDevelopers = (selectedDevelopers) => {
    // console.log(selectedDevelopers)
    setDevelopersFilter(selectedDevelopers)
  }

  const customStyles = {
    content: {
      position: 'relative',
      display: 'inline-block',
      width: '300px',
      maxWidth: '50vw',
      maxHeight: '80vh',
      padding: '32px 40px 39px 40px',
      border: 'none',
      outline: 'none',
      borderRadius: '12px',
      backgroundColor: '#313133',
      overflowY: 'scroll'
    },
    overlay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(18, 22, 25, 0.75)',
      margin: 'auto',
      zIndex: 1000
    }
  }

  const filteredProjects = AvailableProjects?.filter((project) =>
    project.name.toLowerCase().includes(NameFilter.toLowerCase()) &&
    (developersFilter.length !== 0
      ? developersFilter.some((employee) =>
        project.assigned_developers.includes(employee.user_id)
      )
      : true) &&
    (managersFilter.length !== 0
      ? managersFilter.some((employee) =>
        project.assigned_managers.includes(employee.user_id)
      )
      : true)
  ).sort((a, b) => a.name.localeCompare(b.name))

  return (
      <div className='main-wrapper' onClick={handleClickOutside}>
        <ToolbarAdmin page={'projects'} />
        <Modal className='modal-content' isOpen={profileModalOpened} style={customStyles}>
          <ProjectEditModal
            type={profileModalType}
            modalInfo={profileModalInfo}
            projectsList={AvailableProjects}
            staffList={staffList}
            closeModal={closeEditProfileModal}
          />
        </Modal>
        <Modal className='modal-content' isOpen={deleteProfileModalOpened} style={customStyles}>
        <ProjectDeleteModal modalInfo={profileModalInfo} closeModal={closeEditProfileModal} />
        </Modal>
        <div className='content-report'>
          <div className='header-report admin'>
            <div className='button-add-user' onClick={openCreateProjectModal}>Создать новый проект</div>
            <div className='header-report-item'>
              <p>Проекты</p>
            </div>
            <div className='header-report-item' style={{ background: 'transparent' }}>
              <span className='profile-box'>
                <img
                  src={getProfileInfo().avatar}
                  onClick={changeVisibleProfile}
                  ref={profileRef1}
                />
              </span>
            </div>
          </div>
          <div className='main-report' style={{ alignItems: 'normal' }}>
            <div className={profile === false ? 'profile-invisible' : 'profile-visible'} ref={profileRef}>
              <Profile closeProfile={changeVisibleProfile} />
            </div>
            <div className='main-content-administration'>
            {loading === true
              ? (
                  <div className='circular-progress-bar-wrapper'>
                    <div className={'circular-progress-bar'}></div>
                  </div>
                )
              : (
              <div className='content_administration'>
                <div className='filter-toolbar'>
                  <div className='filters-admin'>
                    <div className='search-by-name'>
                      <img src={SearchIcon} />
                      <input
                        className='textarea-search-by-name'
                        placeholder='Поиск по проекту'
                        value={NameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                      ></input>
                    </div>
                    <div className='filter-job-title'>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <p>Менеджер:</p>
                        <AssignProjects
                          isInModal={false}
                          onlyManagerStaff={true}
                          staff={staffList}
                          selectedProjects={onSelectManagers}
                          projectsName={AvailableProjects.map((project) => project.name)}
                        />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <p>Разработчик:</p>
                        <AssignProjects
                          isInModal={false}
                          onlyDevStaff={true}
                          staff={staffList}
                          selectedProjects={onSelectDevelopers}
                          projectsName={AvailableProjects.map((project) => project.name)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <HeaderProjectsBox />
                <div style={{ paddingTop: '20px' }}></div>
                <div className='staff-list-wrapper'>
                  <div>
                      {filteredProjects.length === 0
                        ? (
                          <div className='no-items-found'>Проекты не найдены</div>
                          )
                        : (
                            filteredProjects.map((project) => (
                            <ProjectItemBox
                              key={project.id}
                              project={project}
                              staffList={staffList}
                              openEditProjectModal={openEditProjectModal}
                              openDeleteProjectModal={openDeleteProjectModal}
                            />
                            ))
                          )}
                  </div>
                </div>
              </div>
                )}
            </div>
          </div>
        </div>
      </div>
  )
}

export default ProjectsPage
