import SelectProject from "../SelectProject.jsx";
import React, { useState, useRef, useEffect } from 'react';
import "../../css/block_report.css";

const BlockReport = ({ index, count, info, onRemove, projectsList }) => {
    const [reportText, setReportText] = useState("");
    const [reportProject, setReportProject] = useState("");
    const [hoursWork, setHoursWork] = useState(0);
    const [minutesWork, setMinutesWork] = useState(0);

    const setupHoursWork = (e) => {
        setHoursWork(e.target.value)
        info.hours_work = e.target.value
    }

    const setupMinutesWork = (e) => {
        setMinutesWork(e.target.value)
        info.minutes_work = e.target.value
    }

    const setupReportText = (e) => {
        setReportText(e.target.value)
        info.text_report = e.target.value
    }

    // Функция для получения выбранного проекта
    const handleProjectChange = (project, id) => {
       info.project_id = id;
       if (project === "Выбрать проект") {
        onRemove(index);
       }
    };

    return (
        <div className="block-info">
            <div className="pick-project">
                <SelectProject project_id={info.project_id} onProjectChange={handleProjectChange} projectsList={projectsList}/>
                <div className="pick-time">
                    <span className="input-time">
                        <input maxLength={2} placeholder="Часы." inputMode="numeric" type="text" pattern="[0-9]*"
                        value={info.hours_work ? info.hours_work : ""}
                        onChange={setupHoursWork}
                        ></input>
                    </span>
                    <p>:</p>
                    <span className="input-time">
                        <input maxLength={2} placeholder="Мин." inputMode="numeric" type="text" pattern="[0-9]*"
                        value={info.minutes_work ? info.minutes_work : ""}
                        onChange={setupMinutesWork}
                        ></input>
                    </span>
                </div>
            </div>
            <div className="input-info">
                <textarea className={count > 1 ? "more-reports" : "one-report"} 
                value={info.text_report ? info.text_report : reportText.value} 
                onChange={setupReportText}>

                </textarea>
            </div>
        </div>
    )
}

export default BlockReport;