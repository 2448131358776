import '../css/main.css';

const ProjectBox = ( {img, text} ) => {

    return (
        <div className="project-box-container">
            <img src={img}/>
            <p>{text}</p>
        </div>
    );
};

export default ProjectBox;