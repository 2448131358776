import ToolbarAdmin from "../Toolbar.jsx";
import Profile from "../Profile";
import profileButtonImage from "../../icons/profile_img_icon.svg"
import CloseIcon from '../../icons/icon_close.svg';
import "../../css/staff.css";
import "../../css/main.css";
import '../../css/modal_report_info.css';
import HeaderStaffBox from './HeaderStaffBox.jsx';
import StaffBox from './StaffBox.jsx';
import SearchIcon from '../../icons/icon_search.svg';
import MultiSelectText from "../../jsx/MultiSelectText.jsx";
import MultiSelectProjects from "../MultiSelectProjects.jsx";
import Modal from 'react-modal';
import DetailedReviewPage from '../WeeksReport/DetailedReviewPage.jsx';
import { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { GlobalStateContext } from "../GlobalStateContext.jsx";
import { StaffPositionEnum } from "../Helpers/StaffExtensions.js";
import { format } from "date-fns";

const StaffPage = () => {

    const [StaffInfo, setStaffInfo] = useState([]);
    const [AvailableProjects, setAvailableProjects] = useState([]);
    const [AvailableFilterPositions, setAvailableFilterPositions] = useState([]);

    const [NameFilter, setNameFilter] = useState('');
    const [PositionsFilter, setPositionsFilter] = useState([]);
    const [ProjectsFilter, setProjectsFilter] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [userProjectsList, setUserProjectsList] = useState([]);

    const profileRef = useRef(null);
    const profileRef1 = useRef(null);
    const [profile, setVisibleProfile] = useState(false);
    const { getProfileInfo, getCurrentBackendUrl } = useContext(GlobalStateContext);

    const [ReportModalInfo, setReportModalInfo] = useState({
        reportId: null,
        date: new Date(),
        name: 'abcd'
    });

    const [ExternalReportList, setExternalReportList] = useState([]);

    const userRole = Number(getProfileInfo().role);
    const userId = Number(getProfileInfo().id);

    const navigate = useNavigate();

    const openModal = (info) => {
        setReportModalInfo(info);
        setModalIsOpen(true);
      };
    
      const closeModal = () => {
        setModalIsOpen(false);
      };

      const changeVisibleProfile = () => {
        setVisibleProfile(!profile);
      };

      const handleClickOutside = (event) => {
        if (profileRef.current && !profileRef.current.contains(event.target) && !profileRef1.current.contains(event.target)) {
          setVisibleProfile(false);
        }
      };

      useEffect(() => {
        const getReportById = async () => {
          try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${getCurrentBackendUrl()}/api/reports/${ReportModalInfo.reportId}`, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            setExternalReportList(response.data);
          } catch (error) {
            //console.log(error);
          }
        };
    
        if (ReportModalInfo.reportId != null) getReportById();
      }, [ReportModalInfo.reportId]);

    useEffect(() => {
        fetchProjects();
        // console.log("AssignedEmployees: ", userId);
    }, []);

    useEffect(() => {
        getStaffWithSelectedRoles();
    }, [AvailableProjects]);

    const fetchProjects = async () => {
        const token = localStorage.getItem('token');
        await axios.get(`${getCurrentBackendUrl()}/api/projects/`,  {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
        .then((response) => {
            let data = response.data;

            if ([StaffPositionEnum.Администратор, StaffPositionEnum.Директор, StaffPositionEnum.SUPER_ADMIN].includes(userRole)) {
                setAvailableProjects(data);  // Показываем все проекты
            } else if (userRole === StaffPositionEnum.Менеджер) {
                // Получаем проекты, где текущий менеджер указан в assigned_managers
                data = data.filter(project => project.assigned_managers.includes(userId));
                setAvailableProjects(data);
            }
        })
        .catch((error) => {
            //console.log('Error:', error);
        })
    }

    const getStaffWithSelectedRoles = async () => {
        const todayDate = new Date(); //Сегодняшняя дата
        const sevenDaysAgo = new Date(); // Создаем новый объект даты
        sevenDaysAgo.setDate((new Date()).getDate() - 7); // Вычитаем 7 дней
        try {
            const response = await axios.get(`${getCurrentBackendUrl()}/api/staff?role=1&role=4&startDate=${formatDate(sevenDaysAgo)}&endDate=${formatDate(todayDate)}`);

            const staffList = response.data;

            if ([StaffPositionEnum.Администратор, StaffPositionEnum.Директор, StaffPositionEnum.SUPER_ADMIN].includes(userRole)) {
                setStaffInfo(staffList);  // Показываем всех сотрудников
                fillUserProjects(staffList);
                setAvailableFilterPositions(["Менеджер", "Разработчик"]);
            } else if (userRole === StaffPositionEnum.Менеджер) {
                setAvailableFilterPositions(["Разработчик"]);
                filterStaffForManager(staffList);
            }
        } catch (error) {
            //console.error("Ошибка при получении сотрудников:", error);
        }
    };

        // Фильтрация сотрудников для менеджера
        const filterStaffForManager = (staffList) => {
            try {        
                // Получаем разработчиков из доступных менеджеру проектов
                const assignedDevelopers = AvailableProjects.flatMap(project => project.assigned_developers);
    
                // Фильтруем сотрудников, чтобы показать только разработчиков, которые работают в проектах, где данный менеджер указан
                const filteredStaff = staffList.filter(staff => 
                    staff.role === StaffPositionEnum.Разработчик && assignedDevelopers.includes(Number(staff.user))
                );

                fillUserProjects(staffList);
    
                setStaffInfo(filteredStaff);
                //console.log("filtered Staff: ", filteredStaff, "received staff: ", staffList, "managed projects: ", AvailableProjects, "assignedDevelopers: ", assignedDevelopers, "managerId: ", userId);
            } catch (error) {
                //console.error("Ошибка при фильтрации сотрудников для менеджера:", error);
            }
        };

        const fillUserProjects = (staffList) => {
            // Заполняем мапу сотрудников и проектов, в которых они ялвяются разработчиками или менеджерами, нужно для работы фильтрации проектов
            staffList.forEach(staff => {
                const id = staff.user;
                const projects = AvailableProjects.filter(project => project.assigned_developers.includes(id) || project.assigned_managers.includes(id)); 
                const projectsName = projects.map(project => project.name);
                updateUserProjects(id, projectsName);
             });
        }

        const updateUserProjects = (newUserId, newProjects) => {
            setUserProjectsList(prevList => {
                // Проверяем, есть ли уже сотрудник с таким userId в массиве
                const existingUser = prevList.find(user => user.userId === newUserId);
        
                if (existingUser) {
                    // Если сотрудник уже существует, обновляем его проекты
                    return prevList.map(user =>
                        user.userId === newUserId ? { ...user, projects: newProjects } : user
                    );
                } else {
                    // Если сотрудника еще нет, добавляем нового в список
                    return [...prevList, { userId: newUserId, projects: newProjects }];
                }
            });
        };

    const onSelectPositions = (selectedPositions) => {
        setPositionsFilter(selectedPositions);
    }

    const onSelectProjects = (selectedProjects) => {
        setProjectsFilter(selectedProjects);
    }

    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const customStyles = {
        content: {
          display: 'inline-block',
          width: '40%',
          height: 'auto',
          padding: '32px 40px 39px 40px',
          border: 'none',
          outline: 'none',
          borderRadius: '12px',
          backgroundColor: '#313133',
          overflow: 'hidden',
        },
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(18, 22, 25, 0.75)',
          margin: 'auto',
          zIndex: 1000,
        }
      };

      const navigateToSummaryDayReport = () => {
        navigate(`/summary-day-report?date=${format(new Date(), 'yyyy-MM-dd')}`);

      }

    return (
        <div className='main-wrapper' onClick={handleClickOutside}>
            <ToolbarAdmin page={"staff"} />
            <Modal className="modal-window" isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <div>
            <div className="top-modal-bar">
                <p className="title">Отчет</p>
            <img className='close-info' onClick={closeModal} src={CloseIcon} />
            </div>
            <p className="staff-name">{ReportModalInfo.name}</p>
            <div style={{ height: '1px',  width: '100%', backgroundColor: '#555555', marginTop: '20px', marginBottom: '20px'}}></div>
        </div>
        <DetailedReviewPage missedReportsDates={() => {}} isModalReport={true} isExternalReport={true} externalReport={ExternalReportList} projectsList={AvailableProjects}/>
      </Modal>
            <div className="content-report">
                <div className="header-report staff">
                    <div className="button-add-user">
                        Создать пользователя
                     </div>
                    <div className="header-report-item">
                        <p>Сотрудники</p>
                    </div>
                    <div 
                    className="button-add-user"
                    onClick={navigateToSummaryDayReport}
                    >
                        Сводный отчёт
                     </div>
                    <div className="header-report-item" style={{ background: "transparent" }}>
                        <span className="profile-box">
                            <img src={getProfileInfo().avatar} style={{ width: "2vw", height: "2vw" }} onClick={changeVisibleProfile} ref={profileRef1} />
                        </span>
                    </div>
                </div>
                <div className="main-report" style={{ alignItems: "normal" }}>
                <div className={profile == false ? "profile-invisible" : "profile-visible"} ref={profileRef}>
            <Profile closeProfile={changeVisibleProfile} />
          </div>
                    <div className="main-content-staff">
                        <div className="content_staff">
                            <div className="filter-toolbar">
                                <div className="filters-admin">
                                    <div className="search-by-name">
                                        <img src={SearchIcon} />
                                        <input className="textarea-search-by-name" placeholder="Поиск по имени" value={NameFilter} onChange={(e) => setNameFilter(e.target.value)}>

                                        </input>
                                    </div>
                                    <div className="filter-job-title">
                                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <p>Должность:</p>
                                            <MultiSelectText selectedPositions={onSelectPositions} availablePositions={AvailableFilterPositions} />
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <p>Проекты:</p>
                                            <MultiSelectProjects selectedProjects={onSelectProjects} projectsName={AvailableProjects.map((project) => project.name)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <HeaderStaffBox />
                            <div style={{ paddingTop: "20px" }}></div>
                            <div className="staff-list" style={{ width: "100%", overflowY: "scroll", height: "63vh" }}>
                                {
                                StaffInfo
                                .sort((a, b) => a.role - b.role) //Сортировка сотрудников в списке по их роли
                                .filter((item) => 
                                    (item.firstname + " " + item.lastname).toLowerCase().includes(NameFilter.toLowerCase()) // Фильтруем по имени
                                &&
                                    ((PositionsFilter.length !== 0) ? PositionsFilter.some((position) => position === item.role) : true)  //Фильтруем по должности
                                &&
                                    ((ProjectsFilter.length !== 0) ? (
                                        userProjectsList.some(user => 
                                            user.userId === item.user && 
                                            user.projects.some(project => ProjectsFilter.includes(project))
                                        )
                                    ) : true) //Фильтруем по проектам
                                )
                                .map((item, index) => (
                                    <div key={Math.random()}>
                                    <StaffBox
                                    key={index} 
                                    id={item.user} 
                                    name={item.firstname + " " + item.lastname} 
                                    position={item.role} lastReports={item.reports} 
                                    projects={userProjectsList.filter(user => user.userId === item.user).flatMap(user => (user.projects))} 
                                    workHome={item.work_home} 
                                    openReportModal={openModal} 
                                    />
                                    </div>
                                ))
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StaffPage;