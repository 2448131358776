// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-webhook-settings-modal-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.save-button-wrapper {
    margin-left: 50%;
    display: flex;
    justify-content: end;
}

.save-button {
    background-color: #007AA3;
    border-radius: 12px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 10px;

}

.save-button:hover {
    background-color: #006EA4;
}

.section-divider {
    height: 1px;
    width: 100%;
    background-color: #555555;
    margin-top: 20px;
}

.error-message {
    background-color: red;
    color: white;
    border-radius: 12px;
    margin: 12px 0;
    padding: 12px;
    font-family: 'RobotoRegular';
}

.close-settings-modal-wrapper {
    display: flex;
    justify-content: flex-end;
}

.close-settings-modal {
    width: 20px;
    height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/css/summary_report_webhooks_modal.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,oBAAoB;AACxB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;;AAErB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,WAAW;IACX,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,mBAAmB;IACnB,cAAc;IACd,aAAa;IACb,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".main-webhook-settings-modal-wrapper {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n}\n\n.save-button-wrapper {\n    margin-left: 50%;\n    display: flex;\n    justify-content: end;\n}\n\n.save-button {\n    background-color: #007AA3;\n    border-radius: 12px;\n    padding-left: 8px;\n    padding-right: 8px;\n    padding-top: 10px;\n\n}\n\n.save-button:hover {\n    background-color: #006EA4;\n}\n\n.section-divider {\n    height: 1px;\n    width: 100%;\n    background-color: #555555;\n    margin-top: 20px;\n}\n\n.error-message {\n    background-color: red;\n    color: white;\n    border-radius: 12px;\n    margin: 12px 0;\n    padding: 12px;\n    font-family: 'RobotoRegular';\n}\n\n.close-settings-modal-wrapper {\n    display: flex;\n    justify-content: flex-end;\n}\n\n.close-settings-modal {\n    width: 20px;\n    height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
