// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .toolbar {
    background-color: #2D2D2D;
    height: 100vh;
    width: 13.3%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-right: 1px solid #555555
  }

  .logo-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 8%;
    border-bottom: 1px solid #555555;
  }

  .logo {
    width: 95%;
    height: 50%;
  }

  .menu {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .menu-text {
    font-family: "RobotoRegular";
    font-size: 20px;
    margin-bottom: 10px;
    color: #8E8E8E;
  }

  .element-toolbar {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 8px 12px 8px;
  }

  .element-toolbar.active {
    border-radius: 4px;
    color: #EDEDED;
    background-color: rgba(137, 137, 137, 0.2);
  }

  .element-text {
    font-size: 16px;
    color: #8E8E8E;
    font-family: "RobotoMedium";
  }

  .element-text.active {
    color: #EDEDED;
  }`, "",{"version":3,"sources":["webpack://./src/css/toolbar.css"],"names":[],"mappings":"EAAE;IACE,yBAAyB;IACzB,aAAa;IACb,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B;EACF;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,UAAU;IACV,gCAAgC;EAClC;;EAEA;IACE,UAAU;IACV,WAAW;EACb;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,4BAA4B;IAC5B,eAAe;IACf,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,0BAA0B;EAC5B;;EAEA;IACE,kBAAkB;IAClB,cAAc;IACd,0CAA0C;EAC5C;;EAEA;IACE,eAAe;IACf,cAAc;IACd,2BAA2B;EAC7B;;EAEA;IACE,cAAc;EAChB","sourcesContent":["  .toolbar {\n    background-color: #2D2D2D;\n    height: 100vh;\n    width: 13.3%;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    border-right: 1px solid #555555\n  }\n\n  .logo-box {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 8%;\n    border-bottom: 1px solid #555555;\n  }\n\n  .logo {\n    width: 95%;\n    height: 50%;\n  }\n\n  .menu {\n    display: flex;\n    flex-direction: column;\n    width: 90%;\n    margin-top: 20px;\n    margin-left: auto;\n    margin-right: auto;\n  }\n\n  .menu-text {\n    font-family: \"RobotoRegular\";\n    font-size: 20px;\n    margin-bottom: 10px;\n    color: #8E8E8E;\n  }\n\n  .element-toolbar {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    padding: 12px 8px 12px 8px;\n  }\n\n  .element-toolbar.active {\n    border-radius: 4px;\n    color: #EDEDED;\n    background-color: rgba(137, 137, 137, 0.2);\n  }\n\n  .element-text {\n    font-size: 16px;\n    color: #8E8E8E;\n    font-family: \"RobotoMedium\";\n  }\n\n  .element-text.active {\n    color: #EDEDED;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
