import useApi from './useApi'

const useApiProjects = () => {
  const request = useApi().request

  const fetchProjects = async () => {
    return request({ method: 'GET', url: '/api/projects' })
  }

  const updateProject = async (body, projectId) => {
    return request({ method: 'PUT', url: `/api/projects/${projectId}/`, data: body })
  }

  const createProject = async (body) => {
    return request({ method: 'POST', url: '/api/projects/', data: body })
  }

  const deleteProject = async (projectId) => {
    return request({ method: 'DELETE', url: `/api/projects/${projectId}/` })
  }

  return { fetchProjects, updateProject, createProject, deleteProject }
}

export default useApiProjects
