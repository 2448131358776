import '../../css/header_admin_box.css';
import EditReportIcon from '../../icons/three_points.svg'

const HeaderWeekBox = () => {

    return (
        <div className="header-adm-info">
            <div className="item-header-adm-info" style={{borderRight: "1px solid rgba(85, 85, 85, 1)"}}>
                Имя
            </div>
            <div className="item-header-adm-info" style={{borderRight: "1px solid rgba(85, 85, 85, 1)"}}>
                Должность
            </div>
            <div className="item-header-adm-info" style={{borderRight: "1px solid rgba(85, 85, 85, 1)"}}>
                Проекты
            </div>
            <div className="item-header-adm-info" style={{borderRight: "1px solid rgba(85, 85, 85, 1)"}}>
                Назначения
            </div>
            <div className="item-header-adm-info" style={{justifyContent: "center", margin: "0"}}>
                <img src={EditReportIcon}/>
            </div>
        </div>
    )

}

export default HeaderWeekBox;