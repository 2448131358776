import DefaultProjectIcon from "../../icons/default_project_icon.svg"
import "../../css/main.css";
import React, { useState, useRef, useEffect, useContext } from 'react';
import HeaderWeekBox from '../../jsx/HeaderWeekBox.jsx';
import MainWeekBox from '../../jsx/MainWeekBox.jsx';
import axios from 'axios';
import { GlobalStateContext } from "../GlobalStateContext.jsx";


const BriefReviewPage = ({ dates, startDate, endDate, missedReportsDates, projectsList }) => {
    const containerRef = useRef(null);
    const contentRef = useRef(null);
    const { getCurrentBackendUrl, getProfileInfo } = useContext(GlobalStateContext);
    const creator_id = getProfileInfo().id;

    const [ReportList, setReportList] = useState([]);

    useEffect(() => {
        if (startDate || endDate) {
                getReportsList();
        }
        //console.log("Start date: ", startDate, "End date: ", endDate, "dates: ", dates);
    }, [dates]);

    useEffect(() => {
        const missingDates = findMissingReportDates(startDate, endDate, ReportList);
        //console.log("Отсутствуют отчеты за следующие даты:", missingDates);
        if (missedReportsDates.length !== 0) missedReportsDates(missingDates);
    }, [ReportList]);
    

    const getReportsList = async () => {
        await axios.get(`${getCurrentBackendUrl()}/api/reports?creator_id=${creator_id}&end_date=${endDate}&start_date=${startDate}`, {
        })
            .then((response) => {
                //console.log('Received reports data for selected date range: ', response.data);
                //console.log('Success parse data for weekly report:', transformReportsData(response.data));
                setReportList(transformReportsData(response.data).sort((a, b) => b.date - a.date));
            })
            .catch((error) => {
                //console.log('Error:', error);
            }) 
    }

    function transformReportsData(serverReports) {
        return serverReports.map(report => {
            // Преобразуем дату в формат Date
            const reportDate = new Date(report.date);
            //console.log("date = ", reportDate);
    
            // Преобразуем проекты, добавляя изображение и название проекта
            const projects = report.item_reports.map(item => ({
                img_project: DefaultProjectIcon,
                project_id: item.project_id
            }));
    
            // Суммируем все часы работы по проектам в этом отчете
            const totalHours = report.item_reports.reduce((total, item) => total + item.hours_work, 0);
            const totalMinutes = report.item_reports.reduce((total, item) => total + item.minutes_work, 0);
    
            // Место работы (из work_home)
            const workPlace = report.work_home_reason || "Не указано"; // Если work_home пустое
    
            return {
                id: report.id,
                date: reportDate,
                projects: projects,
                work_place: workPlace,
                time: totalHours + Math.round(totalMinutes / 60)
            };
        });
    }

    // Функция для получения всех дат в интервале
function getDatesInRange(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        dates.push(new Date(currentDate));  // Копируем текущую дату
        currentDate.setDate(currentDate.getDate() + 1);  // Переходим на следующий день
    }

    return dates;
}

// Функция для поиска отсутствующих дат
function findMissingReportDates(startDate, endDate, serverReports) {
    // Преобразуем стартовую и конечную дату в объекты Date
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Получаем список всех дат в указанном интервале
    const allDates = getDatesInRange(start, end);

    // Получаем список дат с отчётами
    const reportDates = serverReports.map(report => new Date(report.date).setHours(0, 0, 0, 0)); // Сравниваем только даты без времени

    // Фильтруем даты, для которых нет отчётов
    const missingDates = allDates.filter(date => !reportDates.includes(date.setHours(0, 0, 0, 0)));

    // Преобразуем даты в формат "DD.MM.YY" для вывода
    return missingDates.filter(date => date <= new Date()).map(date => date.toLocaleDateString('ru-RU'));
}

    useEffect(() => {
        //console.log(dates);
        const container = containerRef.current;
        const content = contentRef.current;
        if (content.scrollHeight + 47 >= container.clientHeight) {
            container.style.height = (48 - (container.clientHeight - content.scrollHeight) + container.clientHeight) + 'px';
        }
    }, []);


    return (
        <div className="main-content-report-box">
            <div className="main-content-report">
                <div className="week-reports-wrapper" ref={containerRef}>
                    <div className="main-box" ref={contentRef}>
                        <HeaderWeekBox />
                        {ReportList.map((item, index) => (
                            <MainWeekBox key={index} id={item.id} date={item.date} projects={item.projects} work_place={item.work_place} time={item.time} projectsList={projectsList} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BriefReviewPage;