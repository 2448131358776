import useApi from './useApi'

const useApiAdministration = () => {
  const request = useApi().request

  const loadAdministrationPage = async () => {
    return request({ method: 'GET', url: '/api/administration' })
  }

  return { loadAdministrationPage }
}

export default useApiAdministration
